import React from 'react';
import { connect } from 'react-redux';
import PartnerForm from './partnerForm';

const PartnerDetails = (props) => {
  const { partnerData } = props;

  return <PartnerForm title={partnerData.company} partnerData={partnerData} />;
};

const mapStateToProps = ({ dispatch, Partners }) => ({ dispatch, Partners });
export default connect(mapStateToProps)(PartnerDetails);
