import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../components/UI/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Modal from '../../components/Modal';
import copy from '../../copy-text';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  formControl: {
    marginTop: theme.spacing(3),
  },
}));

const NewEnvironment = (props) => {
  const { envTypes = [], onModalClose, onSubmit, openModal, titleName } = props;
  const emptyEnv = { displayName: '', environmentType: envTypes[0] };
  const [envData, setEnvData] = useState(emptyEnv);
  const classes = useStyles();

  const formState = {
    displayName: {
      error: false,
      msg: copy.pages.titles.newEnvironment.modal.displayName.errorText,
    },
  };
  const [formErrors, setFormErrors] = useState(formState);

  const validateEnv = () => {
    if (envData.displayName.length) {
      handleModalClose();
      onSubmit(envData);
    } else {
      setFormErrors({
        ...formErrors,
        displayName: {
          ...formErrors.displayName,
          error: !envData.displayName.length,
        },
      });
    }
  };

  const handleChange = (event) =>
    setEnvData({ ...envData, [event.target.name]: event.target.value });
  const handleModalClose = () => {
    setEnvData(emptyEnv);
    setFormErrors(formState);
    onModalClose();
  };

  return (
    <Modal
      open={openModal}
      title={copy.pages.titles.newEnvironment.modal.title}
      onClose={onModalClose}
      actions={
        <div className={classes.actions}>
          <Button type="contained" onClick={() => validateEnv()}>
            Add
          </Button>
          <Button onClick={handleModalClose} fill={false}>
            Cancel
          </Button>
        </div>
      }
    >
      <div className={classes.container}>
        <TextField
          disabled
          margin="dense"
          type="text"
          label={copy.pages.titles.newEnvironment.modal.titleName.label}
          value={titleName}
        />
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">New Environment</FormLabel>
          <RadioGroup
            aria-label="Environment Type:"
            name="environmentType"
            value={envData.environmentType}
            onChange={handleChange}
          >
            <FormControlLabel
              value="DEV"
              disabled={envTypes.indexOf('DEV') < 0}
              control={<Radio />}
              label="DEV"
            />
            <FormControlLabel
              value="QA"
              disabled={envTypes.indexOf('QA') < 0}
              control={<Radio />}
              label="QA"
            />
            <FormControlLabel
              value="STAGING"
              disabled={envTypes.indexOf('STAGING') < 0}
              control={<Radio />}
              label="STAGING"
            />
            <FormControlLabel
              value="PROD"
              disabled={envTypes.indexOf('PROD') < 0}
              control={<Radio />}
              label="PROD"
            />
          </RadioGroup>
        </FormControl>
        <TextField
          autoFocus
          margin="dense"
          type="text"
          name="displayName"
          label={copy.pages.titles.newEnvironment.modal.displayName.label}
          error={formErrors.displayName.error}
          helperText={
            formErrors.displayName.error
              ? formErrors.displayName.msg
              : copy.pages.titles.newEnvironment.modal.displayName.helperText
          }
          inputProps={{ maxLength: 20 }}
          onChange={handleChange}
          value={envData.displayName}
        />
      </div>
    </Modal>
  );
};

export default NewEnvironment;
