import { fork, put, takeLatest } from 'redux-saga/effects';
import { getPlayers } from '../../../services/ivi-engine';
import actions, { setPlayers } from './actions';
import { mythicalFetcher } from '../../utilities';

function* getPlayersSaga(action) {
  try {
    const players = yield mythicalFetcher(getPlayers);
    yield put(setPlayers(players));
  } catch (e) {}
}

function* watchGetPlayersSaga() {
  yield takeLatest(actions.REQ_GET_PLAYERS, getPlayersSaga);
}

export default function* playersSagas() {
  yield fork(watchGetPlayersSaga);
}
