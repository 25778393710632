import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Create';
import Switch from '@material-ui/core/Switch';
import { useRoleTarget } from '../../../hooks';
import Button from '../../../components/UI/Button';
import Table from '../../../components/Table';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import WebhookModal from './WebhookModal';
import { setShowModal } from '../../../redux-store/modules/settings';
import {
  reqAddWebhook,
  reqDeleteWebhook,
  reqUpdateWebhook,
} from '../../../redux-store/modules/webhooks';

const useStyles = makeStyles((theme) => ({
  actions: {
    '& svg': {
      color: theme.table.actions.color,
      cursor: 'pointer',
    },
    '& svg:last-child': {
      marginLeft: 15,
    },
  },
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: 30,
  },
}));

export default function Webhooks() {
  const { Settings, Webhooks } = useSelector((state) => state);
  const { webhooks } = Webhooks;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [webhookToDelete, setWebhookToDelete] = useState<any>();
  const webhooksPermissions = useRoleTarget('webhooks');

  const emptyWebhook = {
    name: '',
    description: '',
    disabled: false,
    url: '',
    requiredHeaders: {},
    requiredQueryParams: {},
    activeActions: ['all'],
  };
  const [activeWebhook, setActiveWebhook] = useState<any>(emptyWebhook);

  const confirmDelete = (webhook) => {
    setWebhookToDelete(webhook);
    setShowDialog(true);
  };
  const handleSubmitWebhook = (newWebhook) => {
    dispatch(setShowModal(false));
    if (newWebhook.id) {
      dispatch(reqUpdateWebhook(newWebhook));
    } else {
      dispatch(reqAddWebhook(newWebhook));
    }
  };
  const handleDeleteWebhook = (webhook) => {
    dispatch(reqDeleteWebhook(webhook.id));
    setShowDialog(false);
  };
  const handleEditWebhook = (webhook) => {
    setActiveWebhook(webhook);
    dispatch(setShowModal(true));
  };
  const handleModalClose = () => {
    dispatch(setShowModal(false));
    setActiveWebhook(emptyWebhook);
  };
  const handleModalOpen = () => dispatch(setShowModal(true));
  const handleToggleEnabled = (webhook) => {
    const toggledWebhook = { ...webhook, disabled: !webhook.disabled };
    dispatch(reqUpdateWebhook(toggledWebhook));
  };

  const tableData = {
    name: { label: 'Name', width: 0.2 },
    url: { label: 'URL', width: 0.5 },
    disabled: { label: 'Enabled', width: 0.1 },
    actions: { label: 'Actions', width: 0.2 },
  };

  const renderWebhookRow = (webhookRow) => {
    const webhookData = {
      table: {},
      raw: {},
    };
    Object.keys(webhookRow).forEach((key) => {
      webhookData.raw[key] = webhookRow[key];
      switch (key) {
        case 'disabled':
          webhookData.table[key] = (
            <Switch
              checked={!webhookRow.disabled}
              onChange={() => handleToggleEnabled(webhookRow)}
            />
          );
          break;
        default:
          webhookData.table[key] = webhookRow[key];
          break;
      }
    });
    webhookData.table['actions'] = (
      <div className={classes.actions}>
        <EditIcon onClick={() => handleEditWebhook(webhookRow)} />
        <DeleteIcon onClick={() => confirmDelete(webhookRow)} />
      </div>
    );

    return webhookData;
  };

  const rowData = webhooks.length
    ? webhooks.map((webhook) => renderWebhookRow(webhook))
    : [];

  const cta = webhooksPermissions.CREATE ? (
    <Button
      type="contained"
      disabled={webhooks.length ? true : false}
      onClick={() => handleModalOpen()}
    >
      + New Webhook
    </Button>
  ) : null;

  return (
    <div className={classes.pageContent}>
      <ConfirmationDialog
        show={showDialog}
        message={
          <span>
            Are you sure you want to delete{' '}
            <strong>{webhookToDelete && webhookToDelete.name}</strong>?
          </span>
        }
        title="Confirmation"
        onCancel={() => setShowDialog(false)}
        onConfirm={() => handleDeleteWebhook(webhookToDelete)}
      />
      <WebhookModal
        onModalClose={handleModalClose}
        onSubmitWebhook={handleSubmitWebhook}
        openModal={Settings.showModal}
        onClose={handleModalClose}
        title={webhooks.length ? 'Edit Webhook' : 'Create Webhook'}
        webhook={activeWebhook}
      />
      <Table
        label={`Webhooks (${webhooks.length || 0})`}
        tableData={tableData}
        rowData={rowData}
        downloadable={false}
        cta={cta}
      />
    </div>
  );
}
