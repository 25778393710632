import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { FormControl, TextField } from '@material-ui/core';
import Select from '../../../components/UI/Select';
import Button from '../../../components/UI/Button';
import Modal from '../../../components/Modal';
import copy from '../../../copy-text';

const NewCurrency = (props) => {
  const { onModalClose, onSubmit, openModal } = props;
  const blankCurrency = {
    abbreviation: '',
    baseCurrency: false,
    baseExchangeRate: 1,
    fiat: true,
    name: '',
  };
  const [newCurrency, setNewCurrency] = useState(blankCurrency);
  const noError = {
    abbreviation: false,
    name: false,
    baseCurrency: false,
    baseExchangeRate: false,
  };
  const [hasError, setHasError] = useState<any>(noError);

  const checkForErrors = () => {
    if (newCurrency.abbreviation.length) {
      setHasError(noError);
      onSubmit(newCurrency);
      handleClose();
    } else {
      setHasError({
        abbreviation: !newCurrency.abbreviation.length,
        name: !newCurrency.name.length,
      });
    }
  };

  const handleChange = (event) =>
    setNewCurrency({ ...newCurrency, [event.target.name]: event.target.value });
  const handleClose = () => {
    setNewCurrency(blankCurrency);
    onModalClose();
  };

  return (
    <Modal
      open={openModal}
      title={copy.pages.environment.currencies.newCurrency.title}
      onClose={handleClose}
      actions={
        <Fragment>
          <Button type="contained" onClick={checkForErrors}>
            Submit
          </Button>
          <Button type="outlined" onClick={handleClose} fill={false}>
            Cancel
          </Button>
        </Fragment>
      }
    >
      <FormControl fullWidth>
        <TextField
          fullWidth
          margin="dense"
          name="name"
          label={copy.pages.environment.currencies.newCurrency.name.label}
          type="text"
          inputProps={{ maxLength: 20 }}
          error={hasError.name}
          onChange={handleChange}
          value={newCurrency.name}
        />
        <TextField
          fullWidth
          margin="dense"
          name="abbreviation"
          label={
            copy.pages.environment.currencies.newCurrency.abbreviation.label
          }
          type="text"
          inputProps={{ maxLength: 7 }}
          error={hasError.abbreviation}
          onChange={handleChange}
          value={newCurrency.abbreviation}
        />
        <TextField
          fullWidth
          margin="dense"
          name="baseExchangeRate"
          label="Base Exchange Rate"
          type="number"
          error={hasError.baseExchangeRate}
          onChange={handleChange}
          value={newCurrency.baseCurrency ? 1 : newCurrency.baseExchangeRate}
        />
        <Select
          label="Base Currency?"
          name="baseCurrency"
          value={newCurrency.baseCurrency}
          onChange={handleChange}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
        />
        <Select
          label="Is fiat?"
          name="fiat"
          value={newCurrency.fiat}
          onChange={handleChange}
          options={[
            { label: 'Yes', value: true },
            { label: 'No', value: false },
          ]}
        />
      </FormControl>
    </Modal>
  );
};

NewCurrency.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default NewCurrency;
