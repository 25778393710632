import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { connectRouter } from 'connected-react-router';
import { fork } from 'redux-saga/effects';
import { Account, accountSagas } from './account';
import { Authentication, authenticationSagas } from './authentication';
import { Items, itemsSagas } from './items';
import { Orgs, orgsSagas } from './orgs';
import { Partners, partnersSagas } from './partners';
import { Players, playersSagas } from './players';
import { Settings, settingsSagas } from './settings';
import { Titles, titlesSagas } from './titles';
import { Transactions, transactionsSagas } from './transactions';
import { Webhooks, webhooksSagas } from './webhooks';

const persistConfig = {
  key: 'root',
  storage,
};

export const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    persisted: persistReducer(
      persistConfig,
      combineReducers({ Orgs, Settings })
    ),
    Account,
    Authentication,
    Items,
    Orgs,
    Partners,
    Players,
    Settings,
    Titles,
    Transactions,
    Webhooks,
  });

export function* rootSaga() {
  yield fork(accountSagas);
  yield fork(authenticationSagas);
  yield fork(itemsSagas);
  yield fork(orgsSagas);
  yield fork(partnersSagas);
  yield fork(playersSagas);
  yield fork(settingsSagas);
  yield fork(titlesSagas);
  yield fork(transactionsSagas);
  yield fork(webhooksSagas);
}
