import config from '../config';

const { iviBaseUrl } = config.apis.mythical;

const doFetch = async ({ token, url, method, body }: {token, url, method, body?}) => {
  const options: RequestInit = {
    method,
    mode: 'cors',
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    }),
  };
  if (body) {
    options.body = await JSON.stringify(body);
  }

  try {
    let res = await fetch(url, options);

    //console.log('HTTPS response status: ', res.status, res, options)

    return res.status === 204 || res.status === 404 || res.status === 500
      ? res
      : res.json();
  } catch (error) {
    console.error(error);
  }
};

export const getAccount = async ({ token, platformUserId }) => {
  const url = `${iviBaseUrl}/accounts/${platformUserId}`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const resetPassword = async ({ token, platformUserId }) => {
  const url = `${iviBaseUrl}/accounts/${platformUserId}/reset-password`;
  const method = 'post';
  const response = await doFetch({ token, url, method });
  return response;
};
export const updateProfileData = async (
  { token, platformUserId },
  profileData
) => {
  const { birthdate, email, given_name, family_name } = profileData;
  const url = `${iviBaseUrl}/accounts/${platformUserId}/profile`;
  const method = 'put';
  const body = {
    birthdate,
    email,
    firstName: given_name,
    lastName: family_name,
  };
  const response = await doFetch({ token, url, method, body });
  return response;
};
export const updateProfileImage = async (
  { token, platformUserId },
  imgData
) => {
  const url = `${iviBaseUrl}/accounts/${platformUserId}/profile/picture`;
  const method = 'put';
  const body = imgData;
  const response = await doFetch({ token, url, method, body });
  return response;
};

/******** ENVIRONMENTS ********/

export const addEnvironment = async ({ token }, environment, titleId) => {
  const url = `${iviBaseUrl}/titles/${titleId}/environments`;
  const method = 'post';
  const body = environment;
  const response = await doFetch({ token, url, method, body });
  return response;
};
export const getEnvironments = async ({ token, titleId }) => {
  const url = `${iviBaseUrl}/titles/${titleId}`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const updateEnvironment = async ({ token }, environment) => {
  const url = `${iviBaseUrl}/environments/${environment.id}`;
  const method = 'put';
  const body = {
    displayName: environment.displayName,
    environmentType: environment.environmentType,
  };
  const response = await doFetch({ token, url, method, body });
  return response;
};

/********** ITEMS **********/

export const createItem = async ({ token, environmentId }, item) => {
  const url = `${iviBaseUrl}/environments/${environmentId}/items/types`;
  const method = 'post';
  const body = item;
  const response = await doFetch({ token, url, method, body });
  return response;
};
export const issueItem = async ({ token, environmentId }, item) => {
  const url = `${iviBaseUrl}/environments/${environmentId}/items/issued`;
  const method = 'post';
  const body = item;
  const response = await doFetch({ token, url, method, body });
  return response;
};
export const getIssuedItems = async ({ token, environmentId }) => {
  const queries = `?finalized=all`;
  const url = `${iviBaseUrl}/environments/${environmentId}/items/issued${queries}`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const getItemTypes = async ({ token, environmentId }) => {
  const url = `${iviBaseUrl}/environments/${environmentId}/items/types`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const getItemToken = async (
  { token, environmentId },
  category,
  tokenName
) => {
  const url = `${iviBaseUrl}/environments/${environmentId}/items/types/${category}/${tokenName}`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const getListedItems = async ({ token, environmentId }) => {
  const url = `${iviBaseUrl}/environments/${environmentId}/items/listed`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};

/********** ORGS **********/

export const acceptInvite = async ({ token }, organizationId, inviteId) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/invites/${inviteId}/accept`;
  const method = 'post';
  const response = await doFetch({ token, url, method });
  return response;
};
export const inviteUsers = async ({ token, organizationId }, invites) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/invites`;
  const method = 'post';
  const body = invites;
  const response = await doFetch({ token, url, method, body });
  return response;
};
export const getOrgs = async ({ token }) => {
  const url = `${iviBaseUrl}/orgs`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const getOrgRoles = async ({ token }, organizationId) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/roles`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const getOrgUsers = async ({ token }, organizationId) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/users`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const getPendingInvites = async ({ token }, organizationId) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/invites`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const updateOrg = async ({ token, organizationId }, organization) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}`;
  const { displayName, environmentLimits } = organization;
  const method = 'put';
  const body = { displayName, environmentLimits };
  const response = await doFetch({ token, url, method, body });
  return response;
};

/********** PARTNERS & AGREEMENTS **********/

export const createAgreement = async ({ token, organizationId }, agreement) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/partner/${agreement.partnerId}/agreement`;
  const method = 'post';
  const body = agreement;
  const response = await doFetch({ token, url, method, body });
  return response;
};
export const createPartner = async ({ token, organizationId }, partner) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/partner`;
  const method = 'post';
  const body = partner;
  const response = await doFetch({ token, url, method, body });
  return response;
};
export const getAgreements = async ({ token, organizationId }, partnerId) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/partner/${partnerId}/agreement`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const getPartners = async ({ token }, organizationId) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/partner`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const updateAgreement = async ({ token, organizationId }, agreement) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/partner/${agreement.partnerId}/agreement/${agreement.id}`;
  const method = 'put';
  const body = agreement;
  const response = await doFetch({ token, url, method, body });
  return response;
};
export const updatePartner = async ({ token, organizationId }, partner) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/partner/${partner.id}`;
  const method = 'put';
  const body = partner;
  const response = await doFetch({ token, url, method, body });
  return response;
};

/********** PLAYERS **********/

export const getPlayers = async ({ token, environmentId }) => {
  const url = `${iviBaseUrl}/environments/${environmentId}/players`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};

/********** TITLES **********/

export const addTitle = async ({ token, organizationId }, title) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/titles`;
  const method = 'post';
  const body = title;
  const response = await doFetch({ token, url, method, body });
  return response;
};
export const deleteTitle = async ({ token, titleId }) => {
  const url = `${iviBaseUrl}/titles/${titleId}`;
  const method = 'delete';
  const response = await doFetch({ token, url, method });
  return response;
};
export const getTitle = async ({ token, titleId }) => {
  const url = `${iviBaseUrl}/titles/${titleId}`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const getTitles = async ({ token, organizationId }) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/titles`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const updateTitle = async ({ token }, titleData) => {
  const url = `${iviBaseUrl}/titles/${titleData.id}`;
  const method = 'put';
  const body = { titleName: titleData.name };
  const response = await doFetch({ token, url, method, body });
  return response;
};

/********** SERVER SECRETS **********/

export const addSecret = async ({ token, organizationId }, secret) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/secrets`;
  const method = 'post';
  const body = secret;
  const response = await doFetch({ token, url, method, body });
  return response;
};
export const deleteSecret = async ({ token, organizationId }, secretId) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/secrets/${secretId}`;
  const method = 'delete';
  const response = await doFetch({ token, url, method });
  return response;
};
export const getSecrets = async ({ token }, organizationId) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/secrets`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const disableSecret = async ({ token, organizationId }, secretId) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/secrets/${secretId}/disable`;
  const method = 'put';
  const response = await doFetch({ token, url, method });
  return response;
};
export const enableSecret = async ({ token, organizationId }, secretId) => {
  const url = `${iviBaseUrl}/orgs/${organizationId}/secrets/${secretId}/enable`;
  const method = 'put';
  const response = await doFetch({ token, url, method });
  return response;
};

/******** TRANSACTIONS ********/

export const getTransactions = async ({ token, environmentId }) => {
  const url = `${iviBaseUrl}/environments/${environmentId}/transactions`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};

/******** USER SETTINGS ********/

export const getUserTheme = async ({ token }) => {
  const url = `${iviBaseUrl}/users/settings/theme`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const setUserTheme = async ({ token }, theme) => {
  const url = `${iviBaseUrl}/users/settings/theme`;
  const method = 'post';
  const body = theme;
  const response = await doFetch({ token, url, method, body });
  return response;
};

/********** WEBHOOKS **********/

export const addWebhook = async ({ token, environmentId }, webhook) => {
  const url = `${iviBaseUrl}/environments/${environmentId}/webhooks`;
  const method = 'post';
  const body = webhook;
  const response = await doFetch({ token, url, method, body });
  return response;
};
export const deleteWebhook = async ({ token, environmentId }, webhookId) => {
  const url = `${iviBaseUrl}/environments/${environmentId}/webhooks/${webhookId}`;
  const method = 'delete';
  const response = await doFetch({ token, url, method });
  return response;
};
export const getWebhooks = async ({ token, environmentId }) => {
  const url = `${iviBaseUrl}/environments/${environmentId}/webhooks`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
export const updateWebhook = async ({ token, environmentId }, webhook) => {
  const url = `${iviBaseUrl}/environments/${environmentId}/webhooks/${webhook.id}`;
  const method = 'put';
  const body = webhook;
  const response = await doFetch({ token, url, method, body });
  return response;
};

/********** VIRTUAL CURRENCIES **********/

export const addCurrency = async ({ token, titleId }, currency) => {
  const url = `${iviBaseUrl}/titles/${titleId}/currencies`;
  const method = 'post';
  const body = currency;
  const response = await doFetch({ token, url, method, body });
  return response;
};
export const deleteCurrency = async ({ token, titleId }, abbreviation) => {
  const url = `${iviBaseUrl}/titles/${titleId}/currencies/${abbreviation}`;
  const method = 'delete';
  const response = await doFetch({ token, url, method });
  return response;
};
export const getCurrency = async ({ token, titleId }) => {
  const url = `${iviBaseUrl}/titles/${titleId}/currencies`;
  const method = 'get';
  const response = await doFetch({ token, url, method });
  return response;
};
