import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Page from '../../components/Page';
import Tabs from '../../components/Tabs';
import AgreementsTable from './agreementsTable';
import PartnersTable from './partnersTable';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    webkitFlexWrap: 'wrap',
    flexWrap: 'wrap',
  },
  viewHeader: {
    color: theme.text.primary,
    backgroundColor: theme.table.header.backgroundColor,
    borderRadius: 10,
    margin: '20px 0',
    padding: 20,
  },
  view: {
    marginBottom: 10,
  },
}));

export default function Partners() {
  const { Partners } = useSelector((state) => state);
  const { agreements, partners } = Partners;

  const classes = useStyles();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleTabChange = (event, tabIndex) => setActiveTabIndex(tabIndex);

  const tabContent = [
    {
      label: `Partners`,
      component: <PartnersTable partners={partners} agreements={agreements} />,
      link: <Typography>Partners</Typography>,
    },
    {
      label: `Agreements`,
      component: <AgreementsTable />,
      link: <Typography>Agreements</Typography>,
    },
  ];

  return (
    <Page
      title="Partners"
      subTitle="Add and manage partners or create revenue sharing agreements for existing partners"
    >
      <div className={classes.viewHeader}>
        <Typography className={classes.view} variant="h6">
          View
        </Typography>
        <Tabs
          tabType="button"
          value={activeTabIndex}
          onChange={handleTabChange}
          tabContent={tabContent}
        />
      </div>
      <div className={classes.tabContainer}>
        {tabContent[activeTabIndex].component}
      </div>
    </Page>
  );
}
