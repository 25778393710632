import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as NotificationIcon } from '../../../../assets/icons/Dashboard-Notification.svg';
import Card from '../../../../components/Card';
import copy from '../../../../copy-text';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    height: '100%',
    userSelect: 'none',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  title: {
    color: theme.text.primary,
    fontWeight: 'bold',
    fontSize: '1.5em',
    marginBottom: 10,
  },
  subTitle: {
    color: theme.text.secondary,
    marginBottom: 20,
    textAlign: 'center',
  },
}));

const Notifications = (props) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <div className={classes.content}>
        <Typography className={classes.title}>
          {copy.pages.dashboard.notifications.label}
        </Typography>
        <Typography className={classes.subTitle}>
          {copy.pages.dashboard.notifications.text}
        </Typography>
        <NotificationIcon />
      </div>
    </Card>
  );
};

export default Notifications;
