export default {
  components: {
    imageUploader: {
      title: 'Upload Image',
    },
  },
  pages: {
    dashboard: {
      title: 'Dashboard',
      cards: {
        revenue: {
          label: 'Revenue',
        },
        accounts: {
          label: 'New Accounts',
        },
        issuedItems: {
          label: 'Issued Items',
        },
        soldItems: {
          label: 'Sold Items',
        },
      },
      charts: {
        label: 'Add Chart',
        text: 'Add Charts to highlight your most important metrics.',
      },
      favorites: {
        label: 'Favorite Pages',
        text:
          'Bookmark your most frequented pages for quicker access from the dashboard.',
      },
      activity: {
        label: 'Recent Activity',
      },
      notifications: {
        label: 'Notifications',
        text: "We'll be in touch here with any updates!",
      },
    },
    titles: {
      title: 'Titles Manager',
      editEnvironment: {
        modal: {
          title: 'Edit Environment',
          displayName: {
            label: 'Environment Name',
            helperText: '',
            errorText: 'Please fill in a valid environment name',
          },
          environmentId: {
            label: 'Environment ID',
          },
          environmentType: {
            label: 'Environment Type',
          },
          titleName: {
            label: 'Title Name',
          },
        },
      },
      editTitle: {
        modal: {
          title: 'Edit Title',
          titleName: {
            label: 'Title Name',
            errorText: 'Please fill in a valid title name',
          },
          titleId: {
            label: 'Title ID',
          },
        },
      },
      newTitle: {
        cta: '+ Create New Title',
        modal: {
          title: 'New Title',
          titleName: {
            label: 'Title Name',
            helperText: 'Please enter between 1-20 characters',
            errorText: 'Please fill in a title name',
          },
          environmentType: {
            label: 'First Title Environment',
            helperText:
              'This environment is the first that will be associated with your new title.',
          },
          environmentName: {
            label: 'Environment Name',
            helperText: 'Please enter between 1-20 characters',
            errorText: 'Please fill in an environment name',
          },
        },
      },
      newEnvironment: {
        cta: '+ Add New Environment',
        modal: {
          title: 'New Environment',
          displayName: {
            label: 'Environment Name',
            helperText: '',
            errorText: 'Please fill in a valid environment name',
          },
          titleName: {
            label: 'Title Name',
          },
        },
      },
      noTitles: {
        label: 'Welcome to your Titles Manager page',
        button: '+ Create New Title',
        text:
          "Click <b>+ Create New Title</b> to add your first title and select your title's starting environment (DEV, QA, STAGE, or PROD).",
      },
    },
    environment: {
      currencies: {
        cta: '+ New Currency',
        newCurrency: {
          title: 'Create Virtual Currency',
          name: {
            label: 'Name',
            errorText: '',
          },
          abbreviation: {
            label: 'Abbreviation',
            errorText: '',
          },
        },
      },
      secrets: {
        cta: '+ New Secret',
        newSecret: {
          title: 'Create Server Secret',
          name: {
            label: 'Name',
            errorText: '',
          },
        },
      },
      webhooks: {
        cta: '+ New Webhook',
        modal: {
          name: {
            label: 'Name',
            errorText: '',
          },
          description: {
            label: 'Description',
            errorText: '',
          },
          url: {
            label: 'URL',
            errorText: '',
          },
          headers: {
            cta: '+ Add Header',
            title: 'HTTP Headers',
          },
          query: {
            cta: '+ Add Query',
            title: 'Query Parameters',
          },
        },
      },
    },
    account: {
      title: 'Account',
      details: {
        title: 'My Details',
        picture: {
          cta: 'Change picture',
        },
        updatePassword: {
          cta: 'Update password',
          title: 'Update Password Email Sent',
        },
        firstName: {
          label: 'First Name',
          errorText: 'Please fill in a first name',
        },
        lastName: {
          label: 'Last Name',
          errorText: 'Please fill in a last name',
        },
        email: {
          label: 'Email',
          errorText: 'Please fill in a valid email',
        },
        email2: {
          label: 'Confirm Email',
          errorText: 'Email address does not match',
        },
      },
    },
    admin: {
      users: {
        cta: '+ Invite Users',
        text:
          'Invite additional users to join your organization and assign roles to manage their access to the platform.',
        inviteModal: {
          title: 'Invite Users',
          cta: '+ Add Invite',
          text:
            'Enter the email address for any users that you want to invite to your organization and select a role for each user. You may send multiple invites at once.',
          email: {
            label: 'User Email',
            errorText: 'Must be a valid email address',
          },
          role: {
            label: 'Role',
            errorText: 'You must select a role',
          },
        },
      },
      settings: {
        title: 'Organization Settings',
        name: {
          label: 'Organization Name',
          errorText: 'Organization name must be between 1-20 characters',
        },
        envTypes: {
          label: 'Environment Instance Type Limits',
          helperText:
            'Maximum number of environment types allowed for each title',
        },
      },
    },
    partners: {
      title: 'Partners',
      text:
        'Add and manage partners or create revenue sharing agreements for existing partners.',
    },
  },
};
