import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../components/Page';
import AgreementForm from './agreementForm';

const useStyles = makeStyles((theme) => ({
  companyName: {
    height: 60,
    marginBottom: 10,
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    webkitFlexWrap: 'wrap',
    flexWrap: 'wrap',
  },
}));

export default function Agreement() {
  const { Partners } = useSelector((state) => state);
  const { activePartner, activeAgreement } = Partners;
  const classes = useStyles();

  const breadcrumbs = [
    {
      path: '/partners',
      label: 'Partners',
    },
    {
      path: `/partner/${activePartner.id}`,
      label: activePartner.company,
    },
    {
      path: '',
      label: Object.keys(activeAgreement).length
        ? activeAgreement.name
        : 'New Agreement',
    },
  ];

  const title =
    (activePartner && activePartner.company && (
      <div>
        <div className={classes.companyName}>{activePartner.company}</div>
      </div>
    )) ||
    '';

  return (
    <Page title={title} breadcrumbs={breadcrumbs}>
      <div className={classes.container}>
        <AgreementForm agreementData={activeAgreement} />
      </div>
    </Page>
  );
}
