import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '../../../components/Card';
import { setTheme } from '../../../redux-store/modules/settings';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    marginBottom: 30,
  },
  title: {
    fontSize: '1.5em',
  },
  row: ({ isMobile }: any) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 20,
    justifyContent: isMobile ? 'center' : 'left',
  }),
  themeCard: ({ color }: any) => ({
    backgroundColor: color,
    border: '2px solid #999',
    cursor: 'pointer',
    height: 140,
    width: 120,
    borderRadius: 4,
    margin: 5,
    position: 'relative',
  }),
  themeCardLower: {
    backgroundColor: 'white',
    borderTop: '2px solid #999',
    boxSizing: 'border-box',
    padding: 5,
    position: 'absolute',
    bottom: 0,
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  themeIndicator: ({ activeTheme }: any) => ({
    border: '1px solid #999',
    display: 'flex',
    backgroundColor: activeTheme ? theme.colors.primary : 'white',
    marginRight: 10,
    width: 20,
    height: 20,
    borderRadius: 10,
  }),
}));

const ThemeCard = ({ color, label, onClick, activeTheme }) => {
  const classes = useStyles({ color, activeTheme });
  return (
    <div className={classes.themeCard} onClick={onClick}>
      <div className={classes.themeCardLower}>
        <div className={classes.themeIndicator} />
        <Typography>{label}</Typography>
      </div>
    </div>
  );
};

export default function ThemeSelector() {
  const { isMobile, theme } = useSelector((state) => state.Settings);
  const classes = useStyles({ isMobile });
  const dispatch = useDispatch();

  const handleThemeSelect = (theme) => dispatch(setTheme(theme));

  return (
    <Card className={classes.card}>
      <Typography className={classes.title}>Themes</Typography>
      <div className={classes.row}>
        <ThemeCard
          color="#1B2137"
          label="Dark"
          activeTheme={theme === 'DARK'}
          onClick={() => handleThemeSelect('DARK')}
        />
        <ThemeCard
          color="#F4F4F4"
          label="Light"
          activeTheme={theme === 'LIGHT'}
          onClick={() => handleThemeSelect('LIGHT')}
        />
      </div>
    </Card>
  );
}
