import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import { ReactComponent as MinusIcon } from '../assets/icons/minus-icon.svg';
import { ReactComponent as QuestionIcon } from '../assets/icons/question-icon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  icon: {
    cursor: 'pointer',
    marginLeft: 15,
    '& svg': {
      color: theme.text.primary,
      width: 15,
      height: 15,
      '& g': {
        fill: theme.text.primary,
      },
      '& path': {
        fill: theme.text.primary,
      },
    },
  },
  helpText: {
    fontSize: '0.65em',
    //fontWeight: 'bold'
  },
}));

const TextWithHelp = (props) => {
  const { children, className, helpText } = props;

  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const handleClick = () => {
    setOpen(!open);
  };

  const icon = open ? <MinusIcon /> : <QuestionIcon />;

  return (
    <div className={`${classes.root} ${className ? className : ''}`}>
      <div className={classes.row}>
        <span>{children}</span>
        <span className={classes.icon} onClick={() => handleClick()}>
          {icon}
        </span>
      </div>
      <Collapse in={open}>
        <div className={classes.row}>
          <span className={classes.helpText}>{helpText}</span>
        </div>
      </Collapse>
    </div>
  );
};

export default TextWithHelp;
