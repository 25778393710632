const actions = {
  REQ_CREATE_ITEM: 'REQ_CREATE_ITEM',
  REQ_ISSUE_ITEM: 'REQ_ISSUE_ITEM',
  REQ_GET_ISSUED_ITEMS: 'REQ_GET_ISSUED_ITEMS',
  REQ_GET_ITEM_TOKEN: 'REQ_GET_ITEM_TOKEN',
  REQ_GET_ITEM_TYPES: 'REQ_GET_ITEM_TYPES',
  REQ_GET_LISTED_ITEMS: 'REQ_GET_LISTED_ITEMS',
  SET_ISSUED_ITEMS: 'SET_ISSUED_ITEMS',
  SET_ITEM_TYPES: 'SET_ITEM_TYPES',
  SET_LISTED_ITEMS: 'SET_LISTED_ITEMS',
  UPDATE_ITEM_TYPE: 'UPDATE_ITEM_TYPE',
};

export const reqCreateItem = (item) => ({
  type: actions.REQ_CREATE_ITEM,
  item,
});
export const reqIssueItem = (item) => ({ type: actions.REQ_ISSUE_ITEM, item });
export const reqGetItemToken = (category, tokenName) => ({
  type: actions.REQ_GET_ITEM_TOKEN,
  category,
  tokenName,
});
export const reqGetItemTypes = () => ({ type: actions.REQ_GET_ITEM_TYPES });
export const reqGetIssuedItems = () => ({ type: actions.REQ_GET_ISSUED_ITEMS });
export const reqGetListedItems = () => ({ type: actions.REQ_GET_LISTED_ITEMS });
export const setIssuedItems = (issuedItems) => ({
  type: actions.SET_ISSUED_ITEMS,
  issuedItems,
});
export const setItemTypes = (itemTypes) => ({
  type: actions.SET_ITEM_TYPES,
  itemTypes,
});
export const setListedItems = (listedItems) => ({
  type: actions.SET_LISTED_ITEMS,
  listedItems,
});
export const updateItemType = (item) => ({
  type: actions.UPDATE_ITEM_TYPE,
  item,
});

export default actions;
