import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

export function useDimensions() {
  const [dimensions, setDimensions] = useState(null);
  const ref = useCallback((node) => {
    if (node !== null) {
      setDimensions(node.getBoundingClientRect());

      const resizeObserver = new ResizeObserver(([entry]) => {
        const {
          width,
          height,
          top,
          bottom,
          left,
          right,
          x,
          y,
        } = entry.contentRect;
        //console.log('Size changed', entry.contentRect)
        setDimensions({ width, height, top, bottom, left, right, x, y });
      });
      resizeObserver.observe(node);
    }
  }, []);

  return [ref, dimensions];
}

// Given a specific role target it returns a map of CRUD permissions for the user of the current Org
export function useRoleTarget(target) {
  const { Authentication, Orgs } = useSelector((state) => state);
  const { realmAccess } = Authentication.keycloak;
  const { activeOrg = {} } = Orgs;

  const noPermissions = {
    CREATE: false,
    READ: false,
    UPDATE: false,
    DELETE: false,
  };
  const [permissions, setPermissions] = useState(noPermissions);

  useEffect(() => {
    if (realmAccess.roles.length && activeOrg?.roles) {
      // Iterate over org roles   if the org role matches a user role...
      const hasPermissions = activeOrg.roles.reduce(
        (acc1, role) =>
          realmAccess.roles.indexOf(role.roleName) >= 0
            ? // find the target and update the permission map
              role?.targets[target].reduce(
                (acc2, permission) => ({ ...acc2, [permission]: true }),
                noPermissions
              )
            : acc1,
        noPermissions
      );

      setPermissions(hasPermissions);
    }
  }, [activeOrg, realmAccess]);

  return permissions;
}
