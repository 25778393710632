import { debounce, fork, put, select, takeLatest } from 'redux-saga/effects';
import actions, {
  setItemTypes,
  setIssuedItems,
  setListedItems,
  updateItemType,
} from './actions';
import {
  createItem,
  getIssuedItems,
  getItemToken,
  getItemTypes,
  getListedItems,
  issueItem,
} from '../../../services/ivi-engine';
import { mythicalFetcher } from '../../utilities';

function* createItemSaga(action) {
  try {
    const response = yield mythicalFetcher(createItem, [action.item]);
    if (response.ok && response.transactionId !== null) {
      const state = yield select();
      const newItems = Object.assign([], state.Items.items);
      newItems.push({ ...action.item, id: response.transactionId });
      yield put(setItemTypes(newItems));
    }
  } catch (e) {}
}
function* getIssuedItemsSaga() {
  try {
    const issuedItems = yield mythicalFetcher(getIssuedItems);
    yield put(setIssuedItems(issuedItems));
  } catch (e) {}
}
function* getListedItemsSaga() {
  try {
    const listedItems = yield mythicalFetcher(getListedItems);
    yield put(setListedItems(listedItems));
  } catch (e) {}
}
function* getItemTokenSaga(action) {
  const { category, tokenName } = action;
  try {
    const tokenData = yield mythicalFetcher(getItemToken, [
      category,
      tokenName,
    ]);
    yield put(updateItemType(tokenData));
  } catch (e) {
    console.log('Error fetching item token', e);
  }
}
function* getItemTypesSaga() {
  try {
    const itemTypes = yield mythicalFetcher(getItemTypes);
    yield put(setItemTypes(itemTypes));
  } catch (e) {}
}
function* issueItemSaga(action) {
  try {
    const response = yield mythicalFetcher(issueItem, [action.item]);
    if (response.dgoodId) {
      const state = yield select();
      let issuedItems = Object.assign([], state.Items.issued);
      issuedItems.push({ ...action.item, dgoodId: response.dgoodId });
      yield put(setIssuedItems(issuedItems));
    }
  } catch (e) {}
}
function* watchCreateItemSaga() {
  yield takeLatest(actions.REQ_CREATE_ITEM, createItemSaga);
}
function* watchIssueItemSaga() {
  yield takeLatest(actions.REQ_ISSUE_ITEM, issueItemSaga);
}
function* watchGetIssuedItemsSaga() {
  yield debounce(1000, actions.REQ_GET_ISSUED_ITEMS, getIssuedItemsSaga);
}
function* watchGetListedItemsSaga() {
  yield debounce(1000, actions.REQ_GET_LISTED_ITEMS, getListedItemsSaga);
}
function* watchGetItemTokenSaga() {
  yield takeLatest(actions.REQ_GET_ITEM_TOKEN, getItemTokenSaga);
}
function* watchGetItemTypesSaga() {
  yield debounce(1000, actions.REQ_GET_ITEM_TYPES, getItemTypesSaga);
}

export default function* itemsSagas() {
  yield fork(watchCreateItemSaga);
  yield fork(watchIssueItemSaga);
  yield fork(watchGetIssuedItemsSaga);
  yield fork(watchGetItemTypesSaga);
  yield fork(watchGetListedItemsSaga);
  yield fork(watchGetItemTokenSaga);
}
