import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useRoleTarget } from '../../hooks';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ClickToCopy from '../../components/ClickToCopy';
import EditEnvironment from './EditEnvironment';
import { setActivePage } from '../../redux-store/modules/settings';
import {
  reqUpdateEnvironment,
  setActiveEnvironment,
} from '../../redux-store/modules/titles';
// Icons
import { ReactComponent as EditIcon } from '../../assets/icons/EditIcon.svg';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.environments.card.backgroundColor,
    boxShadow: '3px 3px 5px 0px rgba(0,0,0,0.15)',
    color: theme.text.primary,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: 250,
    height: 80,
    borderRadius: 10,
    padding: 10,
    marginRight: 20,
    marginTop: 20,
  },
  envIdText: {
    fontSize: '1em',
    userSelect: 'text',
    cursor: 'pointer',
    color: theme.text.secondary,
  },
  envTypeGroup: ({ envType }: any) => ({
    backgroundColor: theme.environments.colors[envType],
    cursor: 'pointer',
    color: 'black',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0px 10px',
    borderRadius: 8,
    height: 24,
  }),
  envTypeText: {
    color: 'black',
    fontSize: '0.9em',
    fontWeight: 'bold',
  },
  editIcon: {
    cursor: 'pointer',
    marginLeft: 10,
    marginTop: 5,
    color: theme.text.primary,
    opacity: 0.5,
    fontSize: '1.15em',
  },
  details: {
    cursor: 'default',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  name: {
    fontSize: '1.1em',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'row',
  },
  nameWrapper: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default function Environment(props) {
  const { envData, envType, titleName } = props;
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles({ envType });
  const [showEditEnvModal, setShowEditEnvModal] = useState(false);
  const environmentPermissions = useRoleTarget('environments');

  const handleClickEnvironment = (event) => {
    dispatch(setActiveEnvironment(envData));
    dispatch(setActivePage(envData.id));
    history.push(`/titles/${envData.parentTitleId}/${envData.id}`);
  };
  const handleEditEnvironment = (event) => {
    event.stopPropagation();
    setShowEditEnvModal(true);
  };
  const handleSubmitEnvironment = (envData) => {
    setShowEditEnvModal(false);
    dispatch(reqUpdateEnvironment(envData));
  };

  const editEnvironment = environmentPermissions.UPDATE && (
    <EditIcon className={classes.editIcon} onClick={handleEditEnvironment} />
  );

  return (
    <>
      <EditEnvironment
        environment={envData}
        openModal={showEditEnvModal}
        titleName={titleName}
        onSubmit={handleSubmitEnvironment}
        onModalClose={() => setShowEditEnvModal(false)}
      />
      <div className={classes.container}>
        <div className={classes.name}>
          <Typography
            className={classes.nameWrapper}
            onClick={handleClickEnvironment}
          >
            {envData.displayName}
          </Typography>
          {editEnvironment}
        </div>
        <div className={classes.details}>
          <ClickToCopy value={envData.id}>
            <Typography className={classes.envIdText}>{envData.id}</Typography>
          </ClickToCopy>
          <div
            className={classes.envTypeGroup}
            onClick={handleClickEnvironment}
          >
            <Typography className={classes.envTypeText}>
              {envData.environmentType}
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
}
