import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoleTarget } from '../../../hooks';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../components/UI/Button';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import ClickToCopy from '../../../components/ClickToCopy';
import Table from '../../../components/Table';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import NewSecret from './NewSecret';
import { setShowModal } from '../../../redux-store/modules/settings';
import {
  reqAddSecret,
  reqDeleteSecret,
  reqToggleDisableSecret,
} from '../../../redux-store/modules/orgs';
import { IVITableRowData } from 'types';

const useStyles = makeStyles((theme) => ({
  actions: {
    color: theme.table.actions.color,
    '& path': {
      cursor: 'pointer',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  container: {
    marginBottom: 30,
  },
}));

export default function Secrets() {
  const { Settings, Orgs } = useSelector((state) => state);
  const { secrets = [], roles = [] } = Orgs.activeOrg;
  const dispatch = useDispatch();

  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [secretToDelete, setSecretToDelete] = useState<any>();
  const secretsPermissions = useRoleTarget('secrets');

  const confirmDelete = (secret) => {
    setSecretToDelete(secret);
    setShowDialog(true);
  };
  const handleDeleteSecret = (secret) => {
    dispatch(reqDeleteSecret(secret.id));
    setShowDialog(false);
  };
  const handleNewSecret = () => dispatch(setShowModal(true));
  const handleModalClose = () => dispatch(setShowModal(false));
  const handleSubmit = (secret) => {
    dispatch(reqAddSecret(secret));
    dispatch(setShowModal(false));
  };
  const toggleSecretDisabled = (event) => {
    const updatedSecret = secrets.find(
      (secret) => secret.id === event.target.value
    );
    dispatch(
      reqToggleDisableSecret({
        ...updatedSecret,
        disabled: !event.target.checked,
      })
    );
  };

  const tableData = {
    description: { label: 'Name', width: 0.2, sortable: true },
    secret: { label: 'Secret', width: 0.4, sortable: true },
    roleId: { label: 'Role', width: 0.3, sortable: false },
    disabled: { label: 'Enabled', width: 0.05, sortable: false },
    actions: { label: 'Actions', width: 0.05, sortable: false },
  };

  const renderSecretRow = (row) => {
    const secretData: IVITableRowData = {
      table: {},
      raw: {},
    };
    Object.keys(row).forEach((key, i) => {
      secretData.raw[key] = row[key];
      switch (key) {
        case 'secret':
          secretData.table[key] = (
            <ClickToCopy value={row[key]}>{row[key]}</ClickToCopy>
          );
          break;
        case 'disabled':
          secretData.table[key] = (
            <Switch
              name="disabled"
              checked={!row[key]}
              value={row.id}
              onChange={toggleSecretDisabled}
            />
          );
          break;
        case 'roleId':
          secretData.table.roleId = roles.find(
            (role) => role.id === row.roleId
          ).displayName;
          break;
        default:
          secretData.table[key] = row[key];
          break;
      }
    });
    secretData.table['actions'] = (
      <DeleteIcon
        className={classes.actions}
        onClick={() => confirmDelete(row)}
      />
    );
    return secretData;
  };

  const rowData =
    secrets && secrets.length
      ? secrets
          .sort((a, b) => a.createdTimestamp - b.createdTimestamp)
          .map((secret) => renderSecretRow(secret))
      : [];

  const addNew = secretsPermissions.CREATE && (
    <Button type="contained" onClick={() => handleNewSecret()}>
      + New Secret
    </Button>
  );

  return (
    <div className={classes.container}>
      <ConfirmationDialog
        show={showDialog}
        message={
          <span>
            Are you sure you want to delete{' '}
            <strong>{secretToDelete && secretToDelete.description}</strong>?
          </span>
        }
        title="Confirmation"
        onCancel={() => setShowDialog(false)}
        onConfirm={() => handleDeleteSecret(secretToDelete)}
      />
      <div className={classes.row}>
        <NewSecret
          openModal={Settings.showModal}
          onModalClose={handleModalClose}
          onSubmit={handleSubmit}
          roles={roles}
        />
      </div>
      <div className={classes.row}>
        <Table
          label={`Secrets (${rowData.length})`}
          downloadable={false}
          tableData={tableData}
          rowData={rowData}
          cta={addNew}
        />
      </div>
    </div>
  );
}
