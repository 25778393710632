import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  root: ({ isMobile, menuOpen }: any) => ({
    marginLeft: isMobile
      ? theme.appBar.height / 2
      : !menuOpen
      ? theme.sideBar.widthClosed + theme.appBar.height / 2 + 30
      : theme.sideBar.widthOpen + theme.appBar.height / 2 + 30,
    marginRight: theme.appBar.height / 2,
    marginTop: theme.appBar.height / 2,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  }),
  childContainer: {
    display: 'flex',
    flexDirection: 'column',
    webkitFlexWrap: 'wrap',
    flexWrap: 'wrap',
    height: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: '100%',
    width: '100%',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  cta: {
    display: 'flex',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  title: {
    color: theme.text.primary,
    display: 'flex',
    flexDirection: 'column',
  },
  breadcrumbs: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    '& a': {
      color: theme.text.highlight,
      textDecoration: 'none',
    },
  },
}));

export default function Page(props) {
  const {
    breadcrumbs = [],
    children,
    className,
    cta,
    style,
    subTitle = '',
    title = '',
  } = props;
  const { Settings } = useSelector((state) => state);
  const { isMobile, menuOpen } = Settings;
  const classes = useStyles({ menuOpen, isMobile });

  const renderBreadcrumbs = () => (
    <div className={classes.breadcrumbs}>
      {breadcrumbs.map((link, i) =>
        i < breadcrumbs.length - 1 && link?.label?.length ? (
          <Typography key={i}>
            <Link to={link.path}>{link.label}</Link>
            &nbsp;{`>`}&nbsp;
          </Typography>
        ) : (
          <Typography key={i}>{link.label}</Typography>
        )
      )}
    </div>
  );

  return (
    <main
      className={`${classes.root} ${className ? className : ''}`}
      style={style}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.title}>
            <Typography variant="h4" gutterBottom>
              {title}
            </Typography>
            <Typography variant="h6" gutterBottom>
              {subTitle}
            </Typography>
            {breadcrumbs.length ? renderBreadcrumbs() : null}
          </div>
          <div className={classes.cta}>{cta}</div>
        </div>
        <div className={classes.childContainer}>{children}</div>
      </div>
    </main>
  );
}
