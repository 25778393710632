import actions from './actions';

const initialState = {
  activeOrg: null,
  orgs: [],
};

export default function Orgs(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ACTIVE_ORG:
      return {
        ...state,
        activeOrg: state.orgs.find((org) => org.id === action.orgId),
      };

    case actions.SET_ORG_ROLES:
      return {
        ...state,
        activeOrg:
          state.activeOrg &&
          state.activeOrg.id === action.roles[0].organizationId
            ? { ...state.activeOrg, roles: action.roles }
            : state.activeOrg,
        orgs: state.orgs.map((org) =>
          org.id === action.roles[0].organizationId
            ? { ...org, roles: action.roles }
            : org
        ),
      };

    case actions.SET_ORGS:
      return {
        ...state,
        orgs: action.orgs,
      };

    case actions.SET_ORG_USERS:
      return {
        ...state,
        activeOrg:
          state.activeOrg &&
          state.activeOrg.id === action.users[0].organizationId
            ? { ...state.activeOrg, users: action.users }
            : state.activeOrg,
        orgs: state.orgs.map((org) =>
          org.id === action.users[0].organizationId
            ? { ...org, users: action.users }
            : org
        ),
      };

    case actions.SET_PENDING_INVITES:
      return {
        ...state,
        activeOrg:
          state.activeOrg && state.activeOrg.id === action.orgId
            ? { ...state.activeOrg, pendingUsers: action.invites }
            : state.activeOrg,
        orgs: state.orgs.map((org) =>
          org.id === action.orgId
            ? { ...org, pendingUsers: action.invites }
            : org
        ),
      };

    case actions.SET_SECRETS:
      return {
        ...state,
        activeOrg: { ...state.activeOrg, secrets: action.secrets },
      };

    case actions.SET_USER_IS_ADMIN:
      return {
        ...state,
        activeOrg:
          state.activeOrg && state.activeOrg.id === action.orgId
            ? { ...state.activeOrg, userIsAdmin: action.userIsAdmin }
            : state.activeOrg,
        orgs: state.orgs.map((org) =>
          org.id === action.orgId
            ? { ...org, userIsAdmin: action.userIsAdmin }
            : org
        ),
      };

    case actions.UPDATE_ACTIVE_ORG:
      return {
        ...state,
        activeOrg: action.organization,
      };

    default:
      return state;
  }
}
