import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { useRoleTarget } from '../../../hooks';
import Table from '../../../components/Table';
import Button from '../../../components/UI/Button';
import InviteModal from './inviteModal';
import { reqInviteUsers } from '../../../redux-store/modules/orgs';
import { formatISODate } from '../../../utilities';
import copy from '../../../copy-text';
import { IVITableRowData } from 'types';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: 20,
  },
  text: {
    color: theme.text.secondary,
    fontSize: '1em',
  },
  inviteWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  invitePending: {
    color: theme.text.highlight,
    display: 'flex',
    fontSize: '1em',
    fontWeight: 'bold',
    justifyContent: 'center',
    textAlign: 'center',
    width: 150,
  },
  inviteMoment: {
    color: '#CCC',
    display: 'flex',
    fontSize: '1em',
    marginLeft: 20,
  },
}));

export default function Users() {
  const { Orgs } = useSelector((state) => state);
  const { activeOrg } = Orgs;
  const { users = [], roles = [] } = activeOrg;
  const classes = useStyles();
  const dispatch = useDispatch();
  const invitesPermissions = useRoleTarget('invites');
  const [showModal, setShowModal] = useState(false);

  const handleModalClose = () => setShowModal(false);

  const handleSendInvites = (invites) => dispatch(reqInviteUsers(invites));

  const tableData = {
    email: { label: 'Email', width: 0.4, sortable: true },
    roleId: { label: 'Role', width: 0.4, sortable: true },
    createdTimestamp: {
      label: 'Date Joined',
      width: 0.2,
      sortable: true,
      align: 'start',
    },
  };

  const renderRowData = (row) => {
    const rowData: IVITableRowData = {
      table: {},
      raw: {},
    };
    Object.keys(row).forEach((key, i) => {
      rowData.raw[key] = row[key];

      switch (key) {
        case 'email':
          rowData.table.email = row[key];
          break;

        case 'roleId':
          rowData.table.roleId = roles.find(
            (role) => role.id === row[key]
          ).displayName;
          break;

        case 'createdTimestamp':
          rowData.table[key] = row.pending ? (
            <div className={classes.inviteWrapper}>
              <Typography className={classes.invitePending}>
                Invite Pending
              </Typography>
              <Typography className={classes.inviteMoment}>{`Sent ${moment
                .unix(row[key])
                .fromNow()}`}</Typography>
            </div>
          ) : (
            formatISODate(row[key])
          );
          break;

        default:
          rowData.table[key] = row[key];
          break;
      }
    });
    return rowData;
  };
  const rowData = users.length ? users.map((row) => renderRowData(row)) : [];

  const cta = invitesPermissions.CREATE && (
    <Button type="contained" onClick={() => setShowModal(true)}>
      {copy.pages.admin.users.cta}
    </Button>
  );

  return (
    <div>
      <InviteModal
        openModal={showModal}
        onModalClose={handleModalClose}
        onSubmit={handleSendInvites}
        roles={roles}
      />
      <Typography className={classes.text}>
        {copy.pages.admin.users.text}
      </Typography>
      <Table
        className={classes.table}
        cta={cta}
        label={`Users (${users.length})`}
        downloadable={false}
        expandable={false}
        tableData={tableData}
        rowData={rowData}
      />
    </div>
  );
}
