import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../components/Table';
import { reqGetTransactions } from '../../../redux-store/modules/transactions';
import { formatISODate } from '../../../utilities';

export default function TransactionsTab() {
  const { Titles, Transactions } = useSelector((state) => state);
  const { txs = [] } = Transactions;
  const dispatch = useDispatch();

  const tableData = {
    transactionId: { label: 'Transaction ID', width: 0.5, sortable: true },
    eventId: { label: 'Event ID', width: 0.2, sortable: true },
    timestamp: { label: 'Date', width: 0.2, sortable: true },
    chainName: { label: 'Chain', width: 0.1, sortable: true },
  };

  const renderTxRow = (TxRow) => {
    const TxData = {
      table: {},
      raw: {},
    };
    Object.keys(TxRow).forEach((key) => {
      TxData.raw[key] = TxRow[key];
      switch (key) {
        case 'timestamp':
          TxData.table[key] =
            parseInt(TxRow[key]) === 0 ? '' : formatISODate(TxRow[key]);
          break;
        case 'transactionId':
          TxData.table[key] = TxRow[key].substring(0, 45) + '...';
          break;
        default:
          TxData.table[key] = TxRow[key];
          break;
      }
    });
    return TxData;
  };

  // Fetch data on first load and anytime the environment changes
  useEffect(() => {
    Titles.activeEnvironment && dispatch(reqGetTransactions());
  }, [dispatch, Titles.activeEnvironment]);

  const rowData = txs.length ? txs.map((tx) => renderTxRow(tx)) : [];

  return (
    <Fragment>
      <Table
        label={`Transactions (${rowData.length})`}
        downloadble={true}
        expandable={true}
        tableData={tableData}
        rowData={rowData}
      />
    </Fragment>
  );
}
