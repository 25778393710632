import baseTheme from './base';

const colorPrimary = '#FE395A';
const colorSecondary = '#9AFFFD';

const textPrimary = '#FFFFFF';
const textSecondary = '#E4E4E4';
const textLowlight = '#000000';

const bgColor1 = '#262C40';
const bgColor2 = '#1B2137';
const bgColor3 = '#3F4456';
const bgColor4 = '#3B4053';
const bgColor5 = '#171D31';

const theme = {
  ...baseTheme,
  body: {
    backgroundColor: bgColor2,
  },
  buttons: {
    ...baseTheme.buttons,
    outlined: {
      ...baseTheme.buttons.outlined,
      default: {
        ...baseTheme.buttons.outlined.default,
        color: textPrimary,
      },
    },
  },
  card: {
    backgroundColor: bgColor1,
    header: {
      backgroundColor: bgColor5,
      color: textSecondary,
    },
  },
  environments: {
    ...baseTheme.environments,
    card: {
      backgroundColor: '#3C4153',
    },
  },
  imageUploader: {
    backgroundColor: bgColor3,
  },
  modal: {
    backgroundColor: bgColor1,
  },
  orgSelect: {
    color: textPrimary,
  },
  overrides: {
    ...baseTheme.overrides,
    MuiCheckbox: {
      ...baseTheme.overrides.MuiCheckbox,
      colorSecondary: {
        '&.Mui-disabled': {
          color: 'rgba(255,255,255,0.25)',
        },
      },
      root: {
        ...baseTheme.overrides.MuiCheckbox.root,
        color: textPrimary,
      },
    },
    MuiFormControlLabel: {
      label: {
        '&.Mui-disabled': {
          color: textSecondary,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: textSecondary,
        '&.Mui-error': {
          color: colorPrimary,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: textPrimary,
        '&.Mui-disabled': {
          color: textSecondary,
        },
        '&.Mui-focused': {
          color: colorSecondary,
        },
        '&.Mui-error': {
          color: colorPrimary,
        },
      },
    },
    MuiIconButton: {
      root: {
        color: textSecondary,
      },
    },
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: textSecondary,
        },
        '&:after': {
          borderBottomColor: textSecondary,
        },
        '&:hover:before': {
          borderBottomColor: [textSecondary, '!important'],
        },
        '&.Mui-error:after': {
          borderBottomColor: colorPrimary,
        },
      },
      root: {
        color: textPrimary,
        '&:hover': {
          borderBottom: 'none',
        },
        '& .Mui-disabled': {
          color: textPrimary,
        },
      },
    },
    MuiInputAdornment: {
      root: {
        color: textPrimary,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: bgColor1,
        color: textPrimary,
      },
    },
    MuiPickersDay: {
      day: {
        color: textPrimary,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: textSecondary,
      },
      iconButton: {
        backgroundColor: 'none',
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: bgColor5,
      },
    },
    MuiPopover: {
      paper: {
        backgroundColor: bgColor1,
      },
    },
    MuiSelect: {
      icon: {
        color: textPrimary,
      },
    },
    MuiTypography: {
      colorTextSecondary: {
        color: textSecondary,
      },
    },
  },
  palette: {
    ...baseTheme.palette,
    primary: {
      ...baseTheme.palette.primary,
      main: colorPrimary,
    },
    secondary: {
      ...baseTheme.palette.secondary,
      main: colorSecondary,
    },
  },
  select: {
    backgroundColor: bgColor4,
  },
  table: {
    actions: {
      color: '#C9C9C9',
    },
    header: {
      backgroundColor: '#31364A',
    },
    highlight: {
      backgroundColor: bgColor5,
    },
    rows: {
      backgroundColor: bgColor5,
    },
  },
  tabs: {
    indicator: colorPrimary,
    buttonTabs: {
      root: {
        backgroundColor: bgColor4,
        color: '#666',
      },
      selected: {
        backgroundColor: '#DADBDF',
      },
    },
  },
  text: {
    primary: textPrimary,
    secondary: textSecondary,
    highlight: colorSecondary,
    lowlight: textLowlight,
  },
};

export default theme;
