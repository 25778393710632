import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import Environment from './environment';
// Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    margin: 0,
    padding: '0px 10px',
    minHeight: '48px !important',
  },
  accordionSummaryExpanded: {
    margin: '10px 0px 0px 0px !important',
  },
  titlePanel: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
    transitionProperty: 'none',
    margin: '0px !important',
    '&::before': {
      backgroundColor: theme.sideBar.backgroundColor,
    },
  },
  titlePanelArrow: {
    color: theme.sideBar.color,
    fontSize: '1em',
  },
  titlePanelDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  titlesItemText: {
    color: theme.sideBar.color,
    fontSize: theme.sideBar.fontSize,
    display: 'flex',
    marginLeft: 50,
  },
}));

const Title = (props) => {
  const { environmentTypes, isActive = false, onLinkClick, title } = props;
  const { environments = [] } = title;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(isActive);

  const ignoreClick = (event) => {
    event.stopPropagation();
    setExpanded(!expanded);
  };

  const renderEnvironments = (title) =>
    Object.keys(environmentTypes).map((envType) =>
      title.environments
        .filter((env) => env.environmentType === envType)
        .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
        .map((environment, i) => (
          <Environment
            key={i}
            environment={environment}
            onLinkClick={onLinkClick}
            titleId={title.id}
          />
        ))
    );

  return (
    <Accordion
      expanded={expanded}
      classes={{ root: classes.titlePanel }}
      onClick={ignoreClick}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        classes={{ content: classes.accordionSummaryExpanded }}
        expandIcon={<ExpandMoreIcon className={classes.titlePanelArrow} />}
      >
        <Typography className={classes.titlesItemText}>{title.name}</Typography>
      </AccordionSummary>
      {environments && environments.length && (
        <AccordionDetails className={classes.titlePanelDetails}>
          {renderEnvironments(title)}
        </AccordionDetails>
      )}
    </Accordion>
  );
};
export default Title;
