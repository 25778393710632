import actions from './actions';

const initialState = {
  issued: [],
  types: [],
};

export default function Items(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ISSUED_ITEMS:
      return {
        ...state,
        issued:
          (action.issuedItems &&
            action.issuedItems.length &&
            action.issuedItems.sort((a, b) => a.dgoodId - b.dgoodId)) ||
          [],
      };

    case actions.SET_ITEM_TYPES:
      return {
        ...state,
        types:
          (action.itemTypes &&
            action.itemTypes.length &&
            action.itemTypes.sort((a, b) => a.id - b.id)) ||
          [],
      };

    case actions.SET_LISTED_ITEMS:
      return {
        ...state,
        listed:
          (action.listedItems &&
            action.listedItems.length &&
            action.listedItems.sort((a, b) => a.id - b.id)) ||
          [],
      };

    case actions.UPDATE_ITEM_TYPE:
      return {
        ...state,
        types: state.types.map((type) =>
          type.id === action.item.id ? action.item : type
        ),
      };

    default:
      return state;
  }
}
