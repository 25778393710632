import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../components/UI/Button';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import TextField from '@material-ui/core/TextField';
import Modal from '../../components/Modal';
import TextWithHelp from '../../components/TextWithHelp';
import copy from '../../copy-text';

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: '0 15px',
  },
  actions: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 275,
  },
  formControl: {
    marginTop: theme.spacing(3),
  },
}));

const NewTitle = (props) => {
  const { onModalClose, onSubmit, openModal } = props;
  const emptyTitle = {
    titleName: '',
    initialEnvironmentDisplayName: 'DEV-1',
    initialEnvironmentType: 'DEV',
  };
  const [titleData, setTitleData] = useState(emptyTitle);
  const classes = useStyles();

  const formState = {
    titleName: {
      error: false,
      msg: copy.pages.titles.newTitle.modal.titleName.errorText,
    },
    initialEnvironmentDisplayName: {
      error: false,
      msg: copy.pages.titles.newTitle.modal.environmentName.errorText,
    },
  };
  const [formErrors, setFormErrors] = useState(formState);

  const validateTitle = () => {
    if (
      titleData.titleName.length &&
      titleData.initialEnvironmentDisplayName.length
    ) {
      setFormErrors(formState);
      onSubmit(titleData);
    } else {
      setFormErrors({
        ...formErrors,
        titleName: {
          ...formErrors.titleName,
          error: !titleData.titleName.length,
        },
        initialEnvironmentDisplayName: {
          ...formErrors.initialEnvironmentDisplayName,
          error: !titleData.initialEnvironmentDisplayName.length,
        },
      });
    }
  };

  const handleChange = (event) =>
    setTitleData({ ...titleData, [event.target.name]: event.target.value });
  const handleModalClose = () => {
    setTitleData(emptyTitle);
    setFormErrors(formState);
    onModalClose();
  };

  return (
    <Modal
      open={openModal}
      title={copy.pages.titles.newTitle.modal.title}
      onClose={handleModalClose}
      actions={
        <div className={classes.actions}>
          <Button type="contained" onClick={() => validateTitle()}>
            Create
          </Button>
          <Button onClick={handleModalClose} fill={false}>
            Cancel
          </Button>
        </div>
      }
    >
      <div className={classes.container}>
        <TextField
          autoFocus
          margin="dense"
          name="titleName"
          label={copy.pages.titles.newTitle.modal.titleName.label}
          inputProps={{ maxLength: 20 }}
          type="text"
          error={formErrors.titleName.error}
          helperText={
            formErrors.titleName.error
              ? formErrors.titleName.msg
              : copy.pages.titles.newTitle.modal.titleName.helperText
          }
          onChange={handleChange}
          value={titleData.titleName}
        />
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel component="legend">
            <TextWithHelp
              helpText={
                copy.pages.titles.newTitle.modal.environmentType.helperText
              }
            >
              {copy.pages.titles.newTitle.modal.environmentType.label}
            </TextWithHelp>
          </FormLabel>
          <RadioGroup
            aria-label="First Title Environment"
            name="initialEnvironmentType"
            value={titleData.initialEnvironmentType}
            onChange={handleChange}
          >
            <FormControlLabel value="DEV" control={<Radio />} label="DEV" />
            <FormControlLabel value="QA" control={<Radio />} label="QA" />
            <FormControlLabel
              value="STAGING"
              control={<Radio />}
              label="STAGING"
            />
            <FormControlLabel value="PROD" control={<Radio />} label="PROD" />
          </RadioGroup>
        </FormControl>
        <TextField
          margin="dense"
          type="text"
          name="initialEnvironmentDisplayName"
          label={copy.pages.titles.newTitle.modal.environmentName.label}
          inputProps={{ maxLength: 20 }}
          error={formErrors.initialEnvironmentDisplayName.error}
          helperText={
            formErrors.initialEnvironmentDisplayName.error
              ? formErrors.initialEnvironmentDisplayName.msg
              : copy.pages.titles.newTitle.modal.environmentName.helperText
          }
          onChange={handleChange}
          value={titleData.initialEnvironmentDisplayName}
        />
      </div>
    </Modal>
  );
};

export default NewTitle;
