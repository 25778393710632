import React from 'react';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

const MythicalDatePicker = (props) => {
  const {
    className,
    date,
    error = false,
    format = 'MM/DD/YYYY',
    helperText,
    label = '',
    name,
    onChange,
  } = props;

  const handleDateChange = (date) => {
    let timestamp;
    if (date) {
      timestamp = date.toDate().getTime();
      onChange(timestamp);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        fullWidth
        error={error}
        helperText={helperText}
        className={className}
        label={label}
        name={name}
        //maxDate={new Date()}
        value={date}
        onChange={handleDateChange}
        format={format}
      />
    </MuiPickersUtilsProvider>
  );
};

export default MythicalDatePicker;
