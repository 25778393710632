import actions from './actions';

const initialState = {
  txs: [],
};

export default function Transactions(state = initialState, action) {
  switch (action.type) {
    case actions.SET_TRANSACTIONS:
      return {
        ...state,
        txs: action.transactions,
      };

    default:
      return state;
  }
}
