import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../components/UI/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '../../components/Modal';
import copy from '../../copy-text';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const EditEnvironment = (props) => {
  const { environment, onModalClose, onSubmit, openModal, titleName } = props;

  const [envData, setEnvData] = useState({ ...environment });
  const classes = useStyles();

  const formState = {
    displayName: {
      error: false,
      msg: copy.pages.titles.editEnvironment.modal.displayName.errorText,
    },
  };
  const [formErrors, setFormErrors] = useState(formState);

  const validateTitle = () => {
    if (envData.displayName.length) {
      setFormErrors(formState);
      onSubmit(envData);
    } else {
      setFormErrors({
        ...formErrors,
        displayName: {
          ...formErrors.displayName,
          error: !envData.displayName.length,
        },
      });
    }
  };

  const handleChange = (event) =>
    setEnvData({ ...envData, [event.target.name]: event.target.value });
  const handleModalClose = () => {
    setEnvData(environment);
    setFormErrors(formState);
    onModalClose();
  };

  return (
    <Modal
      open={openModal}
      title={copy.pages.titles.editEnvironment.modal.title}
      onClose={handleModalClose}
      actions={
        <div className={classes.actions}>
          {envData.displayName !== environment.displayName ? (
            <Button type="contained" onClick={() => validateTitle()}>
              Update
            </Button>
          ) : null}
          <Button onClick={handleModalClose} fill={false}>
            Cancel
          </Button>
        </div>
      }
    >
      <div className={classes.container}>
        <TextField
          margin="dense"
          type="text"
          disabled
          label={copy.pages.titles.editEnvironment.modal.titleName.label}
          value={titleName}
        />
        <TextField
          margin="dense"
          type="text"
          disabled
          label={copy.pages.titles.editEnvironment.modal.environmentId.label}
          value={envData.id}
        />
        <TextField
          margin="dense"
          type="text"
          disabled
          label={copy.pages.titles.editEnvironment.modal.environmentType.label}
          value={envData.environmentType}
        />
        <TextField
          autoFocus
          margin="dense"
          type="text"
          name="displayName"
          label={copy.pages.titles.editEnvironment.modal.displayName.label}
          error={formErrors.displayName.error}
          helperText={
            formErrors.displayName.error ? formErrors.displayName.msg : ''
          }
          inputProps={{ maxLength: 20 }}
          onChange={handleChange}
          value={envData.displayName}
        />
      </div>
    </Modal>
  );
};

export default EditEnvironment;
