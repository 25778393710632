import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import UserAvatar from '../UserAvatar';
import { lightGrey } from '../../themes/base';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.text.secondary,
    display: 'flex',
    flexDirection: 'column',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    padding: 20,
  },
  details: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    fontSize: '0.9em',
    marginTop: 10,
    marginBottom: 10,
  },
  avatar: {
    display: 'flex',
    width: 100,
    height: 100,
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 20,
  },
  name: {
    color: `${theme.text.primary} !important`,
    fontWeight: 'bold',
    fontSize: '0.9em',
  },
  email: {
    color: `${theme.text.primary} !important`,
    fontSize: '1em',
  },
  logout: {
    color: theme.text.secondary,
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '1em',
    justifyContent: 'center',
    padding: 10,
    borderTop: `2px solid ${lightGrey}`,
  },
}));

const ProfilePopover = (props) => {
  const { anchorEl, profile, onClose, onLogout, onViewProfile } = props;
  const classes = useStyles();

  const handleClick = () => {
    onClose();
    onViewProfile();
  };

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <div className={classes.root}>
        <div className={classes.row}>
          <div className={classes.avatar}>
            <UserAvatar imageSrc={profile.picture} />
          </div>
          <div className={classes.col}>
            <Typography className={classes.name}>{profile.name}</Typography>
            <Typography className={classes.email}>{profile.email}</Typography>
            <Typography
              className={classes.details}
              onClick={() => handleClick()}
            >
              View account
            </Typography>
          </div>
        </div>
        <div className={classes.logout} onClick={() => onLogout()}>
          <Typography>Log out</Typography>
        </div>
      </div>
    </Popover>
  );
};

export default ProfilePopover;
