import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import GridLayout, { WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import {
  setActivePage,
  setDashboardLayout,
} from '../../redux-store/modules/settings';
import { setActiveEnvironment } from '../../redux-store/modules/titles';
import Page from '../../components/Page';
import Select from '../../components/UI/Select';
import Items from './Cards/Items';
import AtAGlance from './Cards/AtAGlance';
import Charts from './Cards/Charts';
import Favorites from './Cards/Favorites';
import Notifications from './Cards/Notifications';
import Activity from './Cards/Activity';
import { ReactComponent as AccountsIcon } from '../../assets/icons/Dashboard-Accounts.svg';
import { ReactComponent as RevenueIcon } from '../../assets/icons/Dashboard-Revenue.svg';
import { ReactComponent as IssuedItemsIcon } from '../../assets/icons/Dashboard-IssuedItems.svg';
import { ReactComponent as SoldItemsIcon } from '../../assets/icons/Dashboard-SoldItems.svg';
import getTheme from '../../themes';
import copy from '../../copy-text';

const ResponsiveGridLayout = WidthProvider(GridLayout);

const useStyles = makeStyles(
  {
    col: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      '&:last-child': {
        marginLeft: 20,
      },
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    dashContainer: ({ height }: any) => ({
      marginTop: 20,
      width: '100%',
      height: height - 200,
    }),
    envSelect: {
      width: 350,
    },
    page: {
      height: '100%',
    },
    select: {
      marginLeft: 10,
      marginRight: 10,
    },
  },
  { name: 'Dashboard' }
);

export default function Dashboard() {
  const dispatch = useDispatch();
  const { Settings, Titles } = useSelector((state) => state);
  const { colorTheme, dashboard, screenDims } = Settings;
  const { activeEnvironment, titles } = Titles;
  const theme = getTheme(colorTheme);
  const { revenue, accounts, issuedItems, soldItems } = theme.dashboard.icons;
  const classes = useStyles({ height: screenDims.height });
  const envOptions = titles.reduce((acc, title) => {
    const { name } = title;
    return [
      ...acc,
      ...title.environments.map(({ id, displayName }) => ({
        value: id,
        label: `${name} - ${displayName}`,
      })),
    ];
  }, []);

  const handleChangeEnvironment = (event) => {
    const newEnv = titles.reduce(
      (acc, title) => ({
        ...acc,
        ...title.environments.find((env) => env.id === event.target.value),
      }),
      {}
    );
    dispatch(setActiveEnvironment(newEnv));
  };

  // Set initial activePage and environment
  useEffect(() => {
    titles.length &&
      !activeEnvironment &&
      dispatch(setActiveEnvironment(titles[0].environments[0]));
    dispatch(setActivePage('Dashboard'));
  }, [activeEnvironment, dispatch, titles]);

  return (
    <Page title={copy.pages.dashboard.title} className={classes.page}>
      <Select
        className={classes.envSelect}
        label="Environment"
        name="environment"
        value={
          (activeEnvironment &&
            envOptions.length &&
            envOptions.find((env) => env.value === activeEnvironment.id)
              ?.value) ||
          ''
        }
        onChange={handleChangeEnvironment}
        options={envOptions}
      />
      <ResponsiveGridLayout
        className={classes.dashContainer}
        isBounded={true}
        layout={dashboard.layout}
        cols={24}
        rowHeight={30}
        margin={[16, 16]}
        containerPadding={[0, 0]}
        //autoSize={false}
        compactType="vertical"
        onLayoutChange={(currentLayout) =>
          dispatch(setDashboardLayout(currentLayout))
        }
        //onDrag={(layout, oldItem, newItem, placeholder, event, el) => console.log('newItem: ', newItem)}
        //onDragStart={(layout, oldItem, newItem, placeholder, event, el) => console.log('newItem: ', newItem)}
        //onWidthChange={(containerWidth, margin, cols) => console.log('containerWidth: ', containerWidth)}
      >
        <div key="revenue">
          <AtAGlance
            title={copy.pages.dashboard.cards.revenue.label}
            range="Today"
            value="$00,000,000"
            icon={<RevenueIcon />}
            color={revenue.color}
          />
        </div>
        <div key="accounts">
          <AtAGlance
            title={copy.pages.dashboard.cards.accounts.label}
            range="Today"
            value="000,000,000"
            icon={<AccountsIcon />}
            color={accounts.color}
          />
        </div>
        <div key="issuedItems">
          <AtAGlance
            title={copy.pages.dashboard.cards.issuedItems.label}
            range="Today"
            value="000,000,000"
            icon={<IssuedItemsIcon />}
            color={issuedItems.color}
          />
        </div>
        <div key="soldItems">
          <AtAGlance
            title={copy.pages.dashboard.cards.soldItems.label}
            range="Today"
            value="000,000,000"
            icon={<SoldItemsIcon />}
            color={soldItems.color}
          />
        </div>
        <div key="activity">
          <Activity />
        </div>
        <div key="charts">
          <Charts />
        </div>
        <div key="items">
          <Items />
        </div>
        <div key="notifications">
          <Notifications />
        </div>

        {/*           
          <div key="favorites"><Favorites className={classes.card} /></div>
        */}
      </ResponsiveGridLayout>
    </Page>
  );
}
