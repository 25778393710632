import React, { Fragment, useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import ReactCrop from 'react-image-crop';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../UI/Button';
import Typography from '@material-ui/core/Typography';
import Modal from '../Modal';
import copy from '../../copy-text';
import 'react-image-crop/dist/ReactCrop.css';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '10px 0px',
    width: 150,
  },
  dropzone: {
    border: '2px white dashed',
    borderRadius: 100,
    color: 'white',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 200,
    height: 200,
  },
  dropzoneContainer: () => ({
    backgroundColor: theme.imageUploader.backgroundColor,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 500,
    height: 500,
    userSelect: 'none',
    outline: 'none !important;',
    webkitTapHighlightColor: 'transparent',
  }),
  dropzoneContainerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropzoneCaption: {
    color: theme.text.secondary,
    fontSize: '1em',
    marginTop: 10,
  },
  icon: {
    color: 'white',
  },
  reactCrop: {
    backgroundColor: 'white',
    maxWidth: 500,
    maxHeight: 500,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
    width: 500,
  },
}));

const ImageUploader = (props: any) => {
  const { onClose, onSubmit, open } = props;
  const [rawImgSrc, setRawImgSrc] = useState(null);
  const [crop, setCrop] = useState({ aspect: 1, width: 200, height: 200 });
  const [imageRef, setImageRef] = useState(null);
  const [croppedImgData, setCroppedImgData] = useState<any>();
  const classes = useStyles({ rawImgSrc });

  const handleImageLoaded = (image) => setImageRef(image);
  const handleCropComplete = (crop) => {
    const getCroppedImg = (image, crop) => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      return canvas.toDataURL('image/png');
    };

    const makeClientCrop = (crop) => {
      const croppedImg = getCroppedImg(imageRef, crop);
      setCroppedImgData(croppedImg);
    };

    if (imageRef && crop.width && crop.height) {
      makeClientCrop(crop);
    }
  };
  const handleSave = () => {
    const payload = {
      imageBase64: croppedImgData.replace(/^data:image\/[a-z]+;base64,/, ''),
      extension: croppedImgData.substring(
        'data:image/'.length,
        croppedImgData.indexOf(';base64')
      ),
    };
    onSubmit(payload);
    handleModalClose();
  };

  const onDrop = useCallback((files) => {
    const fileReader = new FileReader();
    const file = files[0];

    fileReader.onload = () => {
      setRawImgSrc(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleModalClose = () => {
    setImageRef(null);
    setRawImgSrc(null);
    setCroppedImgData(null);
    setCrop({ aspect: 1, width: 200, height: 200 });
    onClose();
  };

  const dropzone = (
    <div className={classes.dropzoneContainerWrapper}>
      <div className={classes.dropzoneContainer} {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={classes.dropzone}>
          {isDragActive ? (
            <Typography align="center">Drop file here...</Typography>
          ) : (
            <Typography align="center">
              Drag and drop a file or click to add
            </Typography>
          )}
        </div>
      </div>
      <Typography align="center" className={classes.dropzoneCaption}>
        Supported file types: JPEG, PNG
      </Typography>
    </div>
  );

  const cropper = rawImgSrc && (
    <div className={classes.dropzoneContainer} {...getRootProps()}>
      <Typography align="center" className={classes.dropzoneCaption}>
        Click & drag cursor to position.
        <br />
        Drag corners to crop.
      </Typography>
      <ReactCrop
        className={classes.reactCrop}
        src={rawImgSrc}
        crop={crop}
        circularCrop={true}
        onComplete={handleCropComplete}
        onImageLoaded={handleImageLoaded}
        onChange={(crop) => setCrop(crop)}
      />
    </div>
  );

  useEffect(() => {
    handleCropComplete(crop);
  }, [imageRef, crop, handleCropComplete]);

  return (
    <Modal
      className={classes.modal}
      open={open}
      title={copy.components.imageUploader.title}
      onClose={handleModalClose}
      actions={
        <Fragment>
          {rawImgSrc && (
            <Button type="contained" fill={true} onClick={handleSave}>
              Save
            </Button>
          )}
          <Button fill={false} onClick={handleModalClose}>
            Cancel
          </Button>
        </Fragment>
      }
    >
      {rawImgSrc !== null ? cropper : dropzone}
    </Modal>
  );
};

export default ImageUploader;
