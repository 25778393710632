import actions from './actions';
import getTheme from '../../../themes';
const theme = getTheme();
const { colors } = theme.environments;

const initialState = {
  activeEnvironment: null,
  activeTitle: null,
  currencies: [],
  environmentTypes: {
    DEV: { color: colors.DEV },
    QA: { color: colors.QA },
    STAGING: { color: colors.STAGING },
    PROD: { color: colors.PROD },
  },
  titles: [],
};

export default function Titles(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ACTIVE_ENVIRONMENT:
      return {
        ...state,
        activeEnvironment: action.environment,
      };

    case actions.SET_ACTIVE_TITLE:
      return {
        ...state,
        activeTitle: action.title,
      };

    case actions.SET_CURRENCIES:
      return {
        ...state,
        currencies: action.currencies,
      };

    case actions.SET_TITLES:
      return {
        ...state,
        titles: action.titles,
      };

    case actions.UPDATE_ACTIVE_TITLE:
      return {
        ...state,
        activeTitle: action.title,
      };

    default:
      return state;
  }
}
