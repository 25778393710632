const actions = {
  REQ_ADD_CURRENCY: 'REQ_ADD_CURRENCY',
  REQ_ADD_ENVIRONMENT: 'REQ_ADD_ENVIRONMENT',
  REQ_ADD_TITLE: 'REQ_ADD_TITLE',
  REQ_DELETE_CURRENCY: 'REQ_DELETE_CURRENCY',
  REQ_DELETE_TITLE: 'REQ_DELETE_TITLE',
  REQ_GET_CURRENCY: 'REQ_GET_CURRENCY',
  REQ_GET_ENVIRONMENTS: 'REQ_GET_ENVIRONMENTS',
  REQ_GET_TITLES: 'REQ_GET_TITLES',
  REQ_UPDATE_ENVIRONMENT: 'REQ_UPDATE_ENVIRONMENT',
  REQ_UPDATE_TITLE: 'REQ_UPDATE_TITLE',
  SET_ACTIVE_ENVIRONMENT: 'SET_ACTIVE_ENVIRONMENT',
  SET_ACTIVE_TITLE: 'SET_ACTIVE_TITLE',
  SET_CURRENCIES: 'SET_CURRENCIES',
  SET_TITLES: 'SET_TITLES',
  UPDATE_ACTIVE_TITLE: 'UPDATE_ACTIVE_TITLE',
};

export const reqAddCurrency = (currency) => ({
  type: actions.REQ_ADD_CURRENCY,
  currency,
});
export const reqAddEnvironment = (environment, titleId) => ({
  type: actions.REQ_ADD_ENVIRONMENT,
  environment,
  titleId,
});
export const reqAddTitle = (title) => ({ type: actions.REQ_ADD_TITLE, title });
export const reqDeleteCurrency = (abbreviation) => ({
  type: actions.REQ_DELETE_CURRENCY,
  abbreviation,
});
export const reqDeleteTitle = (titleId) => ({
  type: actions.REQ_DELETE_TITLE,
  titleId,
});
export const reqGetCurrency = () => ({ type: actions.REQ_GET_CURRENCY });
export const reqGetEnvironments = () => ({
  type: actions.REQ_GET_ENVIRONMENTS,
});
export const reqUpdateEnvironment = (environment) => ({
  type: actions.REQ_UPDATE_ENVIRONMENT,
  environment,
});
export const reqUpdateTitle = ({ id, name }) => ({
  type: actions.REQ_UPDATE_TITLE,
  payload: { id, name },
});
export const reqGetTitles = () => ({ type: actions.REQ_GET_TITLES });
export const setCurrencies = (currencies) => ({
  type: actions.SET_CURRENCIES,
  currencies,
});
export const setTitles = (titles) => ({ type: actions.SET_TITLES, titles });
export const setActiveEnvironment = (environment) => ({
  type: actions.SET_ACTIVE_ENVIRONMENT,
  environment,
});
export const setActiveTitle = (title) => ({
  type: actions.SET_ACTIVE_TITLE,
  title,
});
export const updateActiveTitle = (title) => ({
  type: actions.UPDATE_ACTIVE_TITLE,
  title,
});

export default actions;
