import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  InputAdornment,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from '../../components/UI/Button';
import Select from '../../components/UI/Select';
import Card from '../../components/Card';
import DatePicker from '../../components/DatePicker';
import { areEqual } from '../../utilities';
import {
  reqAddAgreement,
  reqUpdateAgreement,
} from '../../redux-store/modules/partners';

const useStyles = makeStyles((theme) => ({
  viewContainer: {
    backgroundColor: theme.colors.bgColor1,
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
    position: 'relative',
  },
  column: {
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    width: 350,
    marginRight: 30,
    '&:last-child': {
      marginRight: 0,
    },
  },
  columnRow: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  header: {
    color: theme.card.header.color,
    backgroundColor: theme.card.header.backgroundColor,
    borderRadius: '5px 5px 0px 0px',
    padding: '10px 20px',
    marginTop: 20,
  },
  formWrapper: {
    backgroundColor: theme.colors.bgColor4,
    borderTop: 'none',
    borderRadius: '0px 0px 5px 5px',
    padding: 20,
  },
  input: {
    margin: '10px 0px',
  },
  revShare: {
    width: 100,
    '& p.Mui-error': {
      width: 200,
    },
    '& input': {
      textAlign: 'right',
    },
  },
  revShareInputLabel: {
    fontWeight: 'bold',
  },
  revShareLabel: {
    fontSize: 16,
    marginTop: 20,
    marginLeft: 20,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    '& div:first-child': {
      marginRight: 15,
    },
  },
  checkboxLabel: {
    fontSize: 14,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 30,
    justifyContent: 'flex-end',
  },
  title: {
    color: theme.text.primary,
  },
  link: {
    color: theme.text.highlight,
    cursor: 'pointer',
    fontSize: '1em',
    marginTop: 10,
  },
}));

export default function AgreementForm(props) {
  const { agreementData, title } = props;
  const { Partners, Titles } = useSelector((state) => state);
  const { partners = [] } = Partners;
  const { titles = [] } = Titles;
  const isNewForm = agreementData && agreementData.id ? false : true;
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();

  const blankForm = {
    currency: 'USD',
    name: '',
    paySchedule: 'MONTHLY',
    partnerId: '',
    titleId: '',
    timeFrameStart: new Date().getTime(),
    timeFrameEnd: new Date().getTime(),
    active: false,
    itemTypeNames: [],
    revShareInitial: 0.0,
    revShareResale: 0.0,
    notes: '',
    ...agreementData,
  };
  const noErrors = Object.keys(blankForm).reduce(
    (acc, key) => ({ ...acc, [key]: false }),
    {}
  );
  const [formData, setFormData] = useState<any>({ ...blankForm });
  const [newItemTypeName, setNewItemTypeName] = useState('');
  const [hasErrors, setHasErrors] = useState<any>(noErrors);
  const [hasChanges, setHasChanges] = useState<any>(!areEqual(blankForm, formData));

  const handleChange = (event) => {
    const updatedFormData = {
      ...formData,
      [event.target.name]: event.target.value,
    };
    setFormData(updatedFormData);
    setHasChanges(!areEqual(blankForm, updatedFormData));
  };
  const handleAddItemTypeName = () => {
    const itemTypeNames = [...formData.itemTypeNames, newItemTypeName];
    setFormData({ ...formData, itemTypeNames });
    setNewItemTypeName('');
    setHasChanges(agreementData.itemTypeNames !== itemTypeNames);
  };
  const handleChangeItemTypeName = (event, index) => {
    let itemTypeNames = [...formData.itemTypeNames];
    itemTypeNames[index] = event.target.value;
    setFormData({ ...formData, itemTypeNames });
    setHasChanges(agreementData.itemTypeNames !== itemTypeNames);
  };
  const handleChangeItemTypeNameInput = (event) => {
    setNewItemTypeName(event.target.value);
    setHasChanges(event.target.value.length ? true : false);
  };
  const handleChangeRevShare = (event) => {
    const value = event.target.value / 100;
    const updatedFormData = { ...formData, [event.target.name]: value };
    if (value <= 1) {
      setFormData(updatedFormData);
      setHasChanges(!areEqual(blankForm, updatedFormData));
    }
  };
  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleAddItemTypeName();
    }
  };
  const handleCancel = () => {
    // Clear form
    setFormData(blankForm);
    setHasErrors(noErrors);
    history.goBack();
  };
  const handleSaveNew = () => {
    if (validate(formData)) {
      const sanitizedData = sanitize(formData);
      // submit
      dispatch(reqAddAgreement(sanitizedData));
      history.push(`/partner/${formData.partnerId}/agreements`);
    }
  };
  const handleSaveChanges = () => {
    if (validate(formData)) {
      const sanitizedData = sanitize(formData);
      dispatch(reqUpdateAgreement(sanitizedData));
      history.push(`/partner/${formData.partnerId}/agreements`);
    }
  };

  const validate = (data) => {
    if (
      data.name.length &&
      data.partnerId.length &&
      data.titleId.length &&
      data.revShareInitial >= 0 &&
      !isNaN(data.revShareInitial) &&
      data.revShareResale >= 0 &&
      !isNaN(data.revShareResale) &&
      data.timeFrameStart > 0 &&
      data.timeFrameEnd > 0 &&
      data.timeFrameStart < data.timeFrameEnd
    ) {
      setHasErrors(noErrors);
      return true;
    } else {
      setHasErrors({
        ...hasErrors,
        name: data.name.length ? false : true,
        partnerId: data.partnerId.length ? false : true,
        titleId: data.titleId.length ? false : true,
        revShareInitial:
          data.revShareInitial >= 0 &&
          data.revShareInitial <= 1 &&
          !isNaN(data.revShareInitial)
            ? false
            : true,
        revShareResale:
          data.revShareResale >= 0 &&
          data.revShareResale <= 1 &&
          !isNaN(data.revShareResale)
            ? false
            : true,
        timeFrameStart: data.timeFrameStart > 0 ? false : true,
        timeFrameEnd:
          data.timeFrameEnd > 0 && data.timeFrameStart < data.timeFrameEnd
            ? false
            : true,
      });
      return false;
    }
  };

  const sanitize = (data) => {
    const sanitizedData = { ...data };
    sanitizedData.itemTypeNames = data.itemTypeNames.filter(
      (itemType) => itemType.length >= 1
    );
    newItemTypeName.length && sanitizedData.itemTypeNames.push(newItemTypeName);
    return sanitizedData;
  };

  const terms = (
    <div className={classes.column}>
      <div className={classes.header}>
        <Typography>Agreement Terms</Typography>
      </div>
      <Card className={classes.formWrapper}>
        {partners.length ? (
          <FormControl fullWidth className={classes.input}>
            <Select
              fullWidth
              //disabled={agreementData.partnerId ? true: false}
              error={hasErrors.partnerId}
              labelId="partner-select-label"
              label="Partner"
              name="partnerId"
              value={formData.partnerId}
              onChange={handleChange}
              options={partners.map((partner, i) => ({
                value: partner.id,
                label: partner.company,
              }))}
            />
          </FormControl>
        ) : null}
        {titles.length ? (
          <FormControl fullWidth className={classes.input}>
            <Select
              fullWidth
              error={hasErrors.titleId}
              labelId="game-title-select-label"
              label="Game Title"
              name="titleId"
              value={formData.titleId}
              onChange={handleChange}
              options={titles.map((title, i) => ({
                value: title.id,
                label: title.name,
              }))}
            />
          </FormControl>
        ) : null}
        <TextField
          className={classes.input}
          error={hasErrors.name}
          label="Agreement Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <DatePicker
          className={classes.input}
          error={hasErrors.timeFrameStart}
          date={new Date(formData.timeFrameStart).getTime()}
          label="Start Date"
          format="MMMM DD, YYYY"
          onChange={(value) =>
            handleChange({ target: { name: 'timeFrameStart', value } })
          }
        />
        <DatePicker
          className={classes.input}
          error={hasErrors.timeFrameEnd}
          helperText={
            hasErrors.timeFrameEnd
              ? 'Expiration date must be after start date'
              : ''
          }
          date={new Date(formData.timeFrameEnd).getTime()}
          label="Expiration Date"
          format="MMMM DD, YYYY"
          onChange={(value) =>
            handleChange({ target: { name: 'timeFrameEnd', value } })
          }
        />
        <FormControl fullWidth className={classes.input}>
          <Select
            fullWidth
            label="Status"
            labelId="status-select-label"
            name="active"
            value={formData.active}
            onChange={handleChange}
            options={[
              { value: true, label: 'Active' },
              { value: false, label: 'Inactive' },
            ]}
          />
        </FormControl>
      </Card>
    </div>
  );

  const itemTypeNames = (
    <div className={classes.column}>
      <div className={classes.header}>
        <Typography>Item Types</Typography>
      </div>
      <Card className={classes.formWrapper}>
        {formData.itemTypeNames && formData.itemTypeNames.length
          ? formData.itemTypeNames.map((itemType, i) => (
              <TextField
                key={i}
                className={classes.input}
                label={`Item Type ${i + 1}`}
                name="itemType"
                value={itemType}
                onChange={(event) => handleChangeItemTypeName(event, i)}
              />
            ))
          : null}
        <TextField
          className={classes.input}
          helperText="Press enter to save and add new item type"
          label="New Item Type"
          name="newitemTypeName"
          value={newItemTypeName}
          onChange={handleChangeItemTypeNameInput}
        />
        <Typography className={classes.link} onClick={handleAddItemTypeName}>
          + Add Item Type
        </Typography>
      </Card>
    </div>
  );

  const revenueShare = (
    <div className={classes.column}>
      <div className={classes.header}>
        <Typography>Revenue Share</Typography>
      </div>
      <Card className={classes.formWrapper}>
        <FormControlLabel
          className={classes.input}
          classes={{ label: classes.revShareLabel }}
          control={
            <TextField
              className={classes.revShare}
              InputLabelProps={{ className: classes.revShareInputLabel }}
              error={hasErrors.revShareInitial}
              helperText={
                hasErrors.revShareInitial ? 'must be between 0% and 100%' : ''
              }
              //InputProps={{ inputProps: { min: 0, max: 100 } }}
              label="First Sale"
              name="revShareInitial"
              //type="number"
              value={formData.revShareInitial * 100}
              onChange={handleChangeRevShare}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          }
          label="of Mythical Games Net"
          labelPlacement="end"
        />
        <FormControlLabel
          className={classes.input}
          classes={{ label: classes.revShareLabel }}
          control={
            <TextField
              className={classes.revShare}
              InputLabelProps={{ className: classes.revShareInputLabel }}
              error={hasErrors.revShareResale}
              helperText={
                hasErrors.revShareResale ? 'must be between 0% and 100%' : ''
              }
              //InputProps={{ inputProps: { min: 0, max: 100 } }}
              label="Resales"
              name="revShareResale"
              //type="number"
              value={formData.revShareResale * 100}
              onChange={handleChangeRevShare}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          }
          label="of Mythical Games Net"
          labelPlacement="end"
        />
        <FormControl fullWidth className={classes.input}>
          <Select
            fullWidth
            label="Pay Schedule"
            labelId="pay-schedule"
            name="paySchedule"
            value={formData.paySchedule}
            onChange={handleChange}
            options={[
              { value: 'MONTHLY', label: 'Monthly' },
              { value: 'QUARTERLY', label: 'Quarterly' },
            ]}
          />
        </FormControl>
      </Card>
    </div>
  );

  const notes = (
    <div className={classes.column}>
      <div className={classes.header}>
        <Typography>Additional Notes</Typography>
      </div>
      <Card className={classes.formWrapper}>
        <TextField
          multiline
          className={classes.input}
          label="Additional Notes"
          name="notes"
          helperText={
            formData.notes && formData.notes.length
              ? `${formData.notes.length}/500 character limit`
              : '500 character limit'
          }
          value={formData.notes}
          onChange={handleChange}
          inputProps={{ maxLength: 500 }}
        />
      </Card>
    </div>
  );

  const actions = (
    <div className={classes.actions}>
      {hasChanges && isNewForm && (
        <>
          <Button type="contained" label="Save" onClick={handleSaveNew} />
          <Button label="Cancel" onClick={handleCancel} />
        </>
      )}
      {hasChanges && !isNewForm && (
        <>
          <Button type="contained" label="Save" onClick={handleSaveChanges} />
          <Button label="Cancel" onClick={handleCancel} />
        </>
      )}
    </div>
  );

  // Add event listener for enter key press
  useEffect(() => {
    window.addEventListener('keyup', handleKeyUp);
    // Remove event listeners on cleanup
    return () => window.removeEventListener('keyup', handleKeyUp);
  }, [newItemTypeName, handleKeyUp]);

  return (
    <div className={classes.viewContainer}>
      <Typography className={classes.title}>
        {agreementData.name ? agreementData.name : title}
      </Typography>
      <div className={classes.columnRow}>
        {terms}
        {itemTypeNames}
        <div className={classes.column}>
          {revenueShare}
          {notes}
        </div>
      </div>
      {actions}
    </div>
  );
}
