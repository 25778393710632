import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '../../../components/Card';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 0,
    marginBottom: 30,
  },
  title: {
    fontSize: '1.5em',
  },
  row: ({ isMobile }: any) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: isMobile ? 'center' : 'left',
  }),
  col1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 300,
    minWidth: 300,
    height: 300,
  },
  col2: ({ isMobile }: any) => ({
    display: 'flex',
    flexDirection: 'column',
    marginLeft: isMobile ? 0 : 30,
    width: 300,
    minWidth: 300,
  }),
}));

const ProfileCard = (props) => {
  const { col1, col2, isMobile = false, title } = props;
  const classes = useStyles({ isMobile });

  return (
    <Card className={classes.card}>
      <Typography className={classes.title}>{title}</Typography>
      <div className={classes.row}>
        <div className={classes.col1}>{col1}</div>
        <div className={classes.col2}>{col2}</div>
      </div>
    </Card>
  );
};

export default ProfileCard;
