import {
  all,
  debounce,
  fork,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';
import actions, {
  reqGetCurrency,
  setActiveTitle,
  setCurrencies,
  setTitles,
  updateActiveTitle,
} from './actions';
import { reqGetWebhooks } from '../webhooks/actions';
import {
  reqGetItemTypes,
  reqGetIssuedItems,
  setIssuedItems,
  setItemTypes,
} from '../items';
import { reqGetPlayers, setPlayers } from '../players';
import { setWebhooks } from '../webhooks';
import { reqGetTransactions, setTransactions } from '../transactions';
import {
  addCurrency,
  addEnvironment,
  addTitle,
  deleteCurrency,
  deleteTitle,
  getCurrency,
  getTitle,
  getTitles,
  updateEnvironment,
  updateTitle,
} from '../../../services/ivi-engine';
import { mythicalFetcher } from '../../utilities';

function* addCurrencySaga(action) {
  try {
    const response = yield mythicalFetcher(addCurrency, [action.currency]);
    if (response.createdTimestamp) {
      const state = yield select();
      const newCurrencies = Object.assign([], state.Titles.currencies);
      newCurrencies.push(action.currency);
      yield put(setCurrencies(newCurrencies));
    }
  } catch (e) {}
}
function* addEnvironmentSaga(action) {
  try {
    const newEnvironment = yield mythicalFetcher(addEnvironment, [
      action.environment,
      action.titleId,
    ]);
    if (newEnvironment.id) {
      const state = yield select();
      const newTitles = state.Titles.titles.map((title) =>
        title.id === newEnvironment.parentTitleId
          ? { ...title, environments: [...title.environments, newEnvironment] }
          : title
      );
      yield put(setTitles(newTitles));
    }
  } catch (e) {}
}
function* addTitleSaga(action) {
  try {
    const newTitle = yield mythicalFetcher(addTitle, [action.title]);
    if (newTitle.id) {
      const state = yield select();
      const newTitles = Object.assign([], state.Titles.titles);
      newTitles.push({ ...newTitle, createdTimestamp: Date.now() });
      yield put(setTitles(newTitles));
    }
  } catch (e) {}
}
function* deleteCurrencySaga(action) {
  try {
    const response = yield mythicalFetcher(deleteCurrency, [
      action.abbreviation,
    ]);
    if (response.ok) {
      const state = yield select();
      const newCurrencies = state.Titles.currencies.filter(
        (currency) => currency.abbreviation !== action.abbreviation
      );
      yield put(setCurrencies(newCurrencies));
    }
  } catch (e) {}
}
function* deleteTitleSaga(action) {
  try {
    const response = yield mythicalFetcher(deleteTitle, [action.titleId]);
    if (response.ok) {
      const state = yield select();
      const newTitles = state.Titles.titles.filter(
        (title) => title.id !== action.titleId
      );
      yield put(setTitles(newTitles));
    }
  } catch (e) {}
}
function* getCurrencySaga() {
  try {
    const currencies = yield mythicalFetcher(getCurrency);
    yield put(setCurrencies(currencies));
  } catch (e) {}
}
function* getTitlesSaga() {
  try {
    const response = yield mythicalFetcher(getTitles);
    const titles = Array.isArray(response) ? response : [];
    yield put(setTitles(titles));
    if (Array.isArray(response)) {
      const state = yield select();
      // If no activeTitle is set then use the first result
      if (titles.length && state.Titles.activeTitle === null) {
        yield put(setActiveTitle(titles[0]));
      } else if (!state.Titles.activeTitle.hasOwnProperty('name')) {
        yield put(
          setActiveTitle(
            titles.find((title) => title.id === state.Titles.activeTitle.id)
          )
        );
      }
    }
  } catch (e) {}
}
function* setActiveEnvironmentSaga() {
  yield all([
    put(setTransactions([])),
    put(setItemTypes([])),
    put(setIssuedItems([])),
    put(setPlayers([])),
    put(setCurrencies([])),
    put(setWebhooks([])),
    put(reqGetItemTypes()),
    put(reqGetIssuedItems()),
    put(reqGetTransactions()),
    put(reqGetPlayers()),
    put(reqGetCurrency()),
    put(reqGetWebhooks()),
  ]);
}
function* setActiveTitleSaga(action) {
  try {
    const title = yield mythicalFetcher(getTitle, [action.title]);
    yield put(updateActiveTitle(title));
  } catch (e) {}
}
function* updateEnvironmentSaga(action) {
  try {
    const response = yield mythicalFetcher(updateEnvironment, [
      action.environment,
    ]);
    if (response.ok) {
      const state = yield select();
      const newTitles = state.Titles.titles.map((title) =>
        title.id === action.environment.parentTitleId
          ? {
              ...title,
              environments: title.environments.map((environment) =>
                environment.id === action.environment.id
                  ? {
                      ...environment,
                      displayName: action.environment.displayName,
                    }
                  : environment
              ),
            }
          : title
      );
      yield put(setTitles(newTitles));
    }
  } catch (e) {}
}
function* updateTitleSaga(action) {
  try {
    const response = yield mythicalFetcher(updateTitle, [action.payload]);
    if (response.ok) {
      const state = yield select();
      const newTitles = state.Titles.titles.map((title) =>
        title.id === action.payload.id
          ? { ...title, name: action.payload.name }
          : title
      );
      yield put(setTitles(newTitles));
    }
  } catch (e) {}
}
function* watchAddCurrencySaga() {
  yield takeLatest(actions.REQ_ADD_CURRENCY, addCurrencySaga);
}
function* watchAddEnvironmentSaga() {
  yield takeLatest(actions.REQ_ADD_ENVIRONMENT, addEnvironmentSaga);
}
function* watchAddTitleSaga() {
  yield takeLatest(actions.REQ_ADD_TITLE, addTitleSaga);
}
function* watchDeleteCurrencySaga() {
  yield takeLatest(actions.REQ_DELETE_CURRENCY, deleteCurrencySaga);
}
function* watchDeleteTitleSaga() {
  yield takeLatest(actions.REQ_DELETE_TITLE, deleteTitleSaga);
}
function* watchGetTitlesSaga() {
  yield takeLatest(actions.REQ_GET_TITLES, getTitlesSaga);
}
function* watchGetCurrencySaga() {
  yield debounce(1000, actions.REQ_GET_CURRENCY, getCurrencySaga);
}
function* watchSetActiveEnvironmentSaga() {
  yield takeLatest(actions.SET_ACTIVE_ENVIRONMENT, setActiveEnvironmentSaga);
}
function* watchSetActiveTitleSaga() {
  yield takeLatest(actions.SET_ACTIVE_TITLE, setActiveTitleSaga);
}
function* watchUpdateEnvironmentSaga() {
  yield takeLatest(actions.REQ_UPDATE_ENVIRONMENT, updateEnvironmentSaga);
}
function* watchUpdateTitleSaga() {
  yield takeLatest(actions.REQ_UPDATE_TITLE, updateTitleSaga);
}

export default function* titlesSagas() {
  yield fork(watchAddCurrencySaga);
  yield fork(watchAddEnvironmentSaga);
  yield fork(watchAddTitleSaga);
  yield fork(watchDeleteCurrencySaga);
  yield fork(watchDeleteTitleSaga);
  yield fork(watchGetTitlesSaga);
  yield fork(watchGetCurrencySaga);
  yield fork(watchSetActiveEnvironmentSaga);
  yield fork(watchSetActiveTitleSaga);
  yield fork(watchUpdateEnvironmentSaga);
  yield fork(watchUpdateTitleSaga);
}
