import actions from './actions';

const initialState = {
  activePartner: {},
  activeAgreement: {},
  agreements: [],
  partners: [],
};

export default function Partners(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ACTIVE_AGREEMENT:
      const activeAgreement = state.agreements.find(
        (agreement) => agreement.id === action.agreementId
      );
      return {
        ...state,
        activeAgreement: activeAgreement || {},
      };

    case actions.SET_ACTIVE_PARTNER:
      const activePartner = state.partners.find(
        (partner) => partner.id === action.partnerId
      );
      return {
        ...state,
        activePartner: activePartner || {},
      };

    case actions.SET_AGREEMENTS:
      return {
        ...state,
        agreements: action.agreements,
      };

    case actions.SET_PARTNERS:
      return {
        ...state,
        partners: action.partners,
      };

    case actions.SET_UPDATED_AGREEMENT:
      return {
        ...state,
        agreements: state.agreements.map((agreement) =>
          agreement.id === action.agreement.id ? action.agreement : agreement
        ),
      };

    case actions.SET_UPDATED_PARTNER:
      return {
        ...state,
        partners: state.partners.map((partner) =>
          partner.id === action.partner.id ? action.partner : partner
        ),
      };

    default:
      return state;
  }
}
