import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { useRoleTarget } from '../../../hooks';
import Button from '../../../components/UI/Button';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import Table from '../../../components/Table';
import NewCurrency from './NewCurrency';
import { setShowModal } from '../../../redux-store/modules/settings';
import {
  reqAddCurrency,
  reqDeleteCurrency,
} from '../../../redux-store/modules/titles';

const useStyles = makeStyles((theme) => ({
  actions: {
    color: theme.table.actions.color,
    '& path': {
      cursor: 'pointer',
    },
  },
  container: {
    marginBottom: 30,
  },
}));

export default function Currencies() {
  const { Settings, Titles } = useSelector((state) => state);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showDialog, setShowDialog] = useState(false);
  const [currencyToDelete, setCurrencyToDelete] = useState<any>();
  const currenciesPermissions = useRoleTarget('currencies');

  const handleDeleteCurrency = (currency) => {
    dispatch(reqDeleteCurrency(currency.abbreviation));
    setShowDialog(false);
  };
  const handleSubmit = (newCurrency) => {
    dispatch(reqAddCurrency(newCurrency));
  };
  const handleModalClose = () => dispatch(setShowModal(false));
  const confirmDelete = (currency) => {
    setCurrencyToDelete(currency);
    setShowDialog(true);
  };

  const tableData = {
    name: { label: 'Name', width: 0.2 },
    abbreviation: { label: 'Abbreviation', width: 0.2 },
    baseCurrency: { label: 'Base Currency', width: 0.1 },
    baseExchangeRate: { label: 'Base Exchange Rate', width: 0.2 },
    fiat: { label: 'Is Fiat', width: 0.2 },
    actions: { label: 'Actions', width: 0.1 },
  };

  const renderCurrencyRow = (currencyRow) => {
    const currencyData = {
      table: {},
      raw: {},
    };
    Object.keys(currencyRow).forEach((key, i) => {
      currencyData.raw[key] = currencyRow[key];
      switch (key) {
        case 'baseCurrency':
          currencyData.table[key] = currencyRow[key] ? 'True' : 'False';
          break;
        case 'fiat':
          currencyData.table[key] = currencyRow[key] ? 'True' : 'False';
          break;
        default:
          currencyData.table[key] = currencyRow[key];
          break;
      }
    });
    currencyData.table['actions'] = (
      <DeleteIcon
        className={classes.actions}
        onClick={() => confirmDelete(currencyRow)}
      />
    );
    return currencyData;
  };

  const rowData = Titles.currencies
    ? Titles.currencies.map((currency) => renderCurrencyRow(currency))
    : [];
  const addNew = currenciesPermissions.CREATE ? (
    <Button type="contained" onClick={() => dispatch(setShowModal(true))}>
      + New Currency
    </Button>
  ) : null;

  return (
    <div className={classes.container}>
      <ConfirmationDialog
        show={showDialog}
        message={
          <span>
            Are you sure you want to delete{' '}
            <strong>{currencyToDelete && currencyToDelete.name}</strong>?
          </span>
        }
        title="Confirmation"
        onCancel={() => setShowDialog(false)}
        onConfirm={() => handleDeleteCurrency(currencyToDelete)}
      />
      <NewCurrency
        openModal={Settings.showModal}
        onModalClose={handleModalClose}
        onSubmit={handleSubmit}
      />
      <Table
        label={`Currencies (${rowData.length})`}
        downloadable={false}
        tableData={tableData}
        rowData={rowData}
        cta={addNew}
      />
    </div>
  );
}
