import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
// Icons
import { ReactComponent as CopyIcon } from '../assets/icons/CopyIcon.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  copyIcon: {
    fill: theme.text.secondary,
    fontSize: 16,
    marginLeft: 5,
    opacity: 0.5,
  },
  popover: {
    pointerEvents: 'none',
  },
  text: {
    cursor: 'pointer',
    fontSize: '0.8em',
    padding: 10,
  },
}));

const ClickToCopy = (props) => {
  const { children, className = '', value } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className={`${className} ${classes.root}`}
      onClick={handleClick}
      onMouseLeave={handleClose}
    >
      {children}
      <CopyIcon className={classes.copyIcon} />
      <Popover
        className={classes.popover}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Typography className={classes.text}>
          Text copied to clipboard
        </Typography>
      </Popover>
    </div>
  );
};

export default ClickToCopy;
