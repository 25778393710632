import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import ttiPolyfill from 'tti-polyfill';
import { connect } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';
import { MuiThemeProvider } from '@material-ui/core/styles';
import AppBar from './components/AppBar';
import Environment from './pages/Environment';
import Dashboard from './pages/Dashboard';
import TitlesPage from './pages/Titles';
import AccountPage from './pages/Account';
import ActivityPage from './pages/Activity';
import AdminPage from './pages/Admin';
import PartnerRoot from './pages/Partner';
import PartnerPage from './pages/Partner/partnerPage';
import PartnersPage from './pages/Partners';
import NewPartnerPage from './pages/Partner/newPartner';
import AgreementPage from './pages/Partner/agreement';
import NewAgreementPage from './pages/Partner/newAgreement';
import Error from './components/Error';
import SideBar from './components/SideBar';
import getTheme from './themes';
import { setShowError, setScreenDims } from './redux-store/modules/settings';
import './App.css';

ttiPolyfill.getFirstConsistentlyInteractive().then((tti) => {
  ReactGA.timing({
    category: 'Load Performace',
    variable: 'Time to Interactive',
    value: tti,
  });
});

const App = (props) => {
  const { Authentication, dispatch, history, Settings } = props;
  const { authenticated } = Authentication.keycloak;
  const { theme, errorMsg, showError } = Settings;

  const appTheme = getTheme(theme);

  // Set page title
  useEffect(() => {
    document.title = `IVI Portal${
      process.env.REACT_APP_ENV === 'dev' ? ' (dev)' : ''
    }`;
  }, []);

  // Modify body style
  useEffect(() => {
    document.body.style.backgroundColor = appTheme.body.backgroundColor;
    return () => (document.body.style.backgroundColor = null);
  }, [appTheme]);

  // Get screen and device info on first mount
  useEffect(() => {
    const handleResize = () => {
      dispatch(
        setScreenDims({ height: window.innerHeight, width: window.innerWidth })
      );
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [dispatch]);

  // Settup analytics
  useEffect(() => {
    const observer = new PerformanceObserver((list) => {
      list.getEntries().forEach((entry: any) => {
        ReactGA.timing({
          category: 'Load Performace',
          variable: 'Server Latency',
          value: entry.responseStart - entry.requestStart,
        });
        ReactGA.timing({
          category: 'Load Performace',
          variable: 'Download Time',
          value: entry.responseEnd - entry.responseStart,
        });
        ReactGA.timing({
          category: 'Load Performace',
          variable: 'Total App Load Time',
          value: entry.responseEnd - entry.requestStart,
        });
      });
    });
    observer.observe({ entryTypes: ['navigation'] });
    ReactGA.pageview(history.location.pathname);
  }, [history.location.pathname]);

  // Create location listener for analytics and update anytime path changes
  useEffect(() => {
    const unlisten = history.listen((location) => {
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    });
    return () => unlisten();
  }, [history]);

  return !authenticated ? (
    <div></div>
  ) : (
    <MuiThemeProvider theme={appTheme}>
      <div id="App">
        <Router history={history}>
          <AppBar />
          <SideBar />
          <Error
            open={showError}
            message={errorMsg}
            onClose={() => dispatch(setShowError(false))}
          />
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/account" component={AccountPage} />
            <Route exact path="/account/:tab" component={AccountPage} />
            <Route exact path="/admin" component={AdminPage} />
            <Route exact path="/partner/:partnerId" component={PartnerPage} />
            <Route
              exact
              path="/partner/:partnerId/:tab"
              component={PartnerPage}
            />
            <Route
              exact
              path="/partner/:partnerId/agreement/new"
              component={NewAgreementPage}
            />
            <Route
              exact
              path="/partner/:partnerId/agreement/:agreementId"
              component={AgreementPage}
            />
            <Route exact path="/partners" component={PartnersPage} />
            <Route exact path="/partners/new" component={NewPartnerPage} />
            <Route
              exact
              path="/partners/new-agreement"
              component={NewAgreementPage}
            />
            <Route exact path="/titles" component={TitlesPage} />
            <Route exact path="/dashboard/activity" component={ActivityPage} />
          </Switch>
          {/*The routes below are used for hydrating partner and agreements from a url refresh */}
          <Route path="/partner/:partnerId" component={PartnerRoot} />
          <Route
            path="/partner/:partnerId/agreement/:agreementId"
            component={PartnerRoot}
          />
          {/* The route below loads a template component for all routes that include an activeTitle and activeEnvironment */}
          <Route
            path="/titles/:activeTitleId/:activeEnvironmentId/"
            component={Environment}
          />
        </Router>
      </div>
    </MuiThemeProvider>
  );
};

const mapStateToProps = ({ Authentication, Settings }) => ({
  Authentication,
  Settings,
});

export default withRouter(connect(mapStateToProps)(App));
