import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '../../components/UI/Button';
import Table from '../../components/Table';
import ClickToCopy from '../../components/ClickToCopy';
import { formatISODate } from '../../utilities';
import {
  setActiveAgreement,
  setActivePartner,
} from '../../redux-store/modules/partners';
import { useRoleTarget } from '../../hooks';
import { IVITableRowData } from 'types';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.text.highlight,
    //fontWeight: 'bold',
    textDecoration: 'none',
  },
}));

export default function PartnerAgreements(props: any) {
  const { Partners, Titles } = useSelector((state) => state);
  const { activePartner, agreements } = Partners;
  const { titles } = Titles;
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();

  const partnersPermissions = useRoleTarget('partners');

  const numAgreements = agreements.filter(
    (agreement) => agreement.partnerId === activePartner.id
  ).length;

  const handleAgreementLink = (path, partnerId, agreementId) => {
    dispatch(setActiveAgreement(agreementId));
    dispatch(setActivePartner(partnerId));
    history.push(path);
  };

  const tableData = {
    name: { label: 'Name', width: 0.2, sortable: true },
    itemTypeNames: { label: 'Item Types', width: 0.25, sortable: true },
    id: { label: 'Agreement ID', width: 0.15, sortable: true },
    active: { label: 'Status', width: 0.05, sortable: true },
    titleId: { label: 'Title Name', width: 0.15, sortable: true },
    createdTimestamp: { label: 'Date Created', width: 0.2, sortable: true },
  };

  const renderRowData = (row) => {
    const rowData: IVITableRowData = {
      table: {},
      raw: {},
    };

    Object.keys(row).forEach((key, i) => {
      rowData.raw[key] = row[key];

      switch (key) {
        case 'name':
          rowData.table[key] = (
            <div
              className={classes.link}
              onClick={() =>
                handleAgreementLink(
                  `/partner/${row.partnerId}/agreement/${row.id}`,
                  row.partnerId,
                  row.id
                )
              }
            >
              {row[key]}
            </div>
          );
          break;
        case 'id':
          rowData.table[key] = (
            <ClickToCopy value={row[key]}>
              <Typography>{`${row[key]
                .toString()
                .substring(0, 7)}...`}</Typography>
            </ClickToCopy>
          );
          break;
        case 'itemTypeNames':
          rowData.table[key] = row[key].join(', ');
          rowData.raw[key] = row[key].join(', ');
          break;
        case 'active':
          rowData.raw[key] = row[key] === true ? 'true' : 'false';
          rowData.table[key] = row[key] === true ? 'Active' : 'Inactive';
          break;
        case 'createdTimestamp':
          rowData.table[key] = formatISODate(parseInt(row[key]));
          break;
        case 'titleId':
          rowData.table[key] = titles.find(
            (title) => title.id === row[key]
          ).name;
          break;
        default:
          rowData.table[key] = row[key];
          break;
      }
    });

    return rowData;
  };

  const rowData =
    numAgreements > 0
      ? agreements
          .filter((agreement) => agreement.partnerId === activePartner.id)
          .map((row) => renderRowData(row))
      : [];

  const cta = partnersPermissions.CREATE && (
    <Link
      className={classes.link}
      to={`/partner/${activePartner.id}/agreement/new`}
    >
      <Button type="contained">+ New Agreement</Button>
    </Link>
  );

  return (
    <Table
      label={`Agreements (${numAgreements})`}
      cta={cta}
      downloadable={false}
      expandable={true}
      rowData={rowData}
      tableData={tableData}
    />
  );
}
