import { responsiveFontSizes } from '@material-ui/core/styles';
import light from './light';
import dark from './dark';
import { Theme } from '@material-ui/core/styles/createMuiTheme';

const appThemes = {
  DARK: dark,
  LIGHT: light,
};

export default function getTheme(theme?): Theme {
  return responsiveFontSizes(theme ? appThemes[theme] : appThemes['DARK']);
}
