import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import UserAvatar from '../UserAvatar';
import HelpPopover from './HelpPopover';
import ProfilePopover from './ProfilePopover';
import { reqLogin, reqLogout } from '../../redux-store/modules/authentication';
import { setActivePage, setMenuOpen } from '../../redux-store/modules/settings';

const useStyles = makeStyles((theme) => ({
  appBarWrapper: {
    position: 'fixed',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  root: {
    backgroundColor: theme.appBar.backgroundColor,
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    height: theme.appBar.height,
    width: '100%',
    position: 'fixed',
    alignItems: 'center',
    justifyContent: 'flex-end',
    zIndex: theme.appBar.zIndex,
  },
  iconButton: {
    marginRight: 10,
    padding: 0,
    width: 36,
    height: 36,
  },
  help: {
    color: theme.sideBar.color,
    fontSize: '1em',
    cursor: 'pointer',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    '&:visited': {
      color: 'inherit',
    },
  },
  menuIcon: {
    color: theme.appBar.color,
    position: 'absolute',
    top: 5,
    left: 15,
  },
  popover: {
    color: theme.text.secondary,
    padding: '5px 15px',
    display: 'flex',
    flexDirection: 'column',
    '& a': {
      marginTop: 10,
      marginBottom: 10,
    },
    '& p': {
      color: theme.text.secondary,
    },
  },
  acctButton: {
    color: theme.appBar.color,
    display: 'flex',
    '&:hover': {
      background: 'none !important',
    },
  },
  profileWrapper: {
    backgroundColor: theme.appBar.backgroundColor,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    borderRadius: 5,
  },
  banner: {
    backgroundColor: '#747474',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    '& p': {
      fontSize: '1em',
    },
  },
}));

export default function MythicalAppBar() {
  const { Account, Authentication, Settings } = useSelector((state) => state);
  const { authenticated } = Authentication;
  const { profile } = Account;
  const { activePage, isMobile, orientation } = Settings;
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles({ activePage });
  const [HelpAnchor, setHelpAnchor] = useState(null);
  const [ProfileAnchor, setProfileAnchor] = useState(null);

  const handleAcctClick = (event) =>
    authenticated
      ? setProfileAnchor(event.currentTarget)
      : dispatch(reqLogin());
  const handleDrawerOpen = () => dispatch(setMenuOpen(true));
  const handleLogout = () => {
    dispatch(reqLogout());
  };
  const handleViewProfile = () => {
    dispatch(setActivePage('account'));
    history.push('/account/details');
  };

  const renderAuthed = () => (
    <div className={classes.profileWrapper}>
      <IconButton className={classes.iconButton} color="inherit">
        <UserAvatar />
      </IconButton>
      {profile && (
        <Typography>
          {`${profile?.given_name ? profile?.given_name : ''} ${
            profile?.family_name?.length ? profile.family_name[0] : ''
          }`}
        </Typography>
      )}
    </div>
  );

  const renderUnAuthed = () => (
    <>
      <IconButton className={classes.iconButton} color="inherit">
        <UserAvatar />
      </IconButton>
      <Typography>LOGIN</Typography>
    </>
  );

  return (
    <>
      <AppBar position="fixed" className={classes.root}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          className={classes.menuIcon}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          className={classes.help}
          onClick={(event) => setHelpAnchor(event.currentTarget)}
        >
          Help
        </Typography>
        <HelpPopover
          anchorEl={HelpAnchor}
          onClose={() => setHelpAnchor(null)}
        />
        {profile && (
          <ProfilePopover
            anchorEl={ProfileAnchor}
            profile={profile}
            onClose={() => setProfileAnchor(null)}
            onLogout={handleLogout}
            onViewProfile={handleViewProfile}
          />
        )}
        <MenuItem className={classes.acctButton} onClick={handleAcctClick}>
          {authenticated ? renderAuthed() : renderUnAuthed()}
        </MenuItem>
      </AppBar>
      {isMobile && orientation ? (
        <div className={classes.banner}>
          <Typography>Site optimized for landscape view</Typography>
        </div>
      ) : null}
    </>
  );
}
