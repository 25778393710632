import React, { Fragment } from 'react';
import Button from './UI/Button';
import Modal from './Modal';
import Typography from '@material-ui/core/Typography';

const ConfirmationDialog = (props) => {
  const { message, title, onCancel, onConfirm, show } = props;

  return (
    <Modal
      open={show}
      title={title}
      onClose={onCancel}
      actions={
        <Fragment>
          <Button type="contained" onClick={onConfirm}>
            Yes
          </Button>
          <Button onClick={onCancel}>No</Button>
        </Fragment>
      }
    >
      <Typography>{message}</Typography>
    </Modal>
  );
};

export default ConfirmationDialog;
