import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.modal.backgroundColor,
    borderRadius: 5,
    color: theme.text.primary,
    padding: 10,
  },
  closeButton: {
    display: 'flex',
    color: theme.palette.grey[500],
  },
  dialogTitle: {
    display: 'flex',
    fontFamily: 'Poppins',
    fontSize: '2em',
  },
  dialogTitleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

const Modal = (props) => {
  const {
    actions,
    children,
    className,
    fullWidth,
    open,
    onClose,
    title,
  } = props;

  const classes = useStyles();

  const dialogTitle = (
    <div className={classes.dialogTitleWrapper}>
      <DialogTitle
        disableTypography
        className={classes.dialogTitle}
      >
        {title && title}
      </DialogTitle>
      {onClose && (
        <IconButton className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      )}
    </div>
  );

  return (
    <Dialog
      classes={{ paper: classes.root }}
      fullWidth={fullWidth}
      onClose={onClose}
      open={open}
    >
      {dialogTitle}
      <DialogContent className={className}>{children}</DialogContent>
      {actions ? <DialogActions>{actions}</DialogActions> : null}
    </Dialog>
  );
};

export default Modal;
