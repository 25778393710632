import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useRoleTarget } from '../../hooks';
import Page from '../../components/Page';
import Tabs from '../../components/Tabs';
import ClickToCopy from '../../components/ClickToCopy';
import Typography from '@material-ui/core/Typography';
import Currencies from './Currencies';
import Items from './Items';
import Players from './Players';
import Transactions from './Transactions';
import Webhooks from './Webhooks';
import {
  setActiveEnvironment,
  setActiveTitle,
} from '../../redux-store/modules/titles';
import { setActivePage } from '../../redux-store/modules/settings';

const useStyles = makeStyles((theme) => ({
  configuration: {
    width: '100%',
  },
  titleWrapper: {
    color: theme.text.primary,
    display: 'flex',
    flexDirection: 'column',
  },
  envId: {
    display: 'flex',
    fontSize: '0.6em',
  },
}));

const Configuration = (props) => {
  const { numCurrencies, numWebhooks } = props;
  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const currenciesPermissions = useRoleTarget('currencies');
  const webhooksPermissions = useRoleTarget('webhooks');

  let tabContent = [];
  currenciesPermissions.READ &&
    tabContent.push({
      label: `Currencies (${numCurrencies})`,
      component: <Currencies />,
      link: <Typography>{`Currencies (${numCurrencies})`}</Typography>,
    });
  webhooksPermissions.READ &&
    tabContent.push({
      label: `Webhooks (${numWebhooks})`,
      component: <Webhooks />,
      link: <Typography>{`Webhooks (${numWebhooks})`}</Typography>,
    });

  const handleTabChange = (event, tabIndex) => setActiveTabIndex(tabIndex);

  return (
    <div className={classes.configuration}>
      <Tabs
        value={activeTabIndex}
        onChange={handleTabChange}
        tabContent={tabContent}
        tabType="secondary"
      />
      {tabContent.length ? tabContent[activeTabIndex].component : null}
    </div>
  );
};

export default function Environments() {
  const location = useLocation();
  const { pathname } = location;
  const { Settings, Titles, Webhooks } = useSelector((state) => state);
  const { activeEnvironment, activeTitle, currencies = [] } = Titles;
  const { webhooks = [] } = Webhooks;
  const { activePage } = Settings;

  const { activeTitleId, activeEnvironmentId = '' } = useParams();

  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const itemsPermissions = useRoleTarget('items');
  const playersPermissions = useRoleTarget('players');
  const transactionsPermissions = useRoleTarget('transactions');
  const currenciesPermissions = useRoleTarget('currencies');
  const webhooksPermissions = useRoleTarget('webhooks');

  let tabContent = [];
  itemsPermissions.READ &&
    tabContent.push({
      component: <Items />,
      label: 'items',
      link: <Typography>Items</Typography>,
    });
  playersPermissions.READ &&
    tabContent.push({
      component: <Players />,
      label: 'players',
      link: <Typography>Players</Typography>,
    });
  transactionsPermissions.READ &&
    tabContent.push({
      component: <Transactions />,
      label: 'transactions',
      link: <Typography>Transactions</Typography>,
    });
  if (currenciesPermissions.READ || webhooksPermissions.READ) {
    tabContent.push({
      label: 'configuration',
      component: (
        <Configuration
          numCurrencies={currencies.length || 0}
          numWebhooks={webhooks.length || 0}
        />
      ),
      link: <Typography>Configuration</Typography>,
    });
  }

  const activeEnvironmentName =
    activeTitle &&
    activeEnvironment &&
    activeEnvironment.id &&
    activeTitle.environments &&
    activeTitle.environments.find(
      (environment) => environment.id === activeEnvironment.id
    ) &&
    activeTitle.environments.find(
      (environment) => environment.id === activeEnvironment.id
    ).displayName;

  const handleTabChange = (event, tabIndex) => setActiveTabIndex(tabIndex);

  // This hook checks if a titleId and environmentId are present in the URL and dispatches the action to set them
  useEffect(() => {
    if (activeTitleId) {
      // If no activeTitle is already set
      !activeTitle && dispatch(setActiveTitle({ id: activeTitleId }));
      // If activeTtile is already set then find and pass full title object
      activeTitle &&
        Titles.titles.length &&
        dispatch(
          setActiveTitle(
            Titles.titles.find((title) => title.id === activeTitleId)
          )
        );
    }
    if (activeEnvironmentId) {
      // If no activeEnvironment is already set
      !activeEnvironment &&
        dispatch(setActiveEnvironment({ id: activeEnvironmentId }));
      !activePage && dispatch(setActivePage(activeEnvironmentId));
      // If activeEnvironment is already set then find and pass full environment object
      activeEnvironment &&
        Titles.titles.length &&
        dispatch(
          setActiveEnvironment(
            Titles.titles
              .find((title) => title.id === activeTitleId)
              .environments.find(
                (environment) => environment.id === activeEnvironmentId
              )
          )
        );
    }
  }, [
    activeTitleId,
    activeEnvironmentId,
    activeEnvironment,
    activePage,
    Titles.titles,
  ]);

  const title =
    (activeTitle && activeTitle.name && (
      <div className={classes.titleWrapper}>
        <div>{`${activeTitle.name} - ${activeEnvironmentName}`}</div>
        <ClickToCopy value={activeEnvironmentId}>
          <Typography className={classes.envId}>
            {activeEnvironmentId}
          </Typography>
        </ClickToCopy>
      </div>
    )) ||
    '';

  return (
    <Page title={title}>
      <Tabs
        value={activeTabIndex}
        onChange={handleTabChange}
        tabContent={tabContent}
      />
      {tabContent.length ? tabContent[activeTabIndex].component : null}
      <Switch>
        <Route exact path={`${pathname}/items`} component={Items} />
        <Route exact path={`${pathname}/players`} component={Players} />
        <Route
          exact
          path={`${pathname}/transactions`}
          component={Transactions}
        />
      </Switch>
    </Page>
  );
}
