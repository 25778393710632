import { IVITableRowData } from 'types';
import React from 'react';
import { useSelector } from 'react-redux';
import Table from '../../../components/Table';

export default function MyOrgs() {
  const { Authentication, Orgs } = useSelector((state) => state);
  const { realmAccess } = Authentication.keycloak;
  const { orgs } = Orgs;

  const tableData = {
    displayName: { label: 'Name', width: 0.3, sortable: false },
    role: { label: 'My Role', width: 0.2, sortable: false },
    id: { label: 'ID', width: 0.5, sortable: false },
  };

  const renderRowData = (row) => {
    const rowData: IVITableRowData = {
      table: {},
      raw: {},
    };
    Object.keys(row).forEach((key, i) => {
      rowData.raw[key] = row[key];

      switch (key) {
        case 'roles':
          let role = row.roles.find((role) =>
            realmAccess.roles.indexOf(role.roleName) >= 0 ? role : null
          ).displayName;
          rowData.table.role = role;
          break;
        default:
          rowData.table[key] = row[key];
          break;
      }
    });
    return rowData;
  };

  const rowData = orgs.length ? orgs.map((org) => renderRowData(org)) : [];

  return (
    <Table
      label={`My Organizations (${orgs.length})`}
      downloadable={false}
      expandable={false}
      rowData={rowData}
      tableData={tableData}
    />
  );
}
