import { fork, put, select, takeLatest } from 'redux-saga/effects';
import actions, { setWebhooks } from './actions';
import {
  addWebhook,
  deleteWebhook,
  getWebhooks,
  updateWebhook,
} from '../../../services/ivi-engine';
import { mythicalFetcher } from '../../utilities';

function* addWebhookSaga(action) {
  try {
    const response = yield mythicalFetcher(addWebhook, [action.webhook]);
    if (response.webhookId) {
      const state = yield select();
      const newWebhooks = Object.assign([], state.Webhooks.webhooks);
      newWebhooks.push({ ...action.webhook, id: response.webhookId });
      yield put(setWebhooks(newWebhooks));
    }
  } catch (e) {}
}
function* getWebhooksSaga() {
  try {
    const webhooks = yield mythicalFetcher(getWebhooks);
    yield put(setWebhooks(webhooks));
  } catch (e) {}
}
function* deleteWebhookSaga(action) {
  try {
    const response = yield mythicalFetcher(deleteWebhook, [action.webhookId]);
    if (response.ok) {
      const state = yield select();
      const newWebhooks = state.Webhooks.webhooks.filter(
        (webhook) => webhook.id !== action.webhookId
      );
      yield put(setWebhooks(newWebhooks));
    }
  } catch (e) {}
}
function* updateWebhookSaga(action) {
  try {
    const response = yield mythicalFetcher(updateWebhook, [action.webhook]);
    if (response.ok) {
      const state = yield select();
      const newWebhooks = state.Webhooks.webhooks.map((webhook) =>
        webhook.id === action.webhook.id ? { ...action.webhook } : webhook
      );
      yield put(setWebhooks(newWebhooks));
    }
  } catch (e) {}
}
function* watchAddWebhookSaga() {
  yield takeLatest(actions.REQ_ADD_WEBHOOK, addWebhookSaga);
}
function* watchGetWebhooksSaga() {
  yield takeLatest(actions.REQ_GET_WEBHOOKS, getWebhooksSaga);
}
function* watchDeleteWebhookSaga() {
  yield takeLatest(actions.REQ_DELETE_WEBHOOK, deleteWebhookSaga);
}
function* watchUpdateWebhookSaga() {
  yield takeLatest(actions.REQ_UPDATE_WEBHOOK, updateWebhookSaga);
}
export default function* webhooksSagas() {
  yield fork(watchAddWebhookSaga);
  yield fork(watchGetWebhooksSaga);
  yield fork(watchDeleteWebhookSaga);
  yield fork(watchUpdateWebhookSaga);
}
