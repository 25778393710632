import actions from './actions';

const initialState = {
  players: [],
};

export default function Players(state = initialState, action) {
  switch (action.type) {
    case actions.SET_PLAYERS:
      return {
        ...state,
        players: action.players,
      };

    default:
      return state;
  }
}
