import React from 'react';
import { useDispatch } from 'react-redux';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '../../../components/Table';
import { formatISODate } from '../../../utilities';
import { reqGetItemToken } from '../../../redux-store/modules/items';
import { IVITableRowData } from 'types';

export default function TypeTab(props) {
  const { types = [] } = props;
  const dispatch = useDispatch();

  const tableData = {
    tokenName: { label: 'Name', width: 0.2, sortable: true },
    category: { label: 'Category', width: 0.2, sortable: true },
    maxSupply: { label: 'Max Supply', width: 0.1, sortable: true },
    issueTimeSpan: { label: 'Max Issue Date', width: 0.2, sortable: true },
    burnable: { label: 'Burnable', width: 0.1, sortable: false },
    transferable: { label: 'Transferable', width: 0.1, sortable: false },
    sellable: { label: 'Sellable', width: 0.1, sortable: false },
  };

  const handleExpand = (item) => {
    const { category, tokenName } = item;
    dispatch(reqGetItemToken(category, tokenName));
  };

  const renderRowData = (row) => {
    const rowData: IVITableRowData = {
      table: {},
      raw: {},
    };
    Object.keys(row).forEach((key, i) => {
      rowData.raw[key] = row[key];
      rowData.table[key] = row[key];
      switch (key) {
        case 'issueTimeSpan':
          rowData.table[key] =
            parseInt(row[key]) === 0
              ? ''
              : formatISODate(
                  parseInt(row.createdTimestamp) +
                    parseInt(row.issueTimeSpan) * 24 * 60 * 60
                );
          break;

        case 'createdTimestamp':
          rowData.raw[key] = formatISODate(row.createdTimestamp);
          break;
        case 'maxSupply':
          rowData.table[key] = parseInt(row[key]) === 0 ? '' : row[key];
          break;
        default:
          break;
      }
      switch (typeof row[key]) {
        case 'boolean':
          rowData.table[key] = (
            <Checkbox disabled name={key} checked={row[key]} />
          );
          rowData.raw[key] = row[key] === true ? 'true' : 'false';
          break;
        default:
          break;
      }
    });
    return rowData;
  };

  const rowData = types.length ? types.map((row) => renderRowData(row)) : [];

  return (
    <Table
      label={`Item Types (${types.length})`}
      downloadable={true}
      expandable={true}
      onExpand={handleExpand}
      tableData={tableData}
      rowData={rowData}
    />
  );
}
