import React, { useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useRoleTarget } from '../../hooks';
import Page from '../../components/Page';
import Tabs from '../../components/Tabs';
import ClickToCopy from '../../components/ClickToCopy';
import Typography from '@material-ui/core/Typography';
import Users from './Users';
import Settings from './settings';
import Secrets from './Secrets';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  clickToCopy: {
    marginTop: 20,
  },
  orgId: {
    color: theme.text.primary,
    fontSize: '0.6em',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function Admin() {
  const { Orgs } = useSelector((state) => state);
  const { activeOrg = {} } = Orgs;
  const secretsPermissions = useRoleTarget('secrets');
  const invitesPermissions = useRoleTarget('invites');
  const orgPermissions = useRoleTarget('organizations');
  const history = useHistory();
  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleTabChange = (event, tabIndex) => setActiveTabIndex(tabIndex);

  let tabContent = [];
  invitesPermissions.READ &&
    tabContent.push({
      component: <Users />,
      label: 'users',
      link: <Typography>Users</Typography>,
      route: (
        <Route
          exact
          key="users"
          path={`${history.location.pathname}/users`}
          component={Users}
        />
      ),
    });
  secretsPermissions.READ &&
    tabContent.push({
      label: 'Secrets',
      component: <Secrets />,
      link: <Typography>Secrets</Typography>,
      route: (
        <Route
          exact
          key="secrets"
          path={`${history.location.pathname}/secrets`}
          component={Secrets}
        />
      ),
    });
  orgPermissions.READ &&
    tabContent.push({
      component: <Settings />,
      label: 'settings',
      link: <Typography>Settings</Typography>,
      route: (
        <Route
          exact
          key="settings"
          path={`${history.location.pathname}/settings`}
          component={Settings}
        />
      ),
    });

  const title = activeOrg ? (
    <div className={classes.titleWrapper}>
      <div>{`${activeOrg.displayName} - Admin`}</div>
      <ClickToCopy className={classes.clickToCopy} value={activeOrg.id}>
        <Typography className={classes.orgId}>{activeOrg.id}</Typography>
      </ClickToCopy>
    </div>
  ) : null;

  return (
    <Page title={title}>
      {activeOrg && tabContent.length ? (
        <>
          <Tabs
            value={activeTabIndex}
            onChange={handleTabChange}
            tabContent={tabContent}
          />
          <div className={classes.container}>
            {tabContent[activeTabIndex].component}
          </div>
        </>
      ) : null}
      <Switch>{tabContent.length && tabContent.map((tab) => tab.route)}</Switch>
    </Page>
  );
}
