import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { TextField, Typography } from '@material-ui/core';
import Button from '../../../components/UI/Button';
import Select from '../../../components/UI/Select';
import Modal from '../../../components/Modal';
import { isValidEmail } from '../../../utilities';
import copy from '../../../copy-text';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 500,
  },
  addInvite: {
    color: theme.text.highlight,
    cursor: 'pointer',
    fontSize: '0.9em',
    marginTop: 30,
    maxWidth: 150,
  },
  text: {
    color: theme.text.primary,
    fontSize: '0.9em',
  },
  textFieldHelperText: {
    fontSize: '0.9em',
    position: 'absolute',
    bottom: -30,
  },
  input: {
    display: 'flex',
    flexGrow: 1,
    marginRight: 30,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    height: 70,
  },
  select: {
    position: 'relative',
    width: 275,
  },
  selectInput: {
    paddingBottom: 20,
    '&:focus': {
      background: 'none',
    },
  },
}));

const InviteUser = (props) => {
  const { onModalClose, onSubmit, openModal, roles } = props;
  const classes = useStyles();

  const errorMsgs = {
    email: copy.pages.admin.users.inviteModal.email.errorText,
    role: copy.pages.admin.users.inviteModal.role.errorText,
  };
  const newInvite = {
    email: {
      value: '',
      error: false,
    },
    role: {
      value: '',
      error: false,
    },
  };

  const [invites, setInvites] = useState([newInvite]);

  const handleAddInvite = () => {
    const [...newInvites] = invites;
    newInvites.push(newInvite);
    setInvites(newInvites);
  };
  const handleChange = (key, value, i) => {
    let [...newInvites] = invites;
    newInvites[i] = { ...invites[i], [key]: { ...invites[i][key], value } };
    setInvites(newInvites);
  };
  const handleClose = () => {
    setInvites([newInvite]);
    onModalClose();
  };
  const validate = () => {
    let [...newInvites] = invites;
    const invitesWithErrors = invites.filter(
      (invite) => !isValidEmail(invite.email.value) || !invite.role.value.length
    );

    if (!invitesWithErrors.length) {
      handleSubmit(newInvites);
    } else {
      invites.forEach((invite, i) => {
        newInvites[i].email.error = !isValidEmail(invite.email.value);
        newInvites[i].role.error = invite.role.value.length < 1;
      });
      setInvites(newInvites);
    }
  };
  const handleSubmit = (invites) => {
    let finalInvites = invites.map((invite) => ({
      userEmail: invite.email.value,
      roleId: invite.role.value,
    }));
    onSubmit(finalInvites);
    handleClose();
  };

  return (
    <Modal
      open={openModal}
      title={copy.pages.admin.users.inviteModal.title}
      onClose={handleClose}
      actions={
        <>
          <Button type="contained" onClick={() => validate()}>{`Send Invite${
            invites.length > 1 ? 's' : ''
          }`}</Button>
          <Button onClick={() => handleClose()} fill={false}>
            Cancel
          </Button>
        </>
      }
    >
      <Typography className={classes.text}>
        {copy.pages.admin.users.inviteModal.text}
      </Typography>
      {invites.map((invite, i) => (
        <div key={i} className={classes.row}>
          <TextField
            name="email"
            label={copy.pages.admin.users.inviteModal.email.label}
            className={classes.input}
            error={invites[i].email.error}
            helperText={invites[i].email.error ? errorMsgs.email : ''}
            FormHelperTextProps={{
              classes: { root: classes.textFieldHelperText },
            }}
            inputProps={{ maxLength: 30 }}
            onChange={(event) =>
              handleChange(event.target.name, event.target.value, i)
            }
            value={invite.email.value || ''}
          />
          <Select
            variant="filled"
            disableUnderline
            className={classes.select}
            value={invite.role.value}
            label={copy.pages.admin.users.inviteModal.role.label}
            onChange={(event) =>
              handleChange(event.target.name, event.target.value, i)
            }
            inputProps={{ id: 'role-helper', name: 'role' }}
            options={roles.map((role) => ({
              label: role.displayName,
              value: role.id,
            }))}
            errorText={errorMsgs.role}
            error={invites[i].role.error}
          />
        </div>
      ))}
      <Typography
        className={classes.addInvite}
        onClick={() => handleAddInvite()}
      >
        {copy.pages.admin.users.inviteModal.cta}
      </Typography>
    </Modal>
  );
};

export default InviteUser;
