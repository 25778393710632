import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
  createAgreement,
  createPartner,
  getAgreements,
  getPartners,
  updateAgreement,
  updatePartner,
} from '../../../services/ivi-engine';
import actions, {
  setAgreements,
  setPartners,
  setUpdatedAgreement,
  setUpdatedPartner,
} from './actions';
import { mythicalFetcher } from '../../utilities';

function* addAgreementSaga(action) {
  try {
    const response = yield mythicalFetcher(createAgreement, [action.agreement]);
    if (response.hasOwnProperty('createdTimestamp')) {
      const state = yield select();
      yield put(setAgreements([...state.Partners.agreements, response]));
    }
  } catch (e) {}
}
function* addPartnerSaga(action) {
  try {
    const response = yield mythicalFetcher(createPartner, [action.partner]);
    if (response.hasOwnProperty('id')) {
      const state = yield select();
      yield put(setPartners([...state.Partners.partners, response]));
    }
  } catch (e) {}
}
function* addPartnerAndAgreementSaga(action) {
  try {
    const partner = yield mythicalFetcher(createPartner, [action.partner]);
    yield console.log('partner', partner);
    if (partner.hasOwnProperty('id')) {
      const state = yield select();
      yield put(setPartners([...state.Partners.partners, partner]));
      // Redirect to new agreement form
      yield put(push(`/partner/${partner.id}/agreement/new`));
    }
  } catch (e) {}
}
function* getAgreementsSaga(partnerId) {
  try {
    const agreements = yield mythicalFetcher(getAgreements, [partnerId]);
    return agreements;
  } catch (e) {}
}
function* getPartnersAndAgreementsSaga(action) {
  try {
    const partners = yield mythicalFetcher(getPartners, [
      action.organizationId,
    ]);
    yield put(setPartners(partners));

    const partnerAgreements = yield all(
      partners.map((partner) => call(getAgreementsSaga, partner.id))
    );
    const agreements = partnerAgreements.reduce(
      (acc, partnerAgreements) => [...acc, ...partnerAgreements],
      []
    );
    yield put(setAgreements(agreements));
  } catch (e) {}
}
function* updateAgreementSaga(action) {
  try {
    const updatedAgreement = yield mythicalFetcher(updateAgreement, [
      action.agreement,
    ]);
    yield put(setUpdatedAgreement(updatedAgreement));
  } catch (e) {}
}
function* updatePartnerSaga(action) {
  try {
    const updatedPartner = yield mythicalFetcher(updatePartner, [
      action.partner,
    ]);
    yield put(setUpdatedPartner(updatedPartner));
  } catch (e) {}
}
function* watchAddAgreementSaga() {
  yield takeLatest(actions.REQ_ADD_AGREEMENT, addAgreementSaga);
}
function* watchAddPartnerSaga() {
  yield takeLatest(actions.REQ_ADD_PARTNER, addPartnerSaga);
}
function* watchAddPartnerAndAgreementSaga() {
  yield takeLatest(
    actions.REQ_ADD_PARTNER_AND_AGREEMENT,
    addPartnerAndAgreementSaga
  );
}
function* watchGetPartnersAndAgreementsSaga() {
  yield takeLatest(
    actions.REQ_GET_PARTNERS_AND_AGREEMENTS,
    getPartnersAndAgreementsSaga
  );
}
function* watchReqUpdateAgreementSaga() {
  yield takeLatest(actions.REQ_UPDATE_AGREEMENT, updateAgreementSaga);
}
function* watchReqUpdatePartnerSaga() {
  yield takeLatest(actions.REQ_UPDATE_PARTNER, updatePartnerSaga);
}
export default function* partnersSagas() {
  yield fork(watchAddAgreementSaga);
  yield fork(watchAddPartnerSaga);
  yield fork(watchAddPartnerAndAgreementSaga);
  yield fork(watchGetPartnersAndAgreementsSaga);
  yield fork(watchReqUpdateAgreementSaga);
  yield fork(watchReqUpdatePartnerSaga);
}
