import React, { useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  TextField,
  Typography,
} from '@material-ui/core';
import Button from '../../components/UI/Button';
import { useRoleTarget } from '../../hooks';
import { reqUpdateOrg } from '../../redux-store/modules/orgs';
import { areEqual } from '../../utilities';
import copy from '../../copy-text';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 20,
  },
  button: {
    marginTop: 30,
    fontSize: '0.9em',
    fontWeight: 'bold',
  },
  buttons: ({ isMobile }: any) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isMobile ? 'flex-end' : 'flex-start',
    marginTop: 20,
  }),
  form: {
    minWidth: 300,
    //width: 300
  },
  envTypes: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    '& label': {
      marginLeft: 0,
    },
    '& input': {
      textAlign: 'center',
      width: 50,
    },
  },
}));

const Settings = (props) => {
  const { dispatch, Settings, Orgs } = props;
  const { isMobile } = Settings;
  const { activeOrg } = Orgs;
  const { displayName } = activeOrg;
  const environmentLimits = {
    DEV: 1,
    QA: 1,
    STAGING: 1,
    PROD: 1,
    ...activeOrg.environmentLimits,
  };

  const classes = useStyles({ isMobile });
  const [canEdit, setCanEdit] = useState(false);
  const noErrors = {
    displayName: {
      error: false,
      msg: copy.pages.admin.settings.name.errorText,
    },
  };
  const [formState, setFormState] = useState(noErrors);
  const [orgData, setOrgData] = useState(Object.assign({}, activeOrg));
  const [newEnvLimits, setNewEnvLimits] = useState(environmentLimits);
  const orgPermissions = useRoleTarget('organizations');

  const validateOrg = () => {
    if (orgData.displayName.length) {
      setFormState(noErrors);
      setCanEdit(false);
      dispatch(reqUpdateOrg({ ...orgData, environmentLimits: newEnvLimits }));
    } else {
      setFormState({
        ...formState,
        displayName: {
          ...formState.displayName,
          error: !orgData.displayName.length,
        },
      });
    }
  };
  const handleChange = (event) =>
    setOrgData({ ...orgData, [event.target.name]: event.target.value });
  const handleChangeEnvLimits = (event) =>
    setNewEnvLimits({
      ...newEnvLimits,
      [event.target.name]: parseInt(event.target.value),
    });
  const handleResetOrg = () => {
    setOrgData({ ...activeOrg });
    setNewEnvLimits(environmentLimits);
    setFormState(noErrors);
    setCanEdit(false);
  };
  const hasChanges = () =>
    orgData.displayName !== displayName ||
    !areEqual(newEnvLimits, environmentLimits);

  const editButton = orgPermissions.UPDATE ? (
    <div className={classes.buttons}>
      <Button className={classes.button} onClick={() => setCanEdit(true)}>
        Edit
      </Button>
    </div>
  ) : null;

  return (
    <Card className={classes.card}>
      <Typography variant="h6">{copy.pages.admin.settings.title}</Typography>
      <div className={classes.form}>
        <TextField
          name="displayName"
          label={copy.pages.admin.settings.name.label}
          margin="normal"
          type="text"
          disabled={!canEdit}
          inputProps={{ maxLength: 20 }}
          error={formState.displayName.error}
          helperText={
            formState.displayName.error ? formState.displayName.msg : ''
          }
          onChange={handleChange}
          value={orgData.displayName || ''}
        />
        <FormControl component="fieldset" className={classes.envTypes}>
          <FormLabel>{copy.pages.admin.settings.envTypes.label}</FormLabel>
          <FormHelperText>
            {copy.pages.admin.settings.envTypes.helperText}
          </FormHelperText>
          <FormGroup>
            <FormControlLabel
              value={newEnvLimits.DEV}
              label="DEV"
              name="DEV"
              control={
                <TextField
                  disabled={!canEdit}
                  type="number"
                  inputProps={{ min: 1, max: 5 }}
                  onChange={handleChangeEnvLimits}
                />
              }
            />
            <FormControlLabel
              value={newEnvLimits.QA}
              label="QA"
              name="QA"
              control={
                <TextField
                  disabled={!canEdit}
                  type="number"
                  inputProps={{ min: 1, max: 5 }}
                  onChange={handleChangeEnvLimits}
                />
              }
            />
            <FormControlLabel
              value={newEnvLimits.STAGING}
              label="STAGING"
              name="STAGING"
              control={
                <TextField
                  disabled={!canEdit}
                  type="number"
                  inputProps={{ min: 1, max: 5 }}
                  onChange={handleChangeEnvLimits}
                />
              }
            />
            <FormControlLabel
              value={newEnvLimits.PROD}
              label="PROD"
              name="PROD"
              control={
                <TextField
                  disabled={!canEdit}
                  type="number"
                  inputProps={{ min: 1, max: 5 }}
                  onChange={handleChangeEnvLimits}
                />
              }
            />
          </FormGroup>
        </FormControl>
        {canEdit ? (
          <div className={classes.buttons}>
            {hasChanges() ? (
              <Button
                className={classes.button}
                type="contained"
                onClick={() => validateOrg()}
              >
                Save
              </Button>
            ) : null}
            <Button className={classes.button} onClick={() => handleResetOrg()}>
              Cancel
            </Button>
          </div>
        ) : (
          editButton
        )}
      </div>
    </Card>
  );
};

const mapStateToProps = ({ dispatch, Orgs, Settings }) => ({
  dispatch,
  Orgs,
  Settings,
});

export default connect(mapStateToProps)(Settings);
