import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import Keycloak from 'keycloak-js';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import { persistor, store, history } from './redux-store';
import config from './config';
import { setKeycloak } from './redux-store/modules/authentication';
import ErrorBoundary from './components/ErrorBoundary';

const { authUrl, clientId, realm } = config.apis.mythical.keycloak;

let keycloak = Keycloak({ clientId, realm, url: authUrl });

keycloak.onTokenExpired = () =>
  keycloak
    .updateToken(30)
    .success(() => {
      console.log('keycloak token updated');
      store.dispatch(setKeycloak(keycloak));
    })
    .error(() => {});

keycloak
  .init({ onLoad: 'login-required', checkLoginIframe: false })
  .success((auth) => {
    if (!auth) {
      console.log('keycloak auth:' + auth);
      window.location.reload();
    } else {
      store.dispatch(setKeycloak(keycloak));
      ReactDOM.render(
        <ErrorBoundary>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ConnectedRouter history={history}>
                <App />
              </ConnectedRouter>
            </PersistGate>
          </Provider>
        </ErrorBoundary>,
        document.getElementById('root')
      );
    }
  })
  .error(() => console.log('keycloak error'));

ReactGA.initialize(config.apis.google.analytics.trackingId);
