import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '../../components/UI/Button';
import Table from '../../components/Table';
import ClickToCopy from '../../components/ClickToCopy';
import { formatISODate } from '../../utilities';
import { useRoleTarget } from '../../hooks';
import { setActivePartner } from '../../redux-store/modules/partners';
import { IVITableRowData } from 'types';

const useStyles = makeStyles((theme) => ({
  agreementQty: {
    display: 'flex',
    justifyContent: 'left',
  },
  link: {
    color: theme.text.highlight,
    cursor: 'pointer',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
}));

export default function PartnersTable(props) {
  const { agreements, partners } = props;

  const partnersPermissions = useRoleTarget('partners');
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();

  const handleLink = (path, partnerId) => {
    dispatch(setActivePartner(partnerId));
    history.push(path);
  };

  const tableData = {
    company: { label: 'Company Name', width: 0.25, sortable: true },
    id: { label: 'Partner ID', width: 0.1, sortable: true },
    lastName: { label: 'Last Name', width: 0.1, sortable: true },
    firstName: { label: 'First Name', width: 0.1, sortable: true },
    //createdTimestamp: { label: 'Date Created', width: 0.2, sortable: true },
    email: { label: 'Email', width: 0.2, sortable: false },
    numAgreements: { label: 'Agreements', width: 0.05, sortable: false },
    managerName: { label: 'Account Manager', width: 0.2, sortable: false },
  };

  const renderRowData = (partner) => {
    const rowData: IVITableRowData = {
      table: {
        // Add new key for numAgreements
        numAgreements: (
          <div
            className={classes.link}
            onClick={() =>
              handleLink(`/partner/${partner.id}/agreements`, partner.id)
            }
          >
            <div className={classes.agreementQty}>
              {
                agreements.filter(
                  (agreement) => agreement.partnerId === partner.id
                ).length
              }
            </div>
          </div>
        ),
      },
      raw: {},
    };

    Object.keys(partner).forEach((key, i) => {
      rowData.raw[key] = partner[key];

      switch (key) {
        case 'id':
          rowData.table[key] = (
            <ClickToCopy value={partner[key]}>
              <Typography>{`${partner[key]
                .toString()
                .substring(0, 7)}...`}</Typography>
            </ClickToCopy>
          );
          break;
        case 'company':
          rowData.table[key] = (
            <div
              className={classes.link}
              onClick={() => handleLink(`/partner/${partner.id}`, partner.id)}
            >
              {partner[key]}
            </div>
          );
          break;
        case 'createdTimestamp':
          rowData.table[key] = formatISODate(parseInt(partner[key]));
          break;
        default:
          rowData.table[key] = partner[key];
          break;
      }
    });

    return rowData;
  };

  const cta = partnersPermissions.CREATE && (
    <Link className={classes.link} to={`/partners/new`}>
      <Button type="contained">+ New Partner</Button>
    </Link>
  );

  return (
    <Table
      label={`Partners (${partners.length})`}
      cta={cta}
      downloadable={false}
      expandable={false}
      rowData={
        partners.length ? partners.map((partner) => renderRowData(partner)) : []
      }
      tableData={tableData}
    />
  );
}
