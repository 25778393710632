import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Page from '../../components/Page';
import AgreementForm from './agreementForm';

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function NewAgreement() {
  const { Partners } = useSelector((state) => state);
  const { partners = [] } = Partners;
  const { partnerId } = useParams();
  const classes = useStyles();
  const [agreementData, setAgreementData] = useState<any>({});
  const [partnerCompany, setPartnerCompany] = useState<any>('');

  const breadcrumbs = [
    {
      path: '/partners',
      label: 'Partners',
    },
    {
      path: `/partner/${agreementData.partnerId}`,
      label: partnerCompany,
    },
    {
      path: '',
      label: 'New Agreement',
    },
  ];

  const pageTitle = (
    <div className={classes.titleWrapper}>
      <div>New Partner</div>
    </div>
  );

  const agreementTitle =
    agreementData.partnerId && partners.length
      ? `New Agreement for ${partnerCompany}`
      : 'New Agreement';

  useEffect(() => {
    partnerId && setAgreementData({ ...agreementData, partnerId });
  }, [partnerId]);

  useEffect(() => {
    const company =
      (partners.length &&
        partners.find((partner) => partner.id === agreementData.partnerId)
          ?.company) ||
      '';
    setPartnerCompany(company);
  }, [agreementData.partnerId, partners]);

  return (
    <Page title={pageTitle} breadcrumbs={breadcrumbs}>
      <AgreementForm title={agreementTitle} agreementData={agreementData} />
    </Page>
  );
}
