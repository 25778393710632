import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '../../../components/Tabs';
import IssuedItems from './IssuedItems';
import ListedItems from './listedItems';
import ItemTypes from './ItemTypes';
import {
  reqGetIssuedItems,
  reqGetItemTypes,
  reqGetListedItems,
} from '../../../redux-store/modules/items';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '0 10px',
  },
  card: {
    padding: 15,
  },
  icon: {
    margin: '0 5px',
  },
  buttonRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: 30,
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    webkitFlexWrap: 'wrap',
    flexWrap: 'wrap',
    width: '100%',
  },
}));

export default function ItemsPage() {
  const { Items, Titles } = useSelector((state) => state);
  const { activeEnvironment = {} } = Titles;
  const { types = [], listed = [], issued = [] } = Items;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const activeTitleId = (Titles.activeTitle && Titles.activeTitle.id) || '';
  const activeEnvironmentId = (activeEnvironment && activeEnvironment.id) || '';

  const handleTabChange = (event, tabIndex) => setActiveTabIndex(tabIndex);

  // Fetch data on first load and anytime the environment changes
  useEffect(() => {
    if (activeEnvironment) {
      dispatch(reqGetItemTypes());
      dispatch(reqGetIssuedItems());
      dispatch(reqGetListedItems());
    }
  }, [dispatch, activeEnvironment]);

  const tabContent = [
    {
      label: `Item Types (${types.length})`,
      component: <ItemTypes types={types} />,
      link: (
        <Link
          to={`/titles/${activeTitleId}/${activeEnvironmentId}/items/types`}
        >{`Item Types (${types.length})`}</Link>
      ),
    },
    {
      label: `Issued Items (${issued.length})`,
      component: <IssuedItems issued={issued} />,
      link: (
        <Link
          to={`/titles/${activeTitleId}/${activeEnvironmentId}/items/issued`}
        >{`Issued Items (${issued.length})`}</Link>
      ),
    },
    {
      label: `Listed Items (${listed.length})`,
      component: <ListedItems listed={listed} />,
      link: (
        <Link
          to={`/titles/${activeTitleId}/${activeEnvironmentId}/items/listed`}
        >{`Listed Items (${listed.length})`}</Link>
      ),
    },
  ];

  return (
    <div className={classes.tabContainer}>
      {tabContent.length ? (
        <div className={classes.tabContainer}>
          <Tabs
            value={activeTabIndex}
            onChange={handleTabChange}
            tabContent={tabContent}
            tabType="secondary"
          />
          {tabContent[activeTabIndex].component}
        </div>
      ) : null}
    </div>
  );
}
