import React, { Fragment, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '../../../components/UI/Button';
import Modal from '../../../components/Modal';
import Table from '../../../components/Table';
import { isValidURL } from '../../../utilities';
import copy from '../../../copy-text';

const useStyles = makeStyles((theme) => ({
  group: {
    padding: 15,
  },
  nameField: {
    marginRight: 20,
    width: '40%',
  },
  paper: {
    marginTop: 15,
    marginBottom: 15,
  },
  row: {
    display: 'flex',
    alignItems: ' center',
    flexDirection: 'row',
    marginBottom: 20,
  },
  table: {
    marginTop: 15,
  },
  deleteIcon: {
    color: theme.table.actions.color,
    cursor: 'pointer',
    marginLeft: 5,
    marginRight: -20,
  },
  deleteGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const WebhookModal = (props) => {
  const { onModalClose, onSubmitWebhook, openModal, title, webhook } = props;
  const classes = useStyles();
  const [newWebhook, setNewWebhook] = useState({ ...webhook });

  const emptyArr = ['', ''];
  const [newHeader, setNewHeader] = useState(emptyArr);
  const [newQuery, setNewQuery] = useState(emptyArr);

  const noError = {
    header: { name: false, value: false },
    query: { name: false, value: false },
    webhook: { name: false, description: false, url: false },
  };
  const [hasError, setHasError] = useState(noError);

  const tableData = {
    name: { label: 'Name', width: 0.5 },
    value: { label: 'Value', width: 0.5 },
  };

  const handleToggle = () => {
    setNewWebhook(
      Object.assign({}, newWebhook, { disabled: !newWebhook.disabled })
    );
  };

  useEffect(() => {
    setNewWebhook(webhook);
  }, [webhook]);

  const handleAddHeader = () => {
    if (newHeader[0].length && newHeader[1].length) {
      setNewWebhook({
        ...newWebhook,
        requiredHeaders: {
          ...newWebhook.requiredHeaders,
          [newHeader[0]]: newHeader[1],
        },
      });
      setNewHeader(emptyArr);
      setHasError(noError);
    } else {
      setHasError({
        ...hasError,
        header: { name: !newHeader[0].length, value: !newHeader[1].length },
      });
    }
  };
  const handleAddQuery = () => {
    if (newQuery[0].length && newQuery[1].length) {
      setNewWebhook({
        ...newWebhook,
        requiredQueryParams: {
          ...newWebhook.requiredQueryParams,
          [newQuery[0]]: newQuery[1],
        },
      });
      setNewQuery(emptyArr);
      setHasError(noError);
    } else {
      setHasError({
        ...hasError,
        query: { name: !newQuery[0].length, value: !newQuery[1].length },
      });
    }
  };
  const handleDeleteHeader = (key) => {
    let modifiedWebhook = JSON.parse(JSON.stringify(webhook));
    delete modifiedWebhook.requiredHeaders[key];
    setNewWebhook(modifiedWebhook);
  };
  const handleDeleteQuery = (key) => {
    let modifiedWebhook = JSON.parse(JSON.stringify(webhook));
    delete modifiedWebhook.requiredQueryParams[key];
    setNewWebhook(modifiedWebhook);
  };
  const handleModalClose = () => {
    setHasError(noError);
    setNewWebhook(webhook);
    onModalClose();
  };

  const handleWebhookChange = (event) =>
    setNewWebhook(
      Object.assign({}, newWebhook, { [event.target.name]: event.target.value })
    );

  const validate = () => {
    if (
      newWebhook.name.length &&
      newWebhook.description.length &&
      isValidURL(newWebhook.url)
    ) {
      setHasError(noError);
      onSubmitWebhook(newWebhook);
    } else {
      setHasError({
        ...hasError,
        webhook: {
          name: !newWebhook.name.length,
          description: !newWebhook.description.length,
          url: !isValidURL(newWebhook.url),
        },
      });
    }
  };

  const renderRows = (obj, rowType) =>
    Object.keys(obj).map((name) => ({
      raw: {
        name,
        value: obj[name],
      },
      table: {
        name,
        value: (
          <span className={classes.deleteGroup}>
            {obj[name]}
            <DeleteIcon
              className={classes.deleteIcon}
              onClick={() =>
                rowType === 'header'
                  ? handleDeleteHeader(name)
                  : handleDeleteQuery(name)
              }
            />
          </span>
        ),
      },
    }));

  return (
    <Modal
      fullWidth
      open={openModal}
      title={title}
      onClose={handleModalClose}
      actions={
        <Fragment>
          <Button type="contained" onClick={validate}>
            Submit
          </Button>
          <Button onClick={() => handleModalClose()} fill={false}>
            Cancel
          </Button>
        </Fragment>
      }
    >
      <FormControl>
        <div>
          <TextField
            fullWidth
            name="name"
            label={copy.pages.environment.webhooks.modal.name.label}
            margin="normal"
            type="text"
            inputProps={{ maxLength: 20 }}
            error={hasError.webhook.name}
            onChange={handleWebhookChange}
            value={newWebhook.name || ''}
          />
          <TextField
            fullWidth
            multiline
            name="description"
            margin="normal"
            type="text"
            label={copy.pages.environment.webhooks.modal.description.label}
            error={hasError.webhook.description}
            onChange={handleWebhookChange}
            value={newWebhook.description || ''}
          />
          <TextField
            fullWidth
            margin="normal"
            type="text"
            name="url"
            label={copy.pages.environment.webhooks.modal.url.label}
            error={hasError.webhook.url}
            onChange={handleWebhookChange}
            value={newWebhook.url || ''}
          />
          <FormControlLabel
            control={
              <Switch
                name="disabled"
                checked={!newWebhook.disabled}
                onChange={handleToggle}
              />
            }
            label="Enabled"
          />
        </div>
        <Paper className={classes.paper}>
          <div className={classes.group}>
            <FormLabel component="legend">
              {copy.pages.environment.webhooks.modal.headers.title}
            </FormLabel>
            <div className={classes.row}>
              <TextField
                label="Name"
                margin="dense"
                className={`${classes.nameField}`}
                error={hasError.header.name}
                onChange={(event) =>
                  setNewHeader([event.target.value, newHeader[1]])
                }
                value={newHeader[0]}
              />
              <TextField
                label="Value"
                margin="dense"
                error={hasError.header.value}
                onChange={(event) =>
                  setNewHeader([newHeader[0], event.target.value])
                }
                value={newHeader[1]}
              />
              <Button onClick={handleAddHeader}>
                {copy.pages.environment.webhooks.modal.headers.cta}
              </Button>
            </div>
            {Object.keys(newWebhook.requiredHeaders).length ? (
              <Table
                downloadable={false}
                className={classes.table}
                tableData={tableData}
                rowData={renderRows(newWebhook.requiredHeaders, 'header')}
              />
            ) : null}
          </div>
        </Paper>
        <Paper className={classes.paper}>
          <div className={classes.group}>
            <FormLabel component="legend">
              {copy.pages.environment.webhooks.modal.query.title}
            </FormLabel>
            <div className={classes.row}>
              <TextField
                label="Name"
                margin="dense"
                className={classes.nameField}
                error={hasError.query.name}
                onChange={(event) =>
                  setNewQuery([event.target.value, newQuery[1]])
                }
                value={newQuery[0]}
              />
              <TextField
                label="Value"
                margin="dense"
                error={hasError.query.value}
                onChange={(event) =>
                  setNewQuery([newQuery[0], event.target.value])
                }
                value={newQuery[1]}
              />
              <Button onClick={handleAddQuery}>
                {copy.pages.environment.webhooks.modal.query.cta}
              </Button>
            </div>
            {Object.keys(newWebhook.requiredQueryParams).length ? (
              <Table
                downloadable={false}
                className={classes.table}
                tableData={tableData}
                rowData={renderRows(newWebhook.requiredQueryParams, 'query')}
              />
            ) : null}
          </div>
        </Paper>
      </FormControl>
    </Modal>
  );
};

export default WebhookModal;
