import { call, fork, put, select, takeLatest } from 'redux-saga/effects';
import actions, { setAuthenticated, setToken } from './actions';
import { reqGetOrgs } from '../orgs';
import { getUserSettings } from '../account';

function* reqLoginSaga() {
  const state = yield select();
  yield call(state.Authentication.keycloak.login);
}
function* reqLogoutSaga() {
  const state = yield select();
  yield call(state.Authentication.keycloak.logout);
}
function* setAuthenticatedSaga(action) {
  if (action.authenticated) {
    try {
      yield put(reqGetOrgs());
      yield put(getUserSettings());
    } catch (e) {}
  }
}
function* setKeycloakSaga(action) {
  const { keycloak } = action;

  if (keycloak && keycloak.authenticated) {
    try {
      const state = yield select();
      // Update token
      yield put(setToken(keycloak.idToken));
      // Update authentication status
      if (state.Authentication.authenticated !== keycloak.authenticated) {
        yield put(setAuthenticated(keycloak.authenticated));
      }
    } catch (e) {}
  } else {
    yield put(setAuthenticated(false));
    yield put(setToken(null));
  }
}
function* watchSetKeycloakSaga() {
  yield takeLatest(actions.SET_KEYCLOAK, setKeycloakSaga);
}
function* watchReqLoginSaga() {
  yield takeLatest(actions.REQ_LOGIN, reqLoginSaga);
}
function* watchReqLogoutSaga() {
  yield takeLatest(actions.REQ_LOGOUT, reqLogoutSaga);
}
function* watchSetAuthenticatedSaga() {
  yield takeLatest(actions.SET_AUTHENTICATED, setAuthenticatedSaga);
}

export default function* authenticationSagas() {
  yield fork(watchSetAuthenticatedSaga);
  yield fork(watchReqLoginSaga);
  yield fork(watchReqLogoutSaga);
  yield fork(watchSetKeycloakSaga);
}
