import React from 'react';
import Table from '../../../components/Table';
import { formatISODate } from '../../../utilities';
import { IVITableRowData } from 'types';

export default function ListedItems(props) {
  const { listed = [] } = props;

  const tableData = {
    dgoodIds: { label: 'dGood ID', width: 0.1, sortable: true },
    gameInventoryIds: { label: 'Item Name', width: 0.2, sortable: true },
    sellerPlayerId: { label: 'Seller ID', width: 0.2, sortable: true },
    saleAmount: { label: 'Price', width: 0.1, sortable: true },
    currency: { label: 'Currency', width: 0.1, sortable: true },
    sellByDate: { label: 'Sell By Date', width: 0.3, sortable: true },
  };

  const renderRowData = (row) => {
    const rowData: IVITableRowData = {
      table: {},
      raw: {},
    };
    Object.keys(row).forEach((key, i) => {
      switch (key) {
        case 'dgoodIds':
          const dgoodId = parseInt(row['dgoodIds'][0]);
          rowData.table[key] = dgoodId;
          rowData.raw[key] = dgoodId;
          break;
        case 'gameInventoryIds':
          rowData.table[key] = row[key];
          break;
        case 'saleAmount':
          let formattedAmount = row[key].toFixed(2);
          rowData.table[key] = formattedAmount;
          rowData.raw[key] = formattedAmount;
          break;
        case 'sellByDate':
          let sellByDate = row.sellByDate.toString();
          let formattedDate = formatISODate(sellByDate);
          rowData.table[key] = formattedDate;
          rowData.raw[key] = formattedDate;
          break;
        default:
          rowData.table[key] = row[key];
          rowData.raw[key] = row[key];
          break;
      }
    });
    return rowData;
  };

  const rowData = listed.map((row) => renderRowData(row));

  return (
    <Table
      label={`Listed Items (${listed.length})`}
      downloadable={true}
      expandable={true}
      rowData={rowData}
      tableData={tableData}
    />
  );
}
