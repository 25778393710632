import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../../components/UI/Button';
import ProfileCard from './ProfileCard';
import ThemeSelector from './ThemeSelector';
import { TextField, Typography } from '@material-ui/core';
import UserAvatar from '../../../components/UserAvatar';
import ImageUploader from '../../../components/ImageUploader';
import Modal from '../../../components/Modal';
import {
  reqResetPassword,
  reqSetProfileData,
  reqSetProfileImage,
} from '../../../redux-store/modules/account';
import { isValidEmail } from '../../../utilities';
import copy from '../../../copy-text';

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    marginTop: 0,
    padding: 30,
    marginBottom: 30,
    width: '100%',
  },
  button: {
    marginTop: 30,
    fontSize: '0.9em',
    fontWeight: 'bold',
  },
  buttons: ({ isMobile }: any) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: isMobile ? 'flex-end' : 'flex-start',
  }),
  changePicture: {
    cursor: 'pointer',
    position: 'absolute',
    color: '#FFF',
    fontSize: '0.8em',
    textAlign: 'center',
    width: 100,
    zIndex: 3,
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  profileImage: {
    color: theme.text.primary,
    width: 175,
    height: 175,
    zIndex: 2,
  },
  noProfile: {
    position: 'absolute',
    backgroundColor: '#333',
    borderRadius: 75,
    width: 155,
    height: 155,
    zIndex: 1,
  },
  profileAction: {
    cursor: 'pointer',
    fontSize: '0.8em',
    marginTop: 10,
  },
  link: {
    color: theme.text.highlight,
  },
  remove: {
    //TODO this was previously theme.palette.warning
    color: theme.palette.warning.main,
  },
  updatePassword: {
    color: theme.text.highlight,
    cursor: 'pointer',
    fontSize: '0.9em',
    marginTop: 20,
  },
}));

export default function Details() {
  const { Account, Settings } = useSelector((state) => state);
  const { profile } = Account;
  const { isMobile } = Settings;
  const { given_name, family_name, email, birthdate } = profile;
  const dispatch = useDispatch();
  const classes = useStyles({ isMobile });
  const [canEdit, setCanEdit] = useState(false);
  const [userData, setUserData] = useState<any>(
    Object.assign({}, { given_name, family_name, email, birthdate })
  );
  const [openImgUploader, setOpenImgUploader] = useState(false);

  const formState = {
    given_name: {
      error: false,
      msg: copy.pages.account.details.firstName.errorText,
    },
    family_name: {
      error: false,
      msg: copy.pages.account.details.lastName.errorText,
    },
  };
  const [formErrors, setFormErrors] = useState(formState);
  const [showModal, setShowModal] = useState(false);

  const validateUser = () => {
    if (
      userData.given_name.length &&
      userData.family_name.length &&
      isValidEmail(userData.email)
    ) {
      const { email, given_name, family_name } = userData;
      setFormErrors(formState);
      setCanEdit(false);
      dispatch(
        reqSetProfileData({ email, given_name, family_name, birthdate })
      );
    } else {
      setFormErrors({
        ...formErrors,
        given_name: {
          ...formErrors.given_name,
          error: !userData.given_name.length,
        },
        family_name: {
          ...formErrors.family_name,
          error: !userData.family_name.length,
        },
      });
    }
  };

  const handleChange = (event) =>
    setUserData({ ...userData, [event.target.name]: event.target.value });

  const handleChangePicture = () => setOpenImgUploader(true);

  const handleResetUser = () => {
    setUserData({ given_name, family_name, email });
    setFormErrors(formState);
    setCanEdit(false);
  };
  const handleProfileImage = (imgData) => {
    setOpenImgUploader(false);
    dispatch(reqSetProfileImage(imgData));
  };
  const handleChangePassword = () => {
    dispatch(reqResetPassword());
    setShowModal(true);
  };

  const actions = canEdit ? (
    <div className={classes.buttons}>
      {userData.email !== email ||
      userData.given_name !== given_name ||
      userData.family_name !== family_name ? (
        <Button
          className={classes.button}
          fill={true}
          type="outlined"
          onClick={() => validateUser()}
        >
          Save
        </Button>
      ) : null}
      <Button
        className={classes.button}
        fill={false}
        type="outlined"
        onClick={() => handleResetUser()}
      >
        Cancel
      </Button>
    </div>
  ) : (
    <div className={classes.buttons}>
      <Button
        className={classes.button}
        fill={false}
        type="outlined"
        onClick={() => setCanEdit(true)}
      >
        Edit
      </Button>
    </div>
  );

  const detailsCol1 = (
    <div className={classes.profile}>
      <UserAvatar className={classes.profileImage} />
      <Typography
        className={`${classes.profileAction} ${classes.link}`}
        onClick={() => handleChangePicture()}
      >
        {copy.pages.account.details.picture.cta}
      </Typography>
    </div>
  );

  const detailsCol2 = (
    <div>
      <TextField
        fullWidth
        disabled={!canEdit}
        margin="normal"
        name="given_name"
        label={copy.pages.account.details.firstName.label}
        value={userData.given_name || ''}
        onChange={handleChange}
        inputProps={{ maxLength: 20 }}
        error={formErrors.given_name.error}
        helperText={
          formErrors.given_name.error ? formErrors.given_name.msg : ''
        }
      />
      <TextField
        fullWidth
        disabled={!canEdit}
        margin="normal"
        name="family_name"
        label={copy.pages.account.details.lastName.label}
        value={userData.family_name || ''}
        onChange={handleChange}
        inputProps={{ maxLength: 20 }}
        error={formErrors.family_name.error}
        helperText={
          formErrors.family_name.error ? formErrors.family_name.msg : ''
        }
      />
      <TextField
        fullWidth
        disabled={true}
        margin="normal"
        name="email"
        label={copy.pages.account.details.email.label}
        value={userData.email || ''}
        onChange={handleChange}
      />
      {actions}
      <Typography
        className={classes.updatePassword}
        onClick={() => handleChangePassword()}
      >
        {copy.pages.account.details.updatePassword.cta}
      </Typography>
    </div>
  );

  return (
    <div>
      <ImageUploader
        open={openImgUploader}
        onSubmit={handleProfileImage}
        onClose={() => setOpenImgUploader(false)}
      />
      <ProfileCard
        title={copy.pages.account.details.title}
        col1={detailsCol1}
        col2={detailsCol2}
        isMobile={Settings.isMobile}
      />
      <ThemeSelector />
      <Modal
        open={showModal}
        title={copy.pages.account.details.updatePassword.title}
        onClose={() => setShowModal(false)}
        actions={<Button onClick={() => setShowModal(false)}>Done</Button>}
      >
        <Typography>
          We've sent an email with instructions to <strong>{email}</strong>.
          Check your email and follow the instructions to proceed.
        </Typography>
      </Modal>
    </div>
  );
}
