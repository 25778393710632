import { call, put, select } from 'redux-saga/effects';
import { setErrorMsg, setShowError } from './modules/settings';

function* hasError(msg) {
  try {
    yield put(setErrorMsg(msg));
    yield put(setShowError(true));
  } catch (err) {}
}

export function* mythicalFetcher(apiMethod, params = []) {
  const state = yield select();

  const options = {
    token: state.Authentication.keycloak.token,
    titleId: state.Titles.activeTitle && state.Titles.activeTitle.id,
    environmentId:
      state.Titles.activeEnvironment && state.Titles.activeEnvironment.id,
    organizationId: state.Orgs.activeOrg && state.Orgs.activeOrg.id,
    platformUserId: state.Account.platformUserId,
  };

  try {
    const response = yield call(apiMethod, options, ...params);

    if (response === undefined) {
      yield hasError(`An unknown error occurred`);
      return null;
    }
    if (response.code) {
      yield hasError(`Error code ${response.code}: ${response.message}`);
      return null;
    }
    if (response.status === 500) {
      yield hasError(`Error code 500 fetching: ${response.url}`);
      return null;
    }

    return response;
  } catch (error) {
    console.error(error);
    hasError('An error occurred. Please try again.');
  }
}
