export const actions = {
  GET_USER_SETTINGS: 'GET_USER_SETTINGS',
  REQ_RESET_PASSWORD: 'REQ_RESET_PASSWORD',
  REQ_SET_PROFILE_IMAGE: 'REQ_SET_PROFILE_IMAGE',
  REQ_SET_PROFILE_DATA: 'REQ_SET_PROFILE_DATA',
  SET_ACCOUNT: 'SET_ACCOUNT',
  SET_PROFILE: 'SET_PROFILE',
  SET_PROFILE_IMAGE: 'SET_PROFILE_IMAGE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
};

export const getUserSettings = () => ({ type: actions.GET_USER_SETTINGS });
export const reqResetPassword = () => ({ type: actions.REQ_RESET_PASSWORD });
export const reqSetProfileImage = (imgData) => ({
  type: actions.REQ_SET_PROFILE_IMAGE,
  imgData,
});
export const reqSetProfileData = (profileData) => ({
  type: actions.REQ_SET_PROFILE_DATA,
  profileData,
});
export const setAccount = (account) => ({ type: actions.SET_ACCOUNT, account });
export const setProfile = (profile) => ({ type: actions.SET_PROFILE, profile });
export const setProfileImage = (profileImage) => ({
  type: actions.SET_PROFILE_IMAGE,
  profileImage,
});
export const updateProfile = (profile) => ({
  type: actions.UPDATE_PROFILE,
  profile,
});

export default actions;
