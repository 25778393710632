import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../components/Table';
import { reqGetPlayers } from '../../../redux-store/modules/players';
import { IVITableRowData } from 'types';

export default function PlayersTab() {
  const { Players, Titles } = useSelector((state) => state);
  const dispatch = useDispatch();

  const tableData = {
    playerId: { label: 'Player ID', width: 0.9, sortable: true },
    blank: { label: '', width: 0.1, sortable: false },
  };

  const renderPlayerRow = (playerRow) => {
    const playerData: IVITableRowData = {
      table: {},
      raw: {},
    };
    Object.keys(playerRow).forEach((key, i) => {
      playerData.raw[key] = playerRow[key];
      switch (key) {
        case 'dob':
          playerData.table[key] = new Date(playerRow[key]).toLocaleDateString();
          break;
        default:
          playerData.table[key] = playerRow[key];
          break;
      }
    });
    return playerData;
  };

  const rowData = Players.players
    ? Players.players.map((player) => renderPlayerRow(player))
    : [];

  // Fetch data on first load and anytime the environment changes
  useEffect(() => {
    Titles.activeEnvironment && dispatch(reqGetPlayers());
  }, [dispatch, Titles.activeEnvironment]);

  return (
    <Fragment>
      <Table
        label={`Players (${rowData.length})`}
        downloadable={true}
        expandable={true}
        tableData={tableData}
        rowData={rowData}
      />
    </Fragment>
  );
}
