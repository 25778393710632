import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import { setActivePage, setMenuOpen } from '../../redux-store/modules/settings';
import OrgSelect from './orgSelect';
import Title from './title';
import getTheme from '../../themes';
import { contentWidth } from '../../themes/base';
import { useRoleTarget } from '../../hooks';
import IVILogo from '../../assets/img/IVI-logo.png';

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  collapseContainer: {
    overflowX: 'hidden',
    //overflowY: 'scroll'
  },
  drawer: ({ isMobile }: any) => ({
    display: 'flex',
    backgroundColor: isMobile ? 'transparent' : theme.sideBar.backgroundColor,
    width: theme.sideBar.widthOpen,
    height: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  }),
  drawerOpen: {
    //boxShadow: '0px 0px 15px #000',
    backgroundColor: theme.sideBar.backgroundColor,
    overflowX: 'hidden',
    width: theme.sideBar.widthOpen,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  drawerClose: {
    //boxShadow: '0px 0px 15px #000',
    backgroundColor: theme.sideBar.backgroundColor,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    visibility: 'visible',
    width: theme.sideBar.widthClosed,
  },
  drawerPaper: {
    alignItems: 'center',
    backgroundColor: theme.sideBar.backgroundColor,
    width: theme.sideBar.widthOpen,
  },
  hidden: {
    visibility: 'hidden',
  },
  highlight: {
    backgroundColor: theme.sideBar.highlight,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 0,
    width: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  listItem: {
    cursor: 'pointer',
    paddingLeft: 0,
    paddingRight: 0,
  },
  listItemIcon: () => ({
    marginLeft: 20,
    marginRight: 20,
    height: 20,
    width: 20,
  }),
  listItemText: {
    color: theme.sideBar.color,
    fontSize: theme.sideBar.fontSize,
    display: 'flex',
  },
  logo: {
    color: theme.sideBar.color,
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    bottom: 0,
    height: 30,
    margin: 20,
    '& img': {
      display: 'flex',
      height: '100%',
      marginRight: 20,
    },
    '& p': {
      fontSize: '0.7em',
      marginTop: -4,
    },
  },
  select: {
    padding: 0,
  },
  sideBarTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: theme.appBar.height,
    width: '100%',
  },
  titlesIcon: {
    color: theme.sideBar.color,
    fontSize: 18,
  },
  titlesItem: {
    marginLeft: 0,
  },
  titlesList: {
    boxSizing: 'content-box',
    height: '100%',
    //overflowY: 'scroll',
    overflowX: 'hidden',
    maxHeight: '100%',
    paddingRight: 1,
    width: '100%',
    '&::-webkit-scrollbar': {
      width: 1,
    },
  },
}));

export default function SideBar() {
  const { Orgs, Settings, Titles } = useSelector((state) => state);
  const { activeOrg, orgs } = Orgs;
  const { activePage, colorTheme, isMobile, menuOpen, screenDims } = Settings;
  const dispatch = useDispatch();
  let history = useHistory();
  const theme = getTheme(colorTheme);
  const partnerPermissions = useRoleTarget('partners');
  const titlesPermissions = useRoleTarget('titles');
  const secretsPermissions = useRoleTarget('secrets');
  const invitesPermissions = useRoleTarget('invites');
  const orgPermissions = useRoleTarget('organizations');
  const orgAdmin =
    secretsPermissions.READ || invitesPermissions.READ || orgPermissions.READ;
  const classes = useStyles({ isMobile, menuOpen, width: screenDims.width });
  const transitionDuration = 350;
  const mainLinks = {
    dashboard: {
      path: '/',
      label: 'Dashboard',
      Icon: theme.icons.dashboard,
    },
    titles: {
      path: '/titles',
      label: 'Titles',
      Icon: theme.icons.titles,
    },
    partners: {
      path: '/partners',
      label: 'Partners',
      Icon: theme.icons.partners,
    },
    admin: {
      path: '/admin',
      label: 'Admin',
      Icon: theme.icons.admin,
    },
  };

  const logo = menuOpen ? (
    <div className={classes.logo}>
      <img alt="IVI Engine" src={IVILogo} />
      <p>
        © 2020 IVI Engine, Mythical, Inc.
        <br />
        All rights reserved.
      </p>
    </div>
  ) : null;

  const handleLinkClick = (event, path, label) => {
    event.stopPropagation();
    dispatch(setActivePage(label));
    history.push(path);
    isMobile && dispatch(setMenuOpen(false));
  };

  const renderMainLink = ({ Icon, label, path }) => (
    <ListItem
      className={`${classes.listItem} ${
        activePage === label ? classes.highlight : ''
      }`}
      onClick={(event) => handleLinkClick(event, path, label)}
    >
      <ListItemIcon>
        <div className={classes.row}>
          <img className={`${classes.listItemIcon}`} src={Icon} alt={label} />
          <Typography
            className={`${classes.listItemText} ${
              !menuOpen ? classes.hidden : ''
            } ${activePage === label ? classes.bold : ''}`}
          >
            {label}
          </Typography>
        </div>
      </ListItemIcon>
    </ListItem>
  );

  const renderTitles = () =>
    Titles.titles.length ? (
      <Collapse
        classes={{ container: classes.collapseContainer }}
        in={menuOpen}
        timeout="auto"
        mountOnEnter
        unmountOnExit
      >
        <List className={classes.titlesList} component="div" disablePadding>
          {Titles.titles
            .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
            .map((title, i) => (
              <Title
                key={i}
                environmentTypes={Titles.environmentTypes}
                title={title}
                isActive={
                  Titles.activeTitle && Titles.activeTitle.id === title.id
                }
                onLinkClick={handleLinkClick}
              />
            ))}
        </List>
      </Collapse>
    ) : null;

  // Set active page based on path
  useEffect(
    () =>
      Object.keys(mainLinks).forEach((link) =>
        mainLinks[link].path === history.location.pathname
          ? dispatch(setActivePage(mainLinks[link].label))
          : null
      ),
    []
  );

  return (
    <Drawer
      variant={screenDims.width < contentWidth ? "temporary" : "permanent"}
      anchor="left"
      className={classes.drawer}
      classes={{ paper: menuOpen ? classes.drawerOpen : classes.drawerClose }}
      open={menuOpen}
      transitionDuration={transitionDuration}
      onClick={() => dispatch(setMenuOpen(!menuOpen))}
      onClose={() => dispatch(setMenuOpen(!menuOpen))}
    >
      <OrgSelect organizations={orgs} activeOrg={activeOrg} />
      <List className={classes.list}>
        {renderMainLink(mainLinks.dashboard)}
        {titlesPermissions.READ && renderMainLink(mainLinks.titles)}
        {titlesPermissions.READ && renderTitles()}
        {partnerPermissions.READ && renderMainLink(mainLinks.partners)}
        {orgAdmin && renderMainLink(mainLinks.admin)}
      </List>
      {logo}
    </Drawer>
  );
}
