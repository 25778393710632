import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Page from '../../components/Page';
import PartnerForm from './partnerForm';

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  breadcrumbs: {
    fontSize: '1em',
    marginTop: 5,
    '& a': {
      color: theme.text.secondary,
      textDecoration: 'none',
    },
  },
  active: {
    fontWeight: 600,
  },
}));

export default function NewPartner() {
  const classes = useStyles();

  const title = (
    <div className={classes.titleWrapper}>
      <div>New Partner</div>
      <div className={classes.breadcrumbs}>
        <Typography>
          <Link to="/partners">Partners</Link>
          <span className={classes.active}>{` > Add New Partner`}</span>
        </Typography>
      </div>
    </div>
  );

  return (
    <Page title={title}>
      <PartnerForm title="New Partner Details" partnerData={null} />
    </Page>
  );
}
