import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Page from '../../components/Page';
import Tabs from '../../components/Tabs';
import ClickToCopy from '../../components/ClickToCopy';
import PartnerDetails from './partnerDetails';
import PartnerAgreements from './partnerAgreements';

const useStyles = makeStyles((theme) => ({
  companyName: {
    height: 60,
    marginBottom: 10,
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    webkitFlexWrap: 'wrap',
    flexWrap: 'wrap',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  partnerId: {
    display: 'flex',
    fontSize: '0.6em',
  },
}));

export default function PartnerPage() {
  const { Partners } = useSelector((state) => state);
  const { activePartner, agreements } = Partners;
  const { partnerId, tab } = useParams();

  const classes = useStyles();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleTabChange = (event, tabIndex) => setActiveTabIndex(tabIndex);

  const tabContent = [
    {
      label: `Details`,
      component: <PartnerDetails partnerData={activePartner} />,
      link: (
        <Link to={`/partner/${partnerId}/details`}>
          <Typography>Details</Typography>
        </Link>
      ),
      slug: 'details',
    },
    {
      label: `Agreements`,
      component: (
        <PartnerAgreements
          agreements={agreements.filter(
            (agreement) => agreement.partnerId === partnerId
          )}
        />
      ),
      link: (
        <Link to={`/partner/${partnerId}/agreements`}>
          <Typography>Agreements</Typography>
        </Link>
      ),
      slug: 'agreements',
    },
  ];

  const breadcrumbs = [
    {
      path: '/partners',
      label: 'Partners',
    },
    {
      path: '',
      label: activePartner.company,
    },
  ];

  const title =
    (activePartner && activePartner.company && (
      <div className={classes.titleWrapper}>
        <div className={classes.companyName}>
          {activePartner.company}
          <ClickToCopy value={activePartner.id}>
            <Typography className={classes.partnerId}>
              {activePartner.id}
            </Typography>
          </ClickToCopy>
        </div>
      </div>
    )) ||
    '';

  // Get tab index
  useEffect(() => {
    const tabIndex = tabContent.indexOf(
      tabContent.find((item, i) => item.slug === tab && tab)
    );
    setActiveTabIndex(tabIndex >= 0 ? tabIndex : 0);
  }, [tab, tabContent]);

  return activePartner ? (
    <Page title={title} breadcrumbs={breadcrumbs}>
      <Tabs
        value={activeTabIndex}
        onChange={handleTabChange}
        tabContent={tabContent}
      />
      <div className={classes.tabContainer}>
        {tabContent[activeTabIndex].component}
      </div>
    </Page>
  ) : null;
}
