import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  setActiveAgreement,
  setActivePartner,
} from '../../redux-store/modules/partners';

const PartnerRoot = () => {
  const dispatch = useDispatch();
  const { Partners } = useSelector((state) => state);
  const {
    activeAgreement,
    activePartner,
    agreements = [],
    partners = [],
  } = Partners;
  const { agreementId, partnerId } = useParams();

  // Check if agreementId exists and set it active.
  useEffect(() => {
    if (
      agreements.length &&
      agreementId &&
      !Object.keys(activeAgreement).length
    ) {
      dispatch(setActiveAgreement(agreementId));
    }
  }, [agreementId, agreements]);

  // Check if partnerId exists and set it active.
  useEffect(() => {
    if (partners.length && partnerId && !Object.keys(activePartner).length) {
      dispatch(setActivePartner(partnerId));
    }
  }, [partnerId, partners]);

  return null;
};

export default PartnerRoot;
