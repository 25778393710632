import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useDimensions } from '../../../../hooks';
import Card from '../../../../components/Card';
import getTheme from '../../../../themes';
import { formatDate, hexToRgb } from '../../../../utilities';

const useStyles = makeStyles((theme) => ({
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  col: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  divider: {
    //borderTop: `1px solid ${theme.text.secondary}`,
    marginTop: 30,
    width: '100%',
  },
  itemCol: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginTop: 20,
    width: '100%',
    '&:first-child': {
      marginTop: 0,
    },
  },
  items: {
    height: '100%',
    overflow: 'hidden',
  },
  titleRow: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  tokenCategory: {
    fontSize: '1em',
  },
  tokenName: {
    fontSize: '1.25em',
    fontWeight: 'bold',
  },
  labelRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    '& p': {
      color: theme.text.secondary,
      fontSize: '0.8em',
    },
  },
  valueRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    '& p': {
      color: theme.text.primary,
      fontSize: '0.9em',
    },
  },
  progressCol: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 5,
  },
  progressContainer: ({ color = '#000000' }: any) => {
    const rgbColor = hexToRgb(color);
    return {
      backgroundColor: `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 0.2)`,
      position: 'relative',
      height: 2,
      width: '100%',
    };
  },
  progressIndicator: ({ color = '#000000', width }: any) => {
    const rgbColor = hexToRgb(color);
    return {
      backgroundColor: `rgba(${rgbColor.r},${rgbColor.g},${rgbColor.b}, 1)`,
      position: 'absolute',
      height: 2,
      top: 0,
      left: 0,
      width,
    };
  },
}));

function Indicator(props) {
  const { color, label1, label2, value1, value2, width } = props;
  const classes = useStyles({ color, width });

  return (
    <div className={classes.progressCol}>
      <div className={classes.labelRow}>
        <Typography>{label1}</Typography>
        <Typography>{label2}</Typography>
      </div>
      <div className={classes.progressContainer}>
        <div className={classes.progressIndicator} />
      </div>
      <div className={classes.valueRow}>
        <Typography>{value1}</Typography>
        <Typography>{value2}</Typography>
      </div>
    </div>
  );
}

function Item(props) {
  const { item } = props;
  const {
    category,
    createdTimestamp,
    issuedSupply,
    issueTimeSpan,
    maxSupply,
    tokenName,
  } = item;
  const classes = useStyles();
  const { Settings } = useSelector((state) => state);
  const theme = getTheme(Settings.colorTheme);
  const { accounts, soldItems } = theme.dashboard.icons;

  const supplyRatio = (issuedSupply / maxSupply) * 100;
  const dateRatio =
    (createdTimestamp / (createdTimestamp + issueTimeSpan * 24 * 60 * 60)) *
    100;

  return (
    <div className={classes.itemCol}>
      <div className={classes.row}>
        <div className={classes.col}>
          <div className={classes.titleRow}>
            <Typography className={classes.tokenName}>{tokenName}</Typography>
            <Typography className={classes.tokenCategory}>
              {category}
            </Typography>
          </div>
          <Indicator
            color={soldItems.color}
            width={`${supplyRatio}%`}
            label1="Issued"
            label2="Max Supply"
            value1={issuedSupply}
            value2={maxSupply}
          />
          <Indicator
            color={accounts.color}
            width={dateRatio}
            label1="Created"
            label2="Max Issue Date"
            value1={formatDate(createdTimestamp * 1000)}
            value2={formatDate(
              (createdTimestamp + issueTimeSpan * 24 * 60 * 60) * 1000
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default function Items(props) {
  const ITEM_HEIGHT = 145;
  const { className = '' } = props;
  const { Items } = useSelector((state) => state);
  const { types = [] } = Items;
  const classes = useStyles();
  const [itemsRef, dimensions] = useDimensions();
  const [numItems, setNumItems] = useState<any>();

  const RenderItems = () =>
    types.slice(0, numItems).map((item, i) => <Item key={i} item={item} />);

  useEffect(() => {
    if (dimensions?.height) {
      const calcItems = Math.floor(dimensions.height / ITEM_HEIGHT);
      setNumItems(calcItems);
    }
  }, [dimensions]);

  return types.length ? (
    <Card className={`${classes.items} ${className}`}>
      <div ref={itemsRef} className={classes.content}>
        <RenderItems />
      </div>
    </Card>
  ) : null;
}
