import actions from './actions';

const initialState = {
  platformUserId: null,
  profileImage: null,
  profile: {},
};

export default function Account(state = initialState, action) {
  switch (action.type) {
    case actions.SET_ACCOUNT:
      return {
        ...state,
        ...action.account,
      };

    case actions.SET_PROFILE:
      return {
        ...state,
        platformUserId: action.profile.sub,
        profileImage: action.profile.picture,
        profile: action.profile,
      };

    case actions.SET_PROFILE_IMAGE:
      return {
        ...state,
        profileImage: action.profileImage,
        profile: {
          ...state.profile,
          picture: action.profileImage,
        },
      };

    case actions.UPDATE_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          email: action.profile.email,
          given_name: action.profile.firstName,
          family_name: action.profile.lastName,
        },
      };

    default:
      return state;
  }
}
