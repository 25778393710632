import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Typography, Select } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { setActiveOrg } from '../../redux-store/modules/orgs';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  select: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  defaultCursor: {
    cursor: 'default',
  },
  expandIcon: {
    color: theme.sideBar.color,
    display: 'flex',
    fontSize: '1.5em',
    marginRight: 15,
  },
  menuItem: {
    color: theme.orgSelect.color,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  selectMenu: {
    color: theme.sideBar.highlight,
  },
  selectPaper: {
    top: `${theme.appBar.height}px !important`,
  },
  orgs: {
    backgroundColor: theme.sideBar.backgroundColor,
    color: theme.sideBar.color,
    display: 'flex',
    height: theme.appBar.height,
    flexDirection: 'row',
    alignItems: 'center',
  },
  orgName: {
    color: 'white',
    display: 'flex',
    fontSize: '1em',
    fontWeight: 'bold',
    width: 160,
    overflow: 'hidden',
    wordWrap: 'break-word',
    flexWrap: 'wrap',
  },
  orgImg: {
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#999',
    color: 'white',
    display: 'flex',
    fontWeight: 'bold',
    fontSize: '1em',
    textTransform: 'uppercase',
    width: 30,
    height: 30,
    borderRadius: '50%',
    marginLeft: 15,
  },
}));

const OrgSelect = (props) => {
  const { dispatch, history, Orgs, Settings } = props;
  const { activeOrg } = Orgs;
  const { menuOpen } = Settings;

  const classes = useStyles();

  const handleSelectOrg = (event) => {
    event.stopPropagation();
    dispatch(setActiveOrg(event.target.value.id));
    history.push('/');
  };

  const renderOrg = () =>
    Orgs.orgs.length > 1 ? (
      <Select
        autoWidth
        className={classes.root}
        classes={{
          icon: classes.expandIcon,
          select: classes.select,
          selectMenu: classes.selectMenu,
        }}
        MenuProps={{
          PopoverClasses: { paper: classes.selectPaper },
        }}
        disableUnderline={true}
        IconComponent={KeyboardArrowDownIcon}
        inputProps={{ id: 'org-select' }}
        value={activeOrg}
        onChange={handleSelectOrg}
        onClick={(event) => event.stopPropagation()}
        onOpen={(event) => event.stopPropagation()}
        renderValue={(org: any) => (
          <MenuItem className={classes.menuItem}>
            <Typography className={classes.orgName}>
              {org.displayName}
            </Typography>
          </MenuItem>
        )}
      >
        {Orgs.orgs.map((org, i) => (
          <MenuItem
            className={classes.menuItem}
            key={i}
            value={org.id === activeOrg.id ? activeOrg : org}
          >
            <Typography>{org.displayName}</Typography>
          </MenuItem>
        ))}
      </Select>
    ) : (
      <MenuItem
        className={`${classes.menuItem} ${classes.defaultCursor}`}
        value={activeOrg.id}
      >
        <Typography>{activeOrg.displayName}</Typography>
      </MenuItem>
    );

  return activeOrg ? (
    <div className={classes.orgs}>
      <div>
        <Typography className={classes.orgImg}>
          {activeOrg.displayName[0]}
        </Typography>
      </div>
      {menuOpen && renderOrg()}
    </div>
  ) : (
    <div className={classes.orgs} />
  );
};

const mapStateToProps = ({ dispatch, Orgs, Settings }) => ({
  dispatch,
  Orgs,
  Settings,
});

export default withRouter(connect(mapStateToProps)(OrgSelect));
