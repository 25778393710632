import { debounce, fork, put, takeLatest } from 'redux-saga/effects';
import actions, { setTransactions } from './actions';
import { getTransactions } from '../../../services/ivi-engine';
import { mythicalFetcher } from '../../utilities';

function* getTransactionsSaga() {
  try {
    const transactions = yield mythicalFetcher(getTransactions);
    yield put(setTransactions(transactions));
  } catch (e) {}
}
function* watchGetTransactionsSaga() {
  yield debounce(1000, actions.REQ_GET_TRANSACTIONS, getTransactionsSaga);
}
export default function* webhooksSagas() {
  yield fork(watchGetTransactionsSaga);
}
