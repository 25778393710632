import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  environmentItemText: {
    color: theme.sideBar.color,
    fontSize: '1.2em',
    fontWeight: 300,
    display: 'flex',
    marginLeft: 10,
  },
  environmentLink: {
    display: 'flex',
    alignItems: 'center',
    padding: `0px 0px 0px ${theme.sideBar.widthOpen / 5.5}px`,
    margin: '5px 0',
    width: '100%',
  },
  environmentSquare: {
    height: 8,
    width: 8,
    marginLeft: 15,
    //backgroundColor: '#bbb',
    borderRadius: 4,
    display: 'flex',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    textDecoration: 'none',
  },
  highlight: {
    backgroundColor: theme.sideBar.highlight,
    fontWeight: 'bold',
  },
}));

const Environment = (props) => {
  const { environment, onLinkClick, titleId, Titles, Settings } = props;
  const { activePage } = Settings;
  const { environmentTypes } = Titles;
  const classes = useStyles();

  return (
    <Link
      to={`/titles/${titleId}/${environment.id}`}
      className={`${classes.link} ${
        activePage === environment.id ? classes.highlight : ''
      }`}
      onClick={(event) =>
        onLinkClick(
          event,
          `/titles/${titleId}/${environment.id}`,
          environment.id
        )
      }
    >
      <div className={`${classes.environmentLink}`}>
        <span
          className={classes.environmentSquare}
          style={{
            backgroundColor:
              environmentTypes[environment.environmentType].color,
          }}
        />
        <Typography className={classes.environmentItemText}>
          {environment.displayName}
        </Typography>
      </div>
    </Link>
  );
};

const mapStateToProps = ({ dispatch, Settings, Titles }) => ({
  dispatch,
  Settings,
  Titles,
});

export default connect(mapStateToProps)(Environment);
