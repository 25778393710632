import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  tabs: {
    display: 'flex',
  },
}));

const PrimaryTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: theme.tabs.indicator,
  },
  scroller: {
    borderBottom: 'none',
    marginBottom: 15,
  },
}))((props) => <Tabs {...props} />);

const PrimaryTab = withStyles((theme) => ({
  root: {
    borderBottom: `1px solid ${theme.text.secondary}`,
    color: theme.text.primary,
    textTransform: 'capitalize',
    fontSize: '1.1em',
    '& a': {
      textDecoration: 'none',
      color: theme.text.primary,
    },
  },
  selected: {
    color: theme.text.primary,
    '& p': {
      fontWeight: 'bold',
    },
  },
  textColorPrimary: {
    color: theme.text.secondary,
  },
}))((props: any) => <Tab {...props} />);

const SecondaryTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: 'transparent',
  },
  scroller: {
    borderBottom: `none`,
    marginBottom: 5,
  },
}))((props: any) => <Tabs {...props} />);

const SecondaryTab = withStyles((theme) => ({
  root: {
    color: theme.text.secondary,
    fontSize: '1.1em',
    textTransform: 'none',
    '& a': {
      textDecoration: 'none',
      color: theme.text.secondary,
    },
    '& p': {
      color: theme.text.secondary,
    },
  },
  selected: {
    color: theme.text.primary,
    fontWeight: 'bold',
    '& a': {
      textDecoration: 'none',
      color: theme.text.primary,
    },
    '& p': {
      fontWeight: 'bold',
      color: theme.text.primary,
    },
  },
}))((props: any) => <Tab {...props} />);

const ButtonTabs = withStyles((theme) => ({
  indicator: {
    backgroundColor: 'transparent',
  },
  scroller: {
    borderBottom: `none`,
    marginBottom: 5,
  },
}))((props: any) => <Tabs {...props} />);

const ButtonTab = withStyles((theme) => ({
  root: {
    backgroundColor: theme.tabs.buttonTabs.root.backgroundColor,
    border: '1px solid #999',
    color: theme.tabs.buttonTabs.root.color,
    margin: '5px 0',
    minHeight: 36,
    height: 36,
    padding: 0,
    fontSize: '1.1em',
    textTransform: 'none',
    '& a': {
      textDecoration: 'none',
      color: theme.text.secondary,
    },
    '& p': {
      color: theme.text.secondary,
    },
    '&:first-child': {
      borderTopLeftRadius: 18,
      borderBottomLeftRadius: 18,
    },
    '&:last-child': {
      borderTopRightRadius: 18,
      borderBottomRightRadius: 18,
    },
  },
  selected: {
    backgroundColor: theme.tabs.buttonTabs.selected.backgroundColor,
    fontWeight: 'bold',
    '& a': {
      textDecoration: 'none',
      color: theme.text.primary,
    },
    '& p': {
      fontWeight: 'bold',
      color: theme.text.lowlight,
    },
  },
}))((props: any) => <Tab {...props} />);

const CustomTabs = (props) => {
  const { tabContent = [], tabType = 'primary', ...otherProps } = props;
  const classes = useStyles();
  let tabs;

  switch (tabType) {
    case 'primary':
      tabs = (
        <PrimaryTabs indicatorColor="primary" {...otherProps}>
          {tabContent.map((tab, i) => (
            <PrimaryTab key={i} disableRipple label={tab.link} />
          ))}
        </PrimaryTabs>
      );
      break;

    case 'secondary':
      tabs = (
        <SecondaryTabs indicatorColor="primary" {...otherProps}>
          {tabContent.map((tab, i) => (
            <SecondaryTab key={i} disableRipple label={tab.link} />
          ))}
        </SecondaryTabs>
      );
      break;

    case 'button':
      tabs = (
        <ButtonTabs indicatorColor="primary" {...otherProps}>
          {tabContent.map((tab, i) => (
            <ButtonTab key={i} disableRipple label={tab.link} />
          ))}
        </ButtonTabs>
      );
      break;

    default:
      tabs = (
        <PrimaryTabs indicatorColor="primary" {...otherProps}>
          {tabContent.map((tab, i) => (
            <PrimaryTab key={i} disableRipple label={tab.link} />
          ))}
        </PrimaryTabs>
      );
      break;
  }

  return <div className={classes.tabs}>{tabs}</div>;
};

export default CustomTabs;
