import actions from './actions';

const initialState = {
  webhooks: [],
};

export default function Webhooks(state = initialState, action) {
  switch (action.type) {
    case actions.SET_WEBHOOKS:
      return {
        ...state,
        webhooks: action.webhooks,
      };

    default:
      return state;
  }
}
