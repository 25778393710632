import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '../../components/UI/Button';
import Table from '../../components/Table';
import ClickToCopy from '../../components/ClickToCopy';
import { formatISODate } from '../../utilities';
import { useRoleTarget } from '../../hooks';
import {
  setActiveAgreement,
  setActivePartner,
} from '../../redux-store/modules/partners';
import { IVITableRowData } from 'types';

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.text.highlight,
    textDecoration: 'none',
  },
}));

export default function AgreementsTable() {
  const { Partners, Titles } = useSelector((state) => state);
  const { agreements, partners } = Partners;
  const { titles } = Titles;
  const dispatch = useDispatch();
  const classes = useStyles();
  let history = useHistory();

  const partnersPermissions = useRoleTarget('partners');

  const handleAgreementLink = (path, partnerId, agreementId) => {
    dispatch(setActiveAgreement(agreementId));
    dispatch(setActivePartner(partnerId));
    history.push(path);
  };

  const handlePartnerLink = (path, partnerId) => {
    dispatch(setActivePartner(partnerId));
    history.push(path);
  };

  const tableData = {
    name: { label: 'Name', width: 0.2, sortable: true },
    id: { label: 'Agreement ID', width: 0.15, sortable: true },
    active: { label: 'Status', width: 0.1, sortable: true },
    titleId: { label: 'Title Name', width: 0.2, sortable: true },
    createdTimestamp: { label: 'Date Created', width: 0.2, sortable: true },
    partnerId: { label: 'Company Name', width: 0.15, sortable: true },
  };

  const renderRowData = (agreement) => {
    const rowData: IVITableRowData = {
      table: {},
      raw: {},
    };

    Object.keys(agreement).forEach((key, i) => {
      rowData.raw[key] = agreement[key];
      rowData.table[key] = agreement[key];

      switch (key) {
        case 'id':
          rowData.table[key] = (
            <ClickToCopy value={agreement[key]}>
              <Typography>{`${agreement[key]
                .toString()
                .substring(0, 7)}...`}</Typography>
            </ClickToCopy>
          );
          break;
        case 'active':
          rowData.raw[key] = agreement[key] === true ? 'true' : 'false';
          rowData.table[key] = agreement[key] === true ? 'Active' : 'Inactive';
          break;
        case 'createdTimestamp':
          rowData.table[key] = formatISODate(parseInt(agreement[key]));
          break;
        case 'itemTypes':
          rowData.table[key] = agreement[key].join(' ').trim();
          rowData.raw[key] = agreement[key].toString();
          break;
        case 'partnerId':
          let companyName = partners.find(
            (partner) => partner.id === agreement[key]
          ).company;
          rowData.table[key] = (
            <div
              className={classes.link}
              onClick={() =>
                handlePartnerLink(
                  `/partner/${agreement.partnerId}`,
                  agreement.partnerId
                )
              }
            >
              {companyName}
            </div>
          );
          break;
        case 'name':
          rowData.table[key] = (
            <div
              className={classes.link}
              onClick={() =>
                handleAgreementLink(
                  `/partner/${agreement.partnerId}/agreement/${agreement.id}`,
                  agreement.partnerId,
                  agreement.id
                )
              }
            >
              {agreement[key]}
            </div>
          );
          break;
        case 'titleId':
          rowData.table[key] = titles.find(
            (title) => title.id === agreement[key]
          ).name;
          break;
        default:
          rowData.table[key] = agreement[key];
          break;
      }
    });

    return rowData;
  };

  const cta = partnersPermissions.CREATE && (
    <Link className={classes.link} to={`/partners/new-agreement`}>
      <Button type="contained">+ New Agreement</Button>
    </Link>
  );

  return (
    <Table
      label={`Agreements (${agreements.length})`}
      cta={cta}
      downloadable={false}
      expandable={true}
      rowData={
        agreements.length
          ? agreements.map((agreement) => renderRowData(agreement))
          : []
      }
      tableData={tableData}
    />
  );
}
