const actions = {
  REQ_ADD_WEBHOOK: 'REQ_ADD_WEBHOOK',
  REQ_DELETE_WEBHOOK: 'REQ_DELETE_WEBHOOK',
  REQ_GET_WEBHOOKS: 'REQ_GET_WEBHOOKS',
  REQ_UPDATE_WEBHOOK: 'REQ_UPDATE_WEBHOOK',
  SET_WEBHOOKS: 'SET_WEBHOOKS',
};

export const reqAddWebhook = (webhook) => ({
  type: actions.REQ_ADD_WEBHOOK,
  webhook,
});
export const reqDeleteWebhook = (webhookId) => ({
  type: actions.REQ_DELETE_WEBHOOK,
  webhookId,
});
export const reqGetWebhooks = () => ({ type: actions.REQ_GET_WEBHOOKS });
export const reqUpdateWebhook = (webhook) => ({
  type: actions.REQ_UPDATE_WEBHOOK,
  webhook,
});
export const setWebhooks = (webhooks) => ({
  type: actions.SET_WEBHOOKS,
  webhooks,
});

export default actions;
