import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '../../../../components/Card';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    height: '100%',
    userSelect: 'none',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  title: {
    color: theme.text.secondary,
    fontWeight: 600,
    padding: 5,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  table: {
    borderCollapse: 'collapse',
    borderSpacing: 0,
    border: '1px solid white',
    borderRadius: 3,
    marginTop: 50,
    overflow: 'hidden',
    width: '100%',
  },
  tableHeader: {
    width: '100%',
    color: 'white',
    backgroundColor: theme.table.header.backgroundColor,
    '& p': {
      display: 'flex',
      paddingLeft: 5,
      fontSize: '0.8em',
      textAlign: 'left',
    },
    '& td:last-child': {
      '& p': {
        justifyContent: 'flex-end',
        paddingRight: 5,
      },
    },
  },
  tableRow: {
    padding: '0 10px',
  },
  cell: {
    padding: 5,
    border: '1px solid white',
    '& p': {
      display: 'flex',
      fontSize: '1em',
    },
    '&:last-child': {
      '& p': {
        justifyContent: 'flex-end',
      },
    },
  },
  seeMore: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    bottom: 0,
    '& a': {
      textDecoration: 'none',
    },
    '& p': {
      color: theme.text.highlight,
      cursor: 'pointer',
      fontSize: '1em',
    },
  },
}));

const Activity = (props) => {
  const classes = useStyles();

  const activity = [
    { event: 'Item Created', timestamp: 202029390 },
    { event: 'Item Sold', timestamp: 202029391 },
    { event: 'Player Logged In', timestamp: 202029392 },
    { event: 'Item Burned', timestamp: 202029393 },
    { event: 'Error', timestamp: 202029394 },
    { event: 'Item Created', timestamp: 202029395 },
    { event: 'Item Created', timestamp: 202029396 },
    { event: 'Item Created', timestamp: 202029397 },
    { event: 'Item Sold', timestamp: 202029398 },
    { event: 'Item Created', timestamp: 202029390 },
    { event: 'Item Sold', timestamp: 202029391 },
    { event: 'Player Logged In', timestamp: 202029392 },
    { event: 'Item Burned', timestamp: 202029393 },
    { event: 'Error', timestamp: 202029394 },
    { event: 'Item Created', timestamp: 202029395 },
    { event: 'Item Created', timestamp: 202029396 },
    { event: 'Item Created', timestamp: 202029397 },
    { event: 'Item Sold', timestamp: 202029398 },
    { event: 'Item Created', timestamp: 202029390 },
    { event: 'Item Sold', timestamp: 202029391 },
    { event: 'Player Logged In', timestamp: 202029392 },
  ];

  const seeMore = (
    <div className={classes.seeMore}>
      <Link to="/dashboard/activity">
        <Typography>See More</Typography>
      </Link>
    </div>
  );

  return (
    <Card className={classes.card}>
      <div className={classes.content}>
        <Typography className={classes.title}>Recent Activity</Typography>
        <table className={classes.table}>
          <tbody>
            <tr className={`${classes.tableHeader} ${classes.tableRow}`}>
              <td>
                <Typography>EVENT</Typography>
              </td>
              <td>
                <Typography>TIMESTAMP</Typography>
              </td>
            </tr>
            {activity.map((event, i) => (
              <tr key={i} className={classes.tableRow}>
                <td className={classes.cell}>
                  <Typography>{event.event}</Typography>
                </td>
                <td className={classes.cell}>
                  <Typography>
                    {new Date(event.timestamp).toLocaleString('en-US', {
                      timeZone: 'UTC',
                    })}
                  </Typography>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {seeMore}
      </div>
    </Card>
  );
};

export default Activity;
