import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.select.backgroundColor,
    border: `1px solid ${theme.text.secondary}`,
    color: theme.text.primary,
    marginTop: 10,
  },
  select: {
    '&:focus': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  icon: {
    color: theme.text.secondary,
  },
  inputLabel: {
    color: theme.text.secondary,
    top: 15,
    left: 10,
    zIndex: 999,
    '&.Mui-focused': {
      color: theme.text.highlight,
    },
  },
  selectHelperText: {
    position: 'absolute',
    bottom: -25,
    '& p': {
      color: theme.colors.primary,
      fontSize: '0.9em',
      lineHeight: '1em',
    },
  },
}));

export default function CustomSelect(props) {
  const {
    children,
    className,
    errorText = '',
    error = false,
    label = '',
    onChange,
    options,
    ...otherProps
  } = props;
  const classes = useStyles();

  return (
    <FormControl>
      <InputLabel className={classes.inputLabel} htmlFor="role-helper">
        {label}
      </InputLabel>
      <Select
        variant="filled"
        disableUnderline
        className={`${classes.root} ${className}`}
        classes={{
          select: classes.select,
          icon: classes.icon,
        }}
        IconComponent={KeyboardArrowDownIcon}
        onChange={onChange}
        {...otherProps}
      >
        {options.map((option, i) => (
          <MenuItem key={i} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error ? (
        <div className={classes.selectHelperText}>
          <Typography>{errorText}</Typography>
        </div>
      ) : null}
    </FormControl>
  );
}
