import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '../../../components/Table';
import { IVITableRowData } from 'types';
import { formatISODate } from '../../../utilities';

export default function IssuedItems(props) {
  const { issued = [] } = props;

  const tableData = {
    dgoodId: { label: 'dGood ID', width: 0.1, sortable: true },
    itemName: { label: 'Item Name', width: 0.2, sortable: true },
    gameInventoryId: {
      label: 'Game Inventory ID',
      width: 0.15,
      sortable: true,
    },
    ownerPlayerId: { label: 'Owner ID', width: 0.15, sortable: true },
    tokenName: { label: 'Item Type', width: 0.15, sortable: true },
    category: { label: 'Category', width: 0.15, sortable: true },
    finalized: { label: 'Finalized', width: 0.1, sortable: true },
  };

  const renderRowData = (row) => {
    const rowData: IVITableRowData = {
      table: {},
      raw: {},
    };
    Object.keys(row).forEach((key, i) => {
      rowData.raw[key] = row[key];

      switch (key) {
        case 'additionalMetadata':
          rowData.raw[key] = row[key] && JSON.stringify(row[key]);
          break;
        case 'finalized':
          rowData.table[key] = (
            <Checkbox disabled name={key} checked={row[key]} />
          );
          rowData.raw[key] = row[key] === true ? 'true' : 'false';
          break;
        case 'metadataUri':
          rowData.raw[key] = (
            <a target="_new" href={row[key]}>
              {row[key]}
            </a>
          );
          break;
        case 'revenueDistribution':
          rowData.raw[key] = JSON.stringify(row[key]);
          break;
        case 'createdTimestamp':
          rowData.raw[key] = formatISODate(row.createdTimestamp);
          break;
        default:
          rowData.table[key] = row[key];
          break;
      }
    });
    return rowData;
  };

  const rowData = issued.map((row) => renderRowData(row));

  return (
    <Table
      label={`Issued Items (${issued.length})`}
      downloadable={true}
      expandable={true}
      rowData={rowData}
      tableData={tableData}
    />
  );
}
