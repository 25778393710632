const actions = {
  REQ_ADD_AGREEMENT: 'REQ_ADD_AGREEMENT',
  REQ_ADD_PARTNER: 'REQ_ADD_PARTNER',
  REQ_ADD_PARTNER_AND_AGREEMENT: 'REQ_ADD_PARTNER_AND_AGREEMENT',
  REQ_GET_PARTNERS_AND_AGREEMENTS: 'REQ_GET_PARTNERS_AND_AGREEMENTS',
  REQ_UPDATE_AGREEMENT: 'REQ_UPDATE_AGREEMENT',
  REQ_UPDATE_PARTNER: 'REQ_UPDATE_PARTNER',
  SET_ACTIVE_AGREEMENT: 'SET_ACTIVE_AGREEMENT',
  SET_ACTIVE_PARTNER: 'SET_ACTIVE_PARTNER',
  SET_AGREEMENTS: 'SET_AGREEMENTS',
  SET_PARTNERS: 'SET_PARTNERS',
  SET_UPDATED_AGREEMENT: 'SET_UPDATED_AGREEMENT',
  SET_UPDATED_PARTNER: 'SET_UPDATED_PARTNER',
};

export const reqAddAgreement = (agreement) => ({
  type: actions.REQ_ADD_AGREEMENT,
  agreement,
});
export const reqAddPartner = (partner) => ({
  type: actions.REQ_ADD_PARTNER,
  partner,
});
export const reqAddPartnerAndAgreement = (partner) => ({
  type: actions.REQ_ADD_PARTNER_AND_AGREEMENT,
  partner,
});
export const reqGetPartnersAndAgreements = (organizationId) => ({
  type: actions.REQ_GET_PARTNERS_AND_AGREEMENTS,
  organizationId,
});
export const reqUpdateAgreement = (agreement) => ({
  type: actions.REQ_UPDATE_AGREEMENT,
  agreement,
});
export const reqUpdatePartner = (partner) => ({
  type: actions.REQ_UPDATE_PARTNER,
  partner,
});
export const setActiveAgreement = (agreementId) => ({
  type: actions.SET_ACTIVE_AGREEMENT,
  agreementId,
});
export const setActivePartner = (partnerId) => ({
  type: actions.SET_ACTIVE_PARTNER,
  partnerId,
});
export const setAgreements = (agreements) => ({
  type: actions.SET_AGREEMENTS,
  agreements,
});
export const setPartners = (partners) => ({
  type: actions.SET_PARTNERS,
  partners,
});
export const setUpdatedAgreement = (agreement) => ({
  type: actions.SET_UPDATED_AGREEMENT,
  agreement,
});
export const setUpdatedPartner = (partner) => ({
  type: actions.SET_UPDATED_PARTNER,
  partner,
});

export default actions;
