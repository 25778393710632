import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

var useStyles = makeStyles((theme) => ({
  errorMessage: {
    textAlign: 'center',
    marginTop: '40px',
  },
}));

const AppError = (props) => {
  const classes = useStyles();

  return (
    <Typography className={classes.errorMessage}>
      Something went wrong, and we've sent an error report to our developers.
      Please try again later.
    </Typography>
  );
};

export default AppError;
