import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Switch, Route, useLocation, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Page from '../../components/Page';
import Tabs from '../../components/Tabs';
import { reqAcceptInvite } from '../../redux-store/modules/orgs';
import Details from './details';
import Organizations from './organizations';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    display: 'flex',
    flexDirection: 'row',
    webkitFlexWrap: 'wrap',
    flexWrap: 'wrap',
    width: '100%',
  },
}));

export default function AccountPage() {
  const { Orgs } = useSelector((state) => state);
  const { orgs } = Orgs;
  const dispatch = useDispatch();
  const location = useLocation();
  const { tab } = useParams();
  const { pathname } = location;
  const classes = useStyles();

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const handleTabChange = (event, tabIndex) => setActiveTabIndex(tabIndex);

  const tabContent = [
    {
      label: `My Details`,
      component: <Details />,
      link: (
        <Link to={`/account/details`}>
          <Typography>My Details</Typography>
        </Link>
      ),
      slug: 'details',
    },
    {
      label: `My Organizations`,
      component: <Organizations />,
      link: (
        <Link to={`/account/organizations`}>
          <Typography>My Organizations</Typography>
        </Link>
      ),
      slug: 'organizations',
    },
  ];

  // Get invite id if it exists
  // Email invites should contain a link to /account/organizations?orgid=1234&inviteid=1234
  // This hook will grab the orgid and inviteid and send a post request to accept
  useEffect(() => {
    let timer;
    const url = new URL(window.location.href);
    const inviteId = url.searchParams.get('inviteid');
    const orgId = url.searchParams.get('orgid');
    const hasOrg =
      orgId && orgs.length && orgs.find((org) => org.id === orgId).id === orgId
        ? true
        : false;

    // Set a timer to dispatch the action before we determine the user is not part of any orgs
    if (!hasOrg && inviteId !== null && orgId !== null) {
      timer = setTimeout(() => dispatch(reqAcceptInvite(orgId, inviteId)), 100);
    }

    return () => clearTimeout(timer);
  }, [dispatch, orgs]);

  // Get tab index
  useEffect(() => {
    const tabIndex = tabContent.indexOf(
      tabContent.find((item, i) => item.slug === tab && tab)
    );
    setActiveTabIndex(tabIndex >= 0 ? tabIndex : 0);
  }, [tab, tabContent]);

  return (
    <Page title="Account">
      <Tabs
        value={activeTabIndex}
        onChange={handleTabChange}
        tabContent={tabContent}
      />
      <div className={classes.tabContainer}>
        {tabContent[activeTabIndex].component}
      </div>
      <Switch>
        <Route exact path={`${pathname}/details`} component={Details} />
        <Route
          exact
          path={`${pathname}/organizations`}
          component={Organizations}
        />
      </Switch>
    </Page>
  );
}
