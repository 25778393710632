import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Page from '../../components/Page';
import Table from '../../components/Table';
import { setActivePage } from '../../redux-store/modules/settings';
import { IVITableRowData } from 'types';

export default function Activity() {
  const dispatch = useDispatch();

  const activity = [
    { event: 'Item Created', timestamp: 2020429390 },
    { event: 'Item Sold', timestamp: 2020293591 },
    { event: 'Player Logged In', timestamp: 2402029392 },
    { event: 'Item Burned', timestamp: 202029393 },
    { event: 'Error', timestamp: 202029394 },
    { event: 'Item Created', timestamp: 202029395 },
    { event: 'Item Created', timestamp: 202029396 },
    { event: 'Item Created', timestamp: 202029397 },
    { event: 'Item Sold', timestamp: 202029398 },
    { event: 'Item Created', timestamp: 202029390 },
    { event: 'Item Sold', timestamp: 202029391 },
    { event: 'Player Logged In', timestamp: 202029392 },
    { event: 'Item Burned', timestamp: 202029393 },
    { event: 'Error', timestamp: 202029394 },
    { event: 'Item Created', timestamp: 202029395 },
    { event: 'Item Created', timestamp: 202029396 },
    { event: 'Item Created', timestamp: 202029397 },
    { event: 'Item Sold', timestamp: 202029398 },
    { event: 'Item Created', timestamp: 202029390 },
    { event: 'Item Sold', timestamp: 202029391 },
    { event: 'Player Logged In', timestamp: 202029392 },
    { event: 'Item Burned', timestamp: 202029393 },
    { event: 'Error', timestamp: 202029394 },
    { event: 'Item Created', timestamp: 202029395 },
    { event: 'Item Created', timestamp: 202029396 },
    { event: 'Item Created', timestamp: 202029397 },
    { event: 'Item Sold', timestamp: 202029398 },
  ];

  const tableData = {
    event: { label: 'Event', width: 0.4, sortable: true },
    timestamp: { label: 'Timestamp', width: 0.4, sortable: true },
    blank: { label: '', width: 0.2, sortable: false },
  };

  const renderRowData = (row) => {
    const rowData: IVITableRowData = {
      table: {},
      raw: {},
    };
    Object.keys(row).forEach((key, i) => {
      rowData.raw[key] = row[key];

      switch (key) {
        case 'timestamp':
          rowData.table[key] = new Date(row[key]).toLocaleString('en-US', {
            timeZone: 'UTC',
          });
          break;
        default:
          rowData.table[key] = row[key];
          break;
      }
    });
    return rowData;
  };

  const rowData = activity.length
    ? activity.map((row) => renderRowData(row))
    : [];

  const breadcrumbs = [
    {
      path: '/',
      label: 'Dashboard',
    },
    {
      path: '',
      label: 'Recent Activity',
    },
  ];

  // Set initial activePage
  useEffect(() => {
    dispatch(setActivePage('Dashboard'));
  }, [dispatch]);

  return (
    <Page title="Recent Activity" breadcrumbs={breadcrumbs}>
      <Table
        label={`Events`}
        downloadble={true}
        expandable={false}
        rowData={rowData}
        tableData={tableData}
      />
    </Page>
  );
}
