const actions = {
  REQ_GET_TRANSACTIONS: 'REQ_GET_TRANSACTIONS',
  SET_TRANSACTIONS: 'SET_TRANSACTIONS',
};

export const reqGetTransactions = () => ({
  type: actions.REQ_GET_TRANSACTIONS,
});
export const setTransactions = (transactions) => ({
  type: actions.SET_TRANSACTIONS,
  transactions,
});

export default actions;
