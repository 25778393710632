const getConfig = () => {
  const local = {
    apis: {
      google: {
        analytics: {
          trackingId: 'UA-145492735-1',
        },
      },
      mythical: {
        hyperionBaseUrl: 'https://hyperion-api.testnet.iviengine.com/v2',
        iviBaseUrl: 'http://localhost:8081',
        keycloak: {
          authUrl: 'https://accounts.mythical.games',
          clientId: 'ivi-customer-portal-dev',
          realm: 'mythical',
        },
      },
    },
    links: {
      account_reset:
        'https://accounts.mythical.games/realms/mythical-dev/login-actions/reset-credentials',
      dev_docs: 'https://docs.dev.mythical.games/docs/doc1',
      api_docs: 'https://docs.dev.mythical.games/api.html',
    },
  };
  const dev = {
    apis: {
      google: {
        analytics: {
          trackingId: 'UA-145492735-1',
        },
      },
      mythical: {
        hyperionBaseUrl: 'https://hyperion-api.testnet.iviengine.com/v2',
        iviBaseUrl: 'https://api.dev.iviengine.com',
        keycloak: {
          authUrl: 'https://accounts.mythical.games',
          clientId: 'ivi-customer-portal-dev',
          realm: 'mythical',
        },
      },
    },
    links: {
      account_reset:
        'https://accounts.mythical.games/realms/mythical-dev/login-actions/reset-credentials',
      dev_docs: 'https://docs.dev.mythical.games/docs/doc1',
      api_docs: 'https://docs.dev.mythical.games/api.html',
    },
  };
  const qa = {
    apis: {
      google: {
        analytics: {
          trackingId: 'UA-145492735-1',
        },
      },
      mythical: {
        hyperionBaseUrl: 'https://hyperion-api.testnet.iviengine.com/v2',
        iviBaseUrl: 'https://api.qa.iviengine.com',
        keycloak: {
          authUrl: 'https://accounts.mythical.games',
          clientId: 'ivi-customer-portal-qa',
          realm: 'mythical',
        },
      },
    },
    links: {
      account_reset:
        'https://accounts.mythical.games/realms/mythical-dev/login-actions/reset-credentials',
      dev_docs: 'https://docs.dev.mythical.games/docs/doc1',
      api_docs: 'https://docs.dev.mythical.games/api.html',
    },
  };
  const prod = {
    apis: {
      google: {
        analytics: {
          trackingId: 'UA-145492735-2',
        },
      },
      mythical: {
        hyperionBaseUrl: 'https://hyperion-api.iviengine.com/v2',
        iviBaseUrl: 'https://api.iviengine.com',
        keycloak: {
          authUrl: 'https://accounts.mythical.games',
          clientId: 'ivi-customer-portal-prod',
          realm: 'mythical',
        },
      },
    },
    links: {
      account_reset:
        'https://accounts.mythical.games/realms/mythical/login-actions/reset-credentials',
      dev_docs: 'https://docs.mythical.games/docs/doc1',
      api_docs: 'https://docs.mythical.games/api.html',
    },
  };
  const lt = {
    apis: {
      google: {
        analytics: {
          trackingId: '',
        },
      },
      mythical: {
        hyperionBaseUrl: 'https://hyperion-api.iviengine.com/v2',
        iviBaseUrl: 'https://api.lt.iviengine.com',
        keycloak: {
          authUrl: 'https://accounts.lt.blankos.game',
          clientId: 'ivi-customer-portal-lt',
          realm: 'master',
        },
      },
    },
    links: {
      account_reset:
        'https://accounts.lt.mythical.games/realms/master/login-actions/reset-credentials',
      dev_docs: 'https://docs.mythical.games/docs/doc1',
      api_docs: 'https://docs.mythical.games/api.html',
    },
  };

  switch (process.env.REACT_APP_ENV) {
    case 'local':
      return local;
    case 'dev':
      return dev;
    case 'prod':
      return prod;
    case 'qa':
      return qa;
    case 'lt':
      return lt;
    default:
      return dev;
  }
};

const config = getConfig();

export default config;
