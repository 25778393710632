import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../../components/UI/Button';
import TextField from '@material-ui/core/TextField';
import Modal from '../../components/Modal';
import copy from '../../copy-text';

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const EditTitle = (props) => {
  const { onModalClose, onSubmit, openModal, title } = props;

  const [titleData, setTitleData] = useState(title);
  const classes = useStyles();

  const formState = {
    name: {
      error: false,
      msg: copy.pages.titles.editTitle.modal.titleName.errorText,
    },
  };
  const [formErrors, setFormErrors] = useState(formState);

  const validateTitle = () => {
    if (titleData.name.length) {
      setFormErrors(formState);
      onSubmit(titleData);
    } else {
      setFormErrors({
        ...formErrors,
        name: {
          ...formErrors.name,
          error: !titleData.name.length,
        },
      });
    }
  };

  const handleChange = (event) =>
    setTitleData({ ...titleData, [event.target.name]: event.target.value });
  const handleModalClose = () => {
    setTitleData(title);
    setFormErrors(formState);
    onModalClose();
  };

  return title ? (
    <Modal
      open={openModal}
      title={copy.pages.titles.editTitle.modal.title}
      onClose={handleModalClose}
      actions={
        <div className={classes.actions}>
          <Button type="contained" onClick={() => validateTitle()}>
            Update
          </Button>
          <Button onClick={handleModalClose} fill={false}>
            Cancel
          </Button>
        </div>
      }
    >
      <div className={classes.container}>
        <TextField
          autoFocus
          margin="dense"
          type="text"
          name="name"
          label={copy.pages.titles.editTitle.modal.titleName.label}
          error={formErrors.name.error}
          helperText={formErrors.name.error ? formErrors.name.msg : ''}
          inputProps={{ maxLength: 20 }}
          onChange={handleChange}
          value={titleData.name}
        />
        <TextField
          disabled
          margin="dense"
          type="text"
          label={copy.pages.titles.editTitle.modal.titleId.label}
          value={titleData.id}
        />
      </div>
    </Modal>
  ) : null;
};

export default EditTitle;
