import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  acceptInvite,
  addSecret,
  deleteSecret,
  disableSecret,
  enableSecret,
  getOrgs,
  getPendingInvites,
  getSecrets,
  getOrgRoles,
  getOrgUsers,
  inviteUsers,
  updateOrg,
} from '../../../services/ivi-engine';
import actions, {
  reqGetSecrets,
  reqGetOrgRoles,
  reqGetOrgUsers,
  setActiveOrg,
  setOrgRoles,
  setOrgs,
  setOrgUsers,
  setPendingInvites,
  setSecrets,
  setUserIsAdmin,
  updateActiveOrg,
} from './actions';
import { reqGetPartnersAndAgreements } from '../partners';
import { reqGetTitles } from '../titles';
import { mythicalFetcher } from '../../utilities';

function* addSecretSaga(action) {
  try {
    const response = yield mythicalFetcher(addSecret, [action.secret]);
    yield console.log('new secret response', response);
    if (response.id) {
      const state = yield select();
      const newSecrets = Object.assign([], state.Orgs.secrets);
      newSecrets.push({ ...response, createdTimestamp: Date.now() });
      yield put(setSecrets(newSecrets));
    }
  } catch (e) {}
}
function* deleteSecretSaga(action) {
  try {
    const response = yield mythicalFetcher(deleteSecret, [action.secretId]);
    if (response.ok) {
      const state = yield select();
      const newSecrets = state.Orgs.activeOrg.secrets.filter(
        (secret) => secret.id !== action.secretId
      );
      yield put(setSecrets(newSecrets));
    }
  } catch (e) {}
}
function* getOrgPendingInvitesSaga(orgId) {
  try {
    const invites = yield mythicalFetcher(getPendingInvites, [orgId]);
    yield put(setPendingInvites({ orgId, invites }));
  } catch (e) {}
}
function* getOrgUsersSaga(action) {
  const { orgId } = action;
  try {
    const orgUsers = yield mythicalFetcher(getOrgUsers, [orgId]);
    yield put(setOrgUsers(orgUsers));
  } catch (e) {}
}
function* getSecretsSaga(action) {
  const { orgId } = action;
  try {
    const response = yield mythicalFetcher(getSecrets, [orgId]);
    const secrets = Array.isArray(response) ? response : [];
    yield put(setSecrets(secrets));
  } catch (e) {}
}
function* getOrgsSaga() {
  try {
    const state = yield select();
    const orgs = yield mythicalFetcher(getOrgs);
    if (orgs.length) {
      const lastActiveOrg =
        state.persisted.activeOrg &&
        orgs.find((org) => org.id === state.persisted.activeOrg.id);
      const orgId = lastActiveOrg ? lastActiveOrg.id : orgs[0].id;
      yield put(setOrgs(orgs));
      yield put(setActiveOrg(orgId));
    }
  } catch (e) {}
}
function* getOrgRolesSaga(action) {
  const { orgId } = action;
  try {
    const roles = yield mythicalFetcher(getOrgRoles, [orgId]);
    yield roles.length && put(setOrgRoles(roles));
  } catch (e) {}
}
function* setActiveOrgSaga(action) {
  const { orgId } = action;
  try {
    yield all([
      put(reqGetTitles()),
      put(reqGetPartnersAndAgreements(orgId)),
      put(reqGetOrgRoles(orgId)),
      put(reqGetSecrets(orgId)),
      put(reqGetOrgUsers(orgId)),
    ]);
  } catch (e) {}
}
function* setOrgUsersSaga(action) {
  try {
    // Set user org role
    const state = yield select();
    const { role, organizationId } = action.users.find(
      (user) => user.userId === state.Account.platformUserId
    );
    // Set T/F if user is an admin of the org
    yield put(
      setUserIsAdmin(
        organizationId,
        role === 'org_owner' || role === 'org_admin'
      )
    );
  } catch (e) {}
}
function* reqAcceptInviteSaga(action) {
  try {
    const { orgId, inviteId } = action;
    yield mythicalFetcher(acceptInvite, [orgId, inviteId]);
    yield call(getOrgsSaga);
  } catch (e) {}
}
function* reqInviteUsersSaga(action) {
  try {
    const response = yield mythicalFetcher(inviteUsers, [action.invites]);
    if (response.length) {
      const state = yield select();
      yield call(getOrgPendingInvitesSaga, state.Orgs.activeOrg.id);
    }
  } catch (e) {}
}
function* reqUpdateOrgSaga(action) {
  try {
    const updatedOrg = yield mythicalFetcher(updateOrg, [action.organization]);
    if (updatedOrg) {
      const state = yield select();
      const updatedOrgs = [
        {
          ...state.Orgs.orgs.find((org) => org.id === action.organization.id),
          ...updatedOrg,
        },
        ...state.Orgs.orgs.filter((org) => org.id !== action.organization.id),
      ];
      yield put(setOrgs(updatedOrgs));
      yield put(updateActiveOrg(updatedOrg));
      // Need to refetch titles to refresh their available env types
      yield put(reqGetTitles());
    }
  } catch (e) {}
}
function* toggleDisableSecretSaga(action) {
  try {
    const response = action.secret.disabled
      ? yield mythicalFetcher(disableSecret, [action.secret.id])
      : yield mythicalFetcher(enableSecret, [action.secret.id]);
    if (response.ok) {
      const state = yield select();
      const newSecrets = state.Orgs.activeOrg.secrets.map((secret) =>
        secret.id === action.secret.id ? action.secret : secret
      );
      yield put(setSecrets(newSecrets));
    }
  } catch (e) {}
}

function* watchAcceptInviteSaga() {
  yield takeLatest(actions.REQ_ACCEPT_INVITE, reqAcceptInviteSaga);
}
function* watchAddSecretSaga() {
  yield takeLatest(actions.REQ_ADD_SECRET, addSecretSaga);
}
function* watchDeleteSecretSaga() {
  yield takeLatest(actions.REQ_DELETE_SECRET, deleteSecretSaga);
}
function* watchGetOrgsSaga() {
  yield takeLatest(actions.REQ_GET_ORGS, getOrgsSaga);
}
function* watchGetOrgUsersSaga() {
  yield takeLatest(actions.REQ_GET_ORG_USERS, getOrgUsersSaga);
}
function* watchGetOrgRolesSaga() {
  yield takeLatest(actions.REQ_GET_ORG_ROLES, getOrgRolesSaga);
}
function* watchGetSecretsSaga() {
  yield takeLatest(actions.REQ_GET_SECRETS, getSecretsSaga);
}
function* watchSetActiveOrgSaga() {
  yield takeLatest(actions.SET_ACTIVE_ORG, setActiveOrgSaga);
}
function* watchSetOrgUsersSaga() {
  yield takeLatest(actions.SET_ORG_USERS, setOrgUsersSaga);
}
function* watchReqInviteUsers() {
  yield takeLatest(actions.REQ_INVITE_USERS, reqInviteUsersSaga);
}
function* watchReqUpdateOrgSaga() {
  yield takeLatest(actions.REQ_UPDATE_ORG, reqUpdateOrgSaga);
}
function* watchToggleDisableSecret() {
  yield takeLatest(actions.REQ_TOGGLE_DISABLE_SECRET, toggleDisableSecretSaga);
}

export default function* playersSagas() {
  yield fork(watchAcceptInviteSaga);
  yield fork(watchAddSecretSaga);
  yield fork(watchDeleteSecretSaga);
  yield fork(watchGetOrgsSaga);
  yield fork(watchGetOrgRolesSaga);
  yield fork(watchGetOrgUsersSaga);
  yield fork(watchGetSecretsSaga);
  yield fork(watchSetActiveOrgSaga);
  yield fork(watchSetOrgUsersSaga);
  yield fork(watchReqInviteUsers);
  yield fork(watchReqUpdateOrgSaga);
  yield fork(watchToggleDisableSecret);
}
