const actions = {
  REQ_ACCEPT_INVITE: 'REQ_ACCEPT_INVITE',
  REQ_ADD_SECRET: 'REQ_ADD_SECRET',
  REQ_DELETE_SECRET: 'REQ_DELETE_SECRET',
  REQ_GET_ORGS: 'REQ_GET_ORGS',
  REQ_GET_ORG_ROLES: 'REQ_GET_ORG_ROLES',
  REQ_GET_SECRETS: 'REQ_GET_SECRETS',
  REQ_GET_ORG_USERS: 'REQ_GET_ORG_USERS',
  REQ_INVITE_USERS: 'REQ_INVITE_USERS',
  REQ_TOGGLE_DISABLE_SECRET: 'REQ_TOGGLE_DISABLE_SECRET',
  REQ_UPDATE_ORG: 'REQ_UPDATE_ORG',
  SET_ACTIVE_ORG: 'SET_ACTIVE_ORG',
  SET_ORG_ROLES: 'SET_ORG_ROLES',
  SET_ORGS: 'SET_ORGS',
  SET_ORG_USERS: 'SET_ORG_USERS',
  SET_PENDING_INVITES: 'SET_PENDING_INVITES',
  SET_SECRETS: 'SET_SECRETS',
  SET_USER_IS_ADMIN: 'SET_USER_IS_ADMIN',
  UPDATE_ACTIVE_ORG: 'UPDATE_ACTIVE_ORG',
};

export const reqAcceptInvite = (orgId, inviteId) => ({
  type: actions.REQ_ACCEPT_INVITE,
  orgId,
  inviteId,
});
export const reqAddSecret = (secret) => ({
  type: actions.REQ_ADD_SECRET,
  secret,
});
export const reqDeleteSecret = (secretId) => ({
  type: actions.REQ_DELETE_SECRET,
  secretId,
});
export const reqGetOrgs = () => ({ type: actions.REQ_GET_ORGS });
export const reqGetOrgRoles = (orgId) => ({
  type: actions.REQ_GET_ORG_ROLES,
  orgId,
});
export const reqGetOrgUsers = (orgId) => ({
  type: actions.REQ_GET_ORG_USERS,
  orgId,
});
export const reqGetSecrets = (orgId) => ({
  type: actions.REQ_GET_SECRETS,
  orgId,
});
export const reqInviteUsers = (invites) => ({
  type: actions.REQ_INVITE_USERS,
  invites,
});
export const reqToggleDisableSecret = (secret) => ({
  type: actions.REQ_TOGGLE_DISABLE_SECRET,
  secret,
});
export const reqUpdateOrg = (organization) => ({
  type: actions.REQ_UPDATE_ORG,
  organization,
});
export const setActiveOrg = (orgId) => ({
  type: actions.SET_ACTIVE_ORG,
  orgId,
});
export const setOrgRoles = (roles) => ({ type: actions.SET_ORG_ROLES, roles });
export const setOrgs = (orgs) => ({ type: actions.SET_ORGS, orgs });
export const setOrgUsers = (users) => ({ type: actions.SET_ORG_USERS, users });
export const setPendingInvites = ({ orgId, invites }) => ({
  type: actions.SET_PENDING_INVITES,
  orgId,
  invites,
});
export const setSecrets = (secrets) => ({ type: actions.SET_SECRETS, secrets });
export const setUserIsAdmin = (orgId, userIsAdmin) => ({
  type: actions.SET_USER_IS_ADMIN,
  orgId,
  userIsAdmin,
});
export const updateActiveOrg = (organization) => ({
  type: actions.UPDATE_ACTIVE_ORG,
  organization,
});

export default actions;
