import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  TextField,
} from '@material-ui/core';
import Select from '../../../components/UI/Select';
import Button from '../../../components/UI/Button';
import Modal from '../../../components/Modal';
import copy from '../../../copy-text';

const useStyles = makeStyles((theme) => ({
  form: {
    margin: '5px 0',
  },
  select: {
    width: 300,
  },
}));

const NewSecret = (props) => {
  const classes = useStyles();
  const { onModalClose, onSubmit, openModal, roles } = props;
  const blankSecret = { description: '', roleId: roles[0].id, disabled: false };
  const [newSecret, setNewSecret] = useState<any>(blankSecret);
  const noError = { description: false };
  const [hasError, setHasError] = useState(noError);

  const checkForErrors = () => {
    if (newSecret.description.length) {
      setHasError(noError);
      onSubmit(newSecret);
    } else {
      setHasError({
        description: !newSecret.description.length,
      });
    }
  };

  const handleChange = (event) =>
    setNewSecret({ ...newSecret, [event.target.name]: event.target.value });
  const handleCheckbox = (event) =>
    setNewSecret({ ...newSecret, [event.target.name]: !event.target.checked });
  const handleClose = () => {
    setNewSecret(blankSecret);
    onModalClose();
  };

  return (
    <Modal
      fullWidth
      open={openModal}
      title={copy.pages.environment.secrets.newSecret.title}
      onClose={handleClose}
      actions={
        <Fragment>
          <Button type="contained" onClick={checkForErrors}>
            Submit
          </Button>
          <Button onClick={handleClose} fill={false}>
            Cancel
          </Button>
        </Fragment>
      }
    >
      <FormControl fullWidth className={classes.form}>
        <TextField
          fullWidth
          margin="dense"
          name="description"
          label={copy.pages.environment.secrets.newSecret.name.label}
          type="text"
          error={hasError.description}
          onChange={handleChange}
          value={newSecret.description}
        />
        {newSecret.secret && newSecret.secret.length ? (
          <TextField
            fullWidth
            disabled
            margin="dense"
            name="secret"
            label="Secret"
            type="text"
            value={newSecret.secret}
          />
        ) : null}
      </FormControl>
      <FormControl className={classes.form}>
        <Select
          name="permission"
          label="Permission"
          className={classes.select}
          value={newSecret.roleId}
          onChange={handleChange}
          options={roles.map((role) => ({
            label: role.displayName,
            value: role.id,
          }))}
          //options={[{ label: 'Write', value: 'WRITE' },{ label: 'Marketplace', value: 'MARKETPLACE' }]}
        />
      </FormControl>
      <FormGroup row>
        <FormControlLabel
          label="Enabled"
          control={
            <Checkbox
              name="disabled"
              onChange={handleCheckbox}
              checked={!newSecret.disabled}
            />
          }
        />
      </FormGroup>
    </Modal>
  );
};

export default NewSecret;
