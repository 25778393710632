import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRoleTarget } from '../../hooks';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '../../components/UI/Button';
import Card from '../../components/Card';
import Page from '../../components/Page';
import { reqAddTitle } from '../../redux-store/modules/titles';
import { ReactComponent as NoTitles } from '../../assets/img/no-titles.svg';
import Title from './Title';
import NewTitle from './NewTitle';
import copy from '../../copy-text';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 80,
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    padding: 15,
  },
  row: ({ isMobile }: any) => ({
    display: 'flex',
    flexDirection: 'row',
    flexWrap: isMobile ? 'wrap' : 'nowrap',
  }),
  title: {
    color: theme.colors.darkBlue,
    fontSize: '1.75em',
    fontWeight: 'bold',
    marginBottom: 30,
  },
  img: {
    marginLeft: 20,
    marginRight: 200,
  },
  instructions: {
    color: theme.colors.darkBlue,
    fontSize: '1em',
    marginBottom: 30,
  },
  learnMore: {
    color: theme.colors.darkBlue,
    fontSize: '1em',
  },
}));

export default function Titles() {
  const { Titles, Settings } = useSelector((state) => state);
  const { isMobile } = Settings;
  const dispatch = useDispatch();
  const classes = useStyles({ isMobile });
  const [showNewTitleModal, setShowNewTitleModal] = useState(false);
  const titlesPermissions = useRoleTarget('titles');

  const handleAddTitle = (title) => {
    setShowNewTitleModal(false);
    dispatch(reqAddTitle(title));
  };

  const cta = Titles?.titles.length && titlesPermissions.CREATE && (
    <Button type="contained" onClick={() => setShowNewTitleModal(true)}>
      + Create New Title
    </Button>
  );

  const renderTitles = (titles) =>
    titles
      .sort((a, b) => b.createdTimestamp - a.createdTimestamp)
      .map((title, i) => <Title key={i} title={title} />);

  const renderNoTitles = () => (
    <Card className={classes.card}>
      <div className={classes.row}>
        <div className={classes.col}>
          <NoTitles className={classes.img} />
        </div>
        <div className={classes.col}>
          <Typography className={classes.title}>
            {copy.pages.titles.noTitles.label}
          </Typography>
          <Typography className={classes.instructions}>
            Click <b>+ Create New Title</b> to add your first title and select
            your title's starting environment (DEV, QA, STAGE, or PROD).
          </Typography>
          <Button type="contained" onClick={() => setShowNewTitleModal(true)}>
            {copy.pages.titles.noTitles.button}
          </Button>
        </div>
      </div>
    </Card>
  );

  return (
    <Page title={copy.pages.titles.title} cta={cta}>
      <NewTitle
        openModal={showNewTitleModal}
        onSubmit={handleAddTitle}
        onModalClose={() => setShowNewTitleModal(false)}
      />
      {Titles.titles && Titles.titles.length
        ? renderTitles(Titles.titles)
        : renderNoTitles()}
    </Page>
  );
}
